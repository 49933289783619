import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface QuestionList {
  id: number;
  name: string;
  value: string;
}

interface ErrorFormDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  countryCode?: string;
  message?: string;
}

interface ContactUsData {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    heading: string;
    address: string;
    email: string;
    phone_number: string;
    image: string;
    address_icon: string;
    phone_icon: string;
    email_icon: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler: Function;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formDetails: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    message: string;
  },
  questionList: QuestionList[];
  countryCode: string;
  isErrorFormDetails: ErrorFormDetails;
  contactUsLoading: boolean;
  toggleQuestionsModal: boolean;
  getContactUsData: ContactUsData[];
  getContactUsDataLoading: boolean;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContactUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contactUsFormApiCallId: string = "";
  getContactUsDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      formDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
      },
      questionList: [],
      countryCode: "",
      isErrorFormDetails: {},
      contactUsLoading: false,
      toggleQuestionsModal: false,
      getContactUsData: [],
      getContactUsDataLoading: false,
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getContactUsData();
    this.setState({ getContactUsDataLoading: true });

    const selectedLanguage = await getStorageData("lang") || "en";
    
    this.setState({ languageSelected: selectedLanguage })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.contactUsFormApiCallId) {
        if (!responseJson.errors) {
          this.setState({ contactUsLoading: false });
          this.props.openToastHandler(this.props.t("landingPage.ContactUs.formSubmittedLabel"), "success");
        } else {
          //Check Error Response
          this.setState({ contactUsLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    this.handleGetContactUs(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetContactUs = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    if (apiRequestCallId && responseJson) {
      if(apiRequestCallId === this.getContactUsDataApiCallId) {
        if (!responseJson.errors) {
          this.setState({ getContactUsDataLoading: false });
          this.setState({ getContactUsData: responseJson.data });
        } else {
          //Check Error Response
          this.setState({ getContactUsDataLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  
  handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, item: QuestionList) => {
    const checked = event?.target.checked;
    const questionList = [...this.state.questionList];
    if (checked) {
      questionList.push(item);
    } else {
      const index = questionList.findIndex(q => q.value === item.value)
      if (index > -1) {
        questionList.splice(index, 1);
      }
    }
    this.setState({ questionList: questionList });
  }

  handleQuestionsModalOpen = () => {
    this.setState({ toggleQuestionsModal: !this.state.toggleQuestionsModal });
  }

  handleQuestionsModalClose = () => {
    this.setState({ toggleQuestionsModal: false });
  }
  
  handleChangeCountryCode = (event: React.ChangeEvent<{}>, value: string) => {
    this.setState({ countryCode: value });
  };

  handleChangeFormDetails = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formDetails: {...this.state.formDetails, [e.target.name]: e.target.value}
    });
  }

  validateFormDetails = (values: ErrorFormDetails) => {
    const { t } = this.props;
    const errors: ErrorFormDetails = {}
    const regexEmail = /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
    
    this.validateName(values, errors);
    
    if (!values.email) {
      errors.email = t("landingPage.ContactUs.emailRequired");
    } else if (!regexEmail.test(values.email)) {
      errors.email = t("landingPage.ContactUs.emailInvalid");
    }

    this.validatePhone(values, errors);
    
    if (!values.message) {
      errors.message = t("landingPage.ContactUs.messageRequired");
    }

    return errors;
  };

  validateName = (values: ErrorFormDetails, errors: ErrorFormDetails) => {
    const { t } = this.props;
    const regexName = this.state.languageSelected === "ar" ?
    /^\s*[a-zA-Z\u0621-\u064A]+(?:\s[a-zA-Z\u0621-\u064A]+)*\s*$/ :
    /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
    if (!values.firstName) {
      errors.firstName = t("landingPage.ContactUs.firstNameRequired");
    } else if (!regexName.test(values.firstName)) {
      errors.firstName = t("landingPage.ContactUs.nameAlphabetical");
    } else if (values.firstName.length > 23) {
      errors.firstName = t("landingPage.ContactUs.firstNameLength");
    }
    if (!values.lastName) {
      errors.lastName = t("landingPage.ContactUs.lastNameRequired");
    } else if (!regexName.test(values.lastName)) {
      errors.lastName = t("landingPage.ContactUs.nameAlphabetical");
    } else if (values.lastName.length > 23) {
      errors.lastName = t("landingPage.ContactUs.lastNameAlphabetical");
    }
  }

  validatePhone = (values: ErrorFormDetails, errors: ErrorFormDetails) => {
    const { t } = this.props;
    const regexNumbers = /^\s*\d+\s*$/;
    if (!values.phone) {
      errors.phone = t("landingPage.ContactUs.phoneRequired");
    } else if (!regexNumbers.test(values.phone)) {
      errors.phone = t("landingPage.ContactUs.phoneNumerical");
    }

    if (!this.state.countryCode) {
      errors.countryCode = t("landingPage.ContactUs.countryCodeRequired");
    }
    
    return errors;
  }

  handleDetailsSubmit = (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ isErrorFormDetails: this.validateFormDetails(this.state.formDetails) }, 
    () => {
      if(Object.keys(this.state.isErrorFormDetails).length === 0) {
        this.setState({ contactUsLoading: true });
        this.contactUsFormPage();
        this.setState({
            formDetails: { ...this.state.formDetails, firstName: "", lastName: "", email: "", phone: "", message: "" },
            questionList: [],
            countryCode: ""
          });
        }
      }
    );
  }

  contactUsFormPage = () => {
    const header = {
      "Content-Type": configJSON.contactUsAPIContentType,
    };

    const httpBody = {
      "data": {
        "attributes": {
          "first_name": this.state.formDetails.firstName.trim(),
          "last_name": this.state.formDetails.lastName.trim(),
          "email_address": this.state.formDetails.email.trim(),
          "full_phone_number": `${this.state.countryCode} ${this.state.formDetails.phone.trim()}`,
          "phone_number": this.state.formDetails.phone.trim(),
          "enable_call": true,
          "message": this.state.formDetails.message.trim(), 
          "contact_likes": {
            book_a_demo: this.state.questionList.some((q: QuestionList) => q.value === 'Book a demo'),
            apply_for_a_job: this.state.questionList.some((q: QuestionList) => q.value === 'Apply For a job'),
            get_help: this.state.questionList.some((q: QuestionList) => q.value === 'Get help'),
            others: this.state.questionList.some((q: QuestionList) => q.value === 'Others')
          }
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsFormApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsFormAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.contactUsFormAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getContactUsData = async () => {
    const selectedLanguage = await getStorageData("lang") || "en";
    const headerType = {
      "Content-Type": configJSON.contactUsAPIContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactUsDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getContactUsDataAPIEndPoint}?lang=${selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getContactUsDataAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerType)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End