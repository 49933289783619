import React from "react";

// Customizable Area Start
import {
  Grid,
  Box,
  Button,
  Hidden,
  Typography,
  Container,
  Dialog, 
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Slider
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import { styled } from "@material-ui/styles"
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { managePlan1, managePlan2,cross } from "./assets";
import { tickIcon, tickFilled, tickEmpty } from "../../landingpage/src/assets";
import { SettingsSideBar } from "./SettingsSideBar.web";
import { withSnackbar } from "../../../components/src/toastweb";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { CONSTANTS } from "../../../components/src/theme";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
import { StyledBox } from "./CompanyInformation.web";
import { ArcheiveButton, ContainedButton, CreateQuote, OutlinedButton } from "../../../components/src/CustomizableComponents.web";
import moment from "moment";
// Customizable Area End
import ManagePlanController, {
  Props,
} from "./ManagePlanController.web";

// Customizable Area Start
interface FeatureRow { 
  id: string; 
  feature_name: string; 
  try_us: boolean; 
  essential: boolean; 
  standard: boolean; 
  premium: boolean; 
}

export const StyledDialog = withStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: "2rem",
      width: "100%",
      maxWidth: "657px",
      borderRadius: "20px",
      backgroundColor: CONSTANTS.color.white,
      boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
      [theme.breakpoints.down("xs")]: {
        padding: "1.3rem",
      }
    }
  }
}))(Dialog);
const TableContainerDiv = styled(TableContainer)({
  "@media(max-width:700px)": {
    scrollbarWidth: "thin"
  }
})
const StyledTableHead = withStyles({
  root: {
    "& .MuiTableCell-root": {
      minWidth: "110px !important",
    }
  }
})(TableHead);
const PrettoSlider = withStyles({
  root: {
    color: '#1c18af',
    height: 8,
    paddingBottom:"3px"
  },
  thumb: {
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -2,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
  color: 'white',
    height: 8,
    borderRadius: 4,
    opacity:"inherit"
  },
})(Slider);
// Customizable Area End

export class ManagePlan extends ManagePlanController {
  constructor(props: Props) {
  super(props);
  }
  dialogTitle = () => {
    if (this.state.cancelPlanDialogOpen === true) {
      return this.props.t('settings.cancelPlan')
    } else if (this.state.renewPlan === true) {
      return this.props.t('settings.renewPlan')
    } else {
      return this.props.t('settings.changePlan')
    }
  }
  dialogContent = () => {
    if (this.state.cancelPlanDialogOpen === true) {
      return `${this.props.t('settings.subscriptionEndError1')} ${moment(new Date()).format('Do [of] MMMM, YYYY')}${this.props.t('settings.subscriptionEndError2')}`
    } else if (this.state.renewPlan === true) {
      return this.props.t('settings.sureRenew')
    } else {
      return this.props.t('settings.changeYourPlan')
    }
  }
  dialogButton = (classes: ClassNameMap<string>) => {
    const { cancelPlanDialogOpen, renewPlan } = this.state;
    return <Box style={cancelPlanDialogOpen === false ? { ...webStyle.buttonsContainer, ...webStyle.paddingStyle, ...webStyle.changePlanContainer } : { ...webStyle.buttonsContainer, ...webStyle.btnPlanContainer }} className={classes.cancelSaveContainer}>
      {cancelPlanDialogOpen === false &&
        <>
          <ArcheiveButton
            data-testid='handleCloseCancelPlanModal'
            style={{ maxWidth: "162px" ,maxHeight:"56px"}}
            className={`${classes.buttonContainer} ${classes.buttonMobile}`}
            onClick={this.handleClosePlanModal}
          >
            <Typography style={webStyle.buttonTextCancel} className={classes.buttonTextMobileCancel} variant="subtitle2">
              {this.props.t('settings.cancel')}
            </Typography>
          </ArcheiveButton>
          <ContainedButton
            data-testid='changePlan'
            style={{ maxWidth: "162px" ,maxHeight:"56px"}}
            className={`${classes.buttonContainer} ${classes.buttonMobile}`}
            onClick={renewPlan === true ? this.handleRenewPlan : this.handleChangePlan}
          >
            <Typography style={webStyle.buttonTextSave} className={classes.buttonTextMobileCancel} variant="subtitle2">
              {renewPlan === true ? 'Renew Plan' : this.props.t("settings.changePlanButton")}
            </Typography>
          </ContainedButton>
        </>
      }
      {cancelPlanDialogOpen === true &&
        <CreateQuote
          data-testid='handleConFirmPlanModal'
          onClick={this.handleConfirmPlan}
        >
          <Typography style={webStyle.buttonTextSave} variant="subtitle2">
            Confirm Cancellation
          </Typography>
        </CreateQuote>
      }
    </Box>
  }
  planBoxStyled=(plan_type:string,classes:ClassNameMap<string>)=>{
    const { getPlanData,managePlan } = this.state;
    if(getPlanData&&getPlanData.current_plan===plan_type){
     return `${classes.bg_gray} ${classes.planContainerBox}`
    } else if(plan_type === managePlan){
      return `${classes.planContainerBox} ${classes.border_blue}`
    } else{
      return classes.planContainerBox
    }
  }
  planButtonStyled=(plan_type:string,classes:ClassNameMap<string>)=>{
    const { getPlanData,managePlan } = this.state;
    if(getPlanData&&getPlanData.current_plan===plan_type){
      if(plan_type === managePlan){
        return  `${classes.buttonsContainer1} ${classes.bg_blue}`
      } else{
        return  `${classes.buttonsContainer1} ${classes.bg_gray}`
      }
    } else if(plan_type === managePlan){
      return  `${classes.buttonsContainer1} ${classes.bg_blue}`
    }else{
      return `${classes.buttonsContainer1} ${classes.bg_white}`
    }
  }
  renderTableCell = () => {
    const { t } = this.props;
    return <>
      <TableCell align="right">
        <Typography style={webStyle.tableHeadingColumn} variant="body2">
          {t("landingPage.Pricing.essential")}
        </Typography>
      </TableCell>
      <TableCell align="right" >
        <Typography style={webStyle.tableHeadingColumn} variant="body2">
          {t("landingPage.Pricing.standard")}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography style={webStyle.tableHeadingColumn} variant="body2">
          {t("landingPage.Pricing.premium")}
        </Typography>
      </TableCell>
    </>
  }
  tableHeading = () => {
    return this.state.languageSelected === "ar" ? { ...webStyle.tableHeading, paddingRight: "4rem" } : webStyle.tableHeading
  }
  tableHeadOne = () => {
    return <StyledTableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell>
          <Typography style={this.tableHeading()} variant="body2" className="tableHeading">
            {this.props.t("landingPage.Pricing.jobManagement")}
          </Typography>
        </TableCell>
        {this.renderTableCell()}
      </TableRow>
    </StyledTableHead>
  }
  tableHeadTwo = () => {
    return <StyledTableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell>
          <Typography style={this.tableHeading()} variant="body2" className="tableHeading">
            {this.props.t("landingPage.Pricing.request&Quoting")}
          </Typography>
        </TableCell>
        {this.renderTableCell()}
      </TableRow>
    </StyledTableHead>
  }
  tableHeadThree = () => {
    return <TableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell>
          <Typography style={this.tableHeading()} variant="body2" className="tableHeading">
            Invoices & Payments
          </Typography>
        </TableCell>
        {this.renderTableCell()}
      </TableRow>
    </TableHead>
  }
  tableHeadFour = () => {
    return <StyledTableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell>
          <Typography style={this.tableHeading()} variant="body2" className="tableHeading">
            {this.props.t("landingPage.Pricing.customers")}
          </Typography>
        </TableCell>
        {this.renderTableCell()}
      </TableRow>
    </StyledTableHead>
  }
  tableHeadFive = () => {
    return <StyledTableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell>
          <Typography style={this.tableHeading()} variant="body2" className="tableHeading">
            {this.props.t("landingPage.Pricing.workerManagement")}
          </Typography>
        </TableCell>
        {this.renderTableCell()}
      </TableRow>
    </StyledTableHead>
  }
  tableHeadSix = () => {
    return <StyledTableHead style={webStyle.tableHead}>
      <TableRow>
        <TableCell>
          <Typography style={this.tableHeading()} variant="body2" className="tableHeading">
            {this.props.t("landingPage.Pricing.additionalFeatures")}
          </Typography>
        </TableCell>
        {this.renderTableCell()}
      </TableRow>
    </StyledTableHead>
  }
  tableRowStyle = (featureName: string) => {
    if (featureName === 'Job Template') {
      return 'none'
    } else if (featureName === 'GPS Tracking') {
      return 'none'
    } else if (featureName === 'Custom Template Creation') {
      return 'none'
    } else if (featureName === 'Custom Data Fields') {
      return 'none'
    } else if (featureName === 'Customer History') {
      return 'none'
    } else if (featureName === 'Roles & Permissions') {
      return 'none'
    } else {
      return ''
    }
  } 
  tableRow = (featureRow: FeatureRow) => {
    return <TableRow key={featureRow.id} style={{ display: this.tableRowStyle(featureRow.feature_name) }}>
      <TableCell align="center" scope="row" style={this.state.languageSelected === "ar" ? { ...webStyle.tableHeadingRow, padding: "2rem 4rem 2rem 0rem" } : webStyle.tableHeadingRow} className="tableHeading">
        {featureRow.feature_name}
      </TableCell>
      <TableCell align="center"><img style={webStyle.checkIcon} className="checkIcon" src={featureRow?.essential === true ? tickFilled : tickEmpty} alt="" /></TableCell>
      <TableCell align="center"><img style={webStyle.checkIcon} className="checkIcon" src={featureRow?.standard === true ? tickFilled : tickEmpty} alt="" /></TableCell>
      <TableCell align="center"><img style={webStyle.checkIcon} className="checkIcon" src={featureRow?.premium === true ? tickFilled : tickEmpty} alt="" /></TableCell>
    </TableRow>
  }
  featureContainer = () => {
    return <Grid container style={webStyle.container}>
      <Grid item lg={12} md={12} xs={12}>
        <Typography style={webStyle.headingText} variant="subtitle1">
           {this.props.t("landingPage.Pricing.features")}
        </Typography>
      </Grid>
      <TableContainerDiv>
        <Paper>
          <Table style={{ minWidth: 650 }}>
            {this.tableHeadOne()}
            <TableBody style={webStyle.tableBody}>
              {this.state.getFeatureData && this.state.getFeatureData[0].job_management?.map((featureRow: FeatureRow) => (
                this.tableRow(featureRow)
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainerDiv>
      <TableContainerDiv style={webStyle.marginStyle} >
        <Paper>
          <Table style={{ minWidth: 650 }}>
            {this.tableHeadTwo()}
            <TableBody style={webStyle.tableBody}>
              {this.state.getFeatureData && this.state.getFeatureData[1].request_quoting?.map((featureRow: FeatureRow) => (
                this.tableRow(featureRow)
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainerDiv>
      <TableContainerDiv style={webStyle.marginStyle} >
        <Paper>
          <Table style={{ minWidth: 650 }}>
            {this.tableHeadThree()}
            <TableBody style={webStyle.tableBody}>
              {this.state.getFeatureData && this.state.getFeatureData[2].invoices_payments?.map((featureRow: FeatureRow) => (
                this.tableRow(featureRow)
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainerDiv>
      <TableContainerDiv style={webStyle.marginStyle} >
        <Paper>
          <Table style={{ minWidth: 650 }}>
            {this.tableHeadFour()}
            <TableBody style={webStyle.tableBody}>
              {this.state.getFeatureData && this.state.getFeatureData[3].customers?.map((featureRow: FeatureRow) => (
                this.tableRow(featureRow)
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainerDiv>
      <TableContainerDiv style={webStyle.marginStyle} >
        <Paper>
          <Table style={{ minWidth: 650 }}>
            {this.tableHeadFive()}
            <TableBody style={webStyle.tableBody}>
              {this.state.getFeatureData && this.state.getFeatureData[4].worker_management?.map((featureRow: FeatureRow) => (
                this.tableRow(featureRow)
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainerDiv>
      <TableContainerDiv style={webStyle.marginStyle} >
        <Paper>
          <Table style={{ minWidth: 650 }}>
            {this.tableHeadSix()}
            <TableBody style={webStyle.tableBody}>
              {this.state.getFeatureData && this.state.getFeatureData[5].additional_features?.map((featureRow: FeatureRow) => (
                this.tableRow(featureRow)
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainerDiv>
    </Grid>
  }
  changePlanBox = (classes:ClassNameMap<string>) => {
    const { activeTab, getPlanData, managePlan } = this.state;
    return <>
      <Grid container direction="row" style={{ justifyContent: "center" }} className={classes.mb_50}>
        <Box className={classes.plansContainer}>
          <Button
            data-testid="monthly"
            className={activeTab === "monthly" ? `${classes.button} ${classes.bg_blue}` : classes.button}
            onClick={this.handleClickMonthly}
          >
            <Typography
              style={activeTab === "yearly" ?
                { ...webStyle.buttonTextSave, color: CONSTANTS.color.coolDarkGrey } :
                webStyle.buttonTextSave
              }
              className="buttonText"
              variant="subtitle1"
            >
              {this.props.t("landingPage.Pricing.monthly")}
            </Typography>
          </Button>
          <Button
            data-testid='yearly'
            className={activeTab === "yearly" ? `${classes.button} ${classes.bg_blue}` : classes.button}
            onClick={this.handleClickYearly}
          >
            <Typography
              style={activeTab === "monthly" ? { ...webStyle.buttonTextSave, color: CONSTANTS.color.coolDarkGrey } : webStyle.buttonTextSave}
              className="buttonText"
              variant="subtitle1"
            >
              {this.props.t("landingPage.Pricing.yearly")}
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid container style={{ justifyContent: "space-around" }}>
        {getPlanData && getPlanData.plans.data && getPlanData.plans.data.map((item,index) => {
          return <Grid item xs={12} md={4}
            style={{ display: item.attributes.plan_type === 'try_us' ? 'none' : '' }}
            onClick={() => this.handleSelectManagePlan(item.attributes.plan_type, item.attributes.id)}
            className={this.planBoxStyled(item.attributes.plan_type, classes)}
            data-testid={`selectPlan_${item.attributes.id}`}
          >
            <Box className={classes.planInnerContainerBox}>
              <Typography variant="subtitle1" style={webStyle.tryUsText}>
                {item.attributes.plan_type.toLowerCase().replace(/_/g, " ").replace(/\b\w/g, (name: string) => name?.toUpperCase())}
              </Typography>
              <Typography variant="h3" style={webStyle.planPriceText}>
                {item.attributes.currency} {item.attributes.plan_price}
              </Typography>
              <Typography style={webStyle.planPriceBodyText} data-testid={`current_plan`}>
                {item.attributes.details}
              </Typography>
              <Box style={webStyle.monthlyTermsContainer}>
                {item.attributes.plan_description.map((item: { discription: string; }) => (
                  <Box style={this.state.languageSelected === "ar" ? { ...webStyle.monthlyTickContainer, gap: "0.5rem" } : webStyle.monthlyTickContainer}>
                    <img style={webStyle.tickIconImg} src={tickIcon} alt="" />
                    <Typography
                      style={webStyle.tickIconText}
                      variant="caption"
                      dangerouslySetInnerHTML={{
                        __html: (`${item.discription}`)
                      }}
                    />
                  </Box>
                ))}
              </Box>
              {item.attributes.plan_type === getPlanData.current_plan &&
                <>
                  <Typography style={webStyle.planPriceBodyText1}>
                    Do you have more employees? Consider below option
                  </Typography>
                  
                  
                  <PrettoSlider 
                     valueLabelDisplay="auto" 
                     aria-label="pretto slider" 
                     defaultValue={item.attributes.amount_of_slider} 
                     onChange={(event,value) => this.handleChangeSlider(event,value)}
                  />
                  <Grid container style={{ justifyContent: "space-between" }}>
                    <Typography style={webStyle.empText}>25 emp</Typography>
                    <Typography style={webStyle.empText}>50 emp</Typography>
                  </Grid>
                </>
              }
              <Button
                className={this.planButtonStyled(item.attributes?.plan_type, classes)} data-testid={`Choose_planBtn_${index}`}
                onClick={this.updatePlan}
              >
                <Typography
                  style={webStyle.buttonsText}
                  className={item.attributes?.plan_type === managePlan ? classes.text_white : classes.text_blue}
                  variant="body1"
                >
                  {item.attributes?.button_text_setting || "Choose Plan"}
                </Typography>
              </Button>
            </Box>
          </Grid>
        })}
      </Grid> 
      {this.featureContainer()}
    </>
  }
  changePlanContainer = (classes: ClassNameMap<string>) => {
    const {renewPlan} = this.state;
    return <Box>
      <Typography style={webStyle.managePlanHead} className={classes.managePlanHead} variant="body1">
        {renewPlan ? this.props.t('settings.renewMyPlan'): this.props.t('settings.cancelMyPlan')}
      </Typography>
      <Box className={classes.changePlanContainerMobile}>
        <Typography style={webStyle.managePlanDesc} className={classes.managePlanHead} variant="body1" color="textPrimary">
          {renewPlan ? this.props.t('settings.renewCurrentPlan') : this.props.t('settings.cancelMyPlanDesc')}
        </Typography>
        <Hidden smUp>
          <img style={webStyle.managePlan2} className={classes.cancelPlanModalImage} src={managePlan2} alt="" />
        </Hidden>
      </Box>
      <ContainedButton
        data-testid="handleCancelPlan"
        style={webStyle.marginStyle}
        className={`${classes.buttonContainer} ${classes.buttonMobile} ${classes.buttonMobileMargin}`}
        onClick={renewPlan ? this.handleOpenRenewPlanModal : this.handleOpenCancelPlanModal}
      >
        <Typography style={webStyle.buttonTextSave} className={classes.buttonTextMobile} variant="subtitle2">
          {renewPlan ? this.props.t('settings.renew') : this.props.t('settings.cancelPlan')}
        </Typography>
      </ContainedButton>
    </Box>
  }
  bottomContainerBtn = (classes: ClassNameMap<string>) => {
    const { changePlan } = this.state;
    return <>
      <Box style={{ ...webStyle.buttonsContainer, ...webStyle.changePlanContainer }} className={`${classes.buttonsContainer} ${classes.cancelSaveContainer}`}>
        {changePlan===true? 
          <OutlinedButton
          data-test-id="handleCancel"
            style={{ maxWidth: "200px" }}
          className={`${classes.buttonContainer} ${classes.buttonMobile}`}
          onClick={this.handleCancel}
        >
          <Typography style={webStyle.buttonTextGoBack} className={classes.buttonTextMobile} variant="subtitle2">
          {this.props.t("landingPage.Pricing.goBack")}
          </Typography>
        </OutlinedButton>:
         <ArcheiveButton
         data-test-id="handleCancel"
         style={{ maxWidth: "200px" }}
         className={`${classes.buttonContainer} ${classes.buttonMobile}`}
         onClick={this.handleCancel}
       >
         <Typography style={webStyle.buttonTextCancel} className={classes.buttonTextMobile} variant="subtitle2">
           {this.props.t('settings.cancel')}
         </Typography>
       </ArcheiveButton>
        }
        <ContainedButton
          data-test-id="handleSave"
          data-testid="handleUpdate"
          style={{ maxWidth: "200px" }}
          className={`${classes.buttonContainer} ${classes.buttonMobile}`}
          onClick={changePlan === false ? this.handleCreateCharge : this.updatePlan}
        >
          <Typography style={webStyle.buttonTextSave} className={classes.buttonTextMobile} variant="subtitle2">
            {changePlan === false ? this.props.t('settings.save') : this.props.t('suppliers.update')}
          </Typography>
        </ContainedButton>
      </Box>
    </>
  }
  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    const { planDialogOpen,cancelPlanDialogOpen,getPlanData, changePlan, loading } = this.state;
    const currentPlan = getPlanData&&getPlanData.current_plan&&getPlanData.current_plan.toLowerCase().replace(/_/g, " ").replace(/\b\w/g, (name: string) => name?.toUpperCase())
    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.settings')} active={false} active1={false} active2={1} />
        <SettingsSideBar progress={100} {...this.props} active={2} />
        <StyledBox languageSelected={this.state.languageSelected}>
          <Box
            sx={webStyle.boxContainerManagePlan}
            className={classes.boxContainerManagePlan}
          >
            <Container maxWidth={false}>
              <Typography style={webStyle.mainHeaderText} className={classes.mainHeaderText} variant="subtitle1">
                {t('settings.manageYourPlan')}
              </Typography>
              {changePlan === true ?
                  this.changePlanBox(classes)
                  :
                <>
                  <Grid container spacing={2} className={classes.gridContainer}>
                    <Grid item lg={3} md={3} sm={4} xs={5}>
                      <Box
                        className={`${classes.accountStatusContainer} ${classes.border_darkBlue}`}
                      >
                        <Typography style={webStyle.planText} variant="body1">
                          {this.props.t('settings.currentPlan')}
                        </Typography>
                        <Typography style={webStyle.activeText} variant="subtitle1">
                          {currentPlan}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Typography variant="body2" style={{ color:"rgb(102, 102, 102)"}}> {this.props.t('settings.renewalDate')} : {moment(this.state.renewalDataDate).format('MMMM DD, YYYY')}</Typography>
                  <span style={webStyle.hr}></span>
                  <Grid container>
                    <Grid item lg={11} md={12} xs={12} >
                      <Box style={webStyle.changePlanContainer}>
                        <Box>
                          <Typography
                            className={classes.managePlanHead}
                            style={webStyle.managePlanHead}
                            variant="body1"
                          >
                            {t('settings.changeMyPlan')}
                          </Typography>
                          <Box className={classes.changePlanContainerMobile}>
                            <Typography
                              className={classes.managePlanHead}
                              style={webStyle.managePlanDesc}
                              color="textPrimary"
                              variant="body1"
                            >
                              {t('settings.changeMyPlanDesc')}
                            </Typography>
                            <Hidden smUp>
                              <img style={webStyle.managePlan1} className={classes.managePlan1} src={managePlan1} alt="" />
                            </Hidden>
                          </Box>
                          <ContainedButton
                            data-testid="openChangePlanModal"
                            style={webStyle.marginStyle}
                            className={`${classes.buttonContainer} ${classes.buttonMobile} ${classes.buttonMobileMargin}`}
                            onClick={this.handleOpenChangePlanModal}
                          >
                            <Typography style={webStyle.buttonTextSave} className={classes.buttonTextMobile} variant="subtitle2">
                              {t('settings.changePlanButton')}
                            </Typography>
                          </ContainedButton>
                        </Box>
                        <Hidden xsDown>
                          <img style={webStyle.managePlan1} className={classes.managePlan1} src={managePlan1} alt="" />
                        </Hidden>
                      </Box>
                    </Grid>
                  </Grid>
                  <span style={webStyle.hr}></span>
                  <Grid container>
                    <Grid item lg={11} md={12} xs={12}>
                      <Box style={webStyle.changePlanContainer}>
                        {this.changePlanContainer(classes)}
                        <Hidden xsDown>
                          <img style={webStyle.managePlan2} className={classes.cancelPlanModalImage} src={managePlan2} alt="" />
                        </Hidden>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container style={{ justifyContent: "center" }}>
                    <LoadingBackdrop loading={loading} />
                  </Grid>
                </>
              }
              <StyledDialog
                data-test-id="handleCloseCancelPlan"
                open={planDialogOpen}
                onClose={this.handleClosePlanModal}
                aria-labelledby="responsive-dialog-title"
              >
                <Box>
                  <Box style={webStyle.boxImage}>
                  <img data-testid="closeModal" onClick={this.closeModal} style={webStyle.crosssImage} src={cross}></img>

                  </Box>
                  <Box>
                    <Typography style={{ ...webStyle.mainHeaderText, ...webStyle.paddingBottomStyle }} variant="subtitle1">
                      {this.dialogTitle()}
                    </Typography>
                    <Typography style={webStyle.managePlanDesc} className={classes.managePlanDesc} variant="body1" color="textPrimary">
                      {this.dialogContent()}
                    </Typography>
                  </Box>
                </Box>
                {this.dialogButton(classes)}
              </StyledDialog>
              {this.bottomContainerBtn(classes)}
            </Container>
          </Box>
        </StyledBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainerManagePlan: {
    [theme.breakpoints.down("md")]: {
      padding: "0 1rem 8rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 0rem 5rem !important",
      marginTop: "0rem !important"
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "184px !important",
      padding:0
    }
  },
  mb_50:{
    marginBottom:"50px"
  },
  plansContainer: {
    width: "440px",
    maxWidth: "100%",
    height: "70px",
    borderRadius: "35px",
    background: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(163,177,198,0.25)",
    marginTop: "1rem",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      height: "53px !important",
    },
  },
  text_blue:{
    color:CONSTANTS.color.darkBlue
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CONSTANTS.color.white,
    borderRadius: "35px",
    minHeight: "70px",
    maxWidth: "220px",
    width: "100%",
    textTransform:"none" as const,
    [theme.breakpoints.down("xs")]: {
      height: "53px !important",
      minHeight: "0px !important"
    },
  },
  bg_gray:{
    background:"#E5E4E4"
  },
  planInnerContainerBox: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonCommon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: "100%",
    minHeight: "67px",
    borderRadius: "10px",
    textTransform: "none" as const,
  },
   planContainerBox: {
    maxWidth: "288px",
    minWidth: "288px",
    width:'100%',
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #ECECEC",
    padding: "1rem",
    cursor: "pointer",
    marginTop: "2rem",
    filter:"drop-shadow(0px 0px 10px rgba(0,0,0,0.05000000074505806))",
  },
  border_blue:{
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
  },
  bg_blue: {
    "&:hover": {
      background: CONSTANTS.color.darkBlue,
    },
    background: CONSTANTS.color.darkBlue,
  },
  bg_white:{
    backgroundColor: CONSTANTS.color.white
  },
  mainHeaderText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important"
    }
  },
  gridContainer: {
    "& .MuiGrid-item": {
      [theme.breakpoints.down("xs")]: {
        padding: "4px !important",
      },
    },
    paddingBottom:"2rem"
  },
  border_gray:{
    border: "1px solid rgba(0,0,0,0.1)",
  },
  text_white:{
    color:CONSTANTS.color.white
  },
  border_darkBlue: {
    border: `1px solid ${CONSTANTS.color.royalBlue}`,
    backgroundColor: "rgba(77,74,202,0.15)"
  },
  accountStatusContainer: {
    width: "100%",
    minHeight: "116px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    padding: "1rem 1.5rem 1.5rem",

  },
  buttonsContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "3rem !important"
    }
  },
  cancelSaveContainer: {
    gap: "1rem"
  },
  managePlanHead: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  buttonMobile: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "48px !important"
    }
  },
  buttonMobileMargin: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.5rem !important",
      maxWidth: "100% !important"
    }
  },
  buttonTextMobile: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px !important"
    }
  },
  buttonTextMobileCancel: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important"
    },
    [theme.breakpoints.down(374)]: {
      fontSize: "16px !important"
    },
  },
  managePlan1: {
    [theme.breakpoints.down("xs")]: {
      width: "96px !important",
      height: "122px !important"
    }
  },
  cancelPlanModalImage: {
    [theme.breakpoints.down("xs")]: {
      width: "95px !important",
      height: "93px !important"
    }
  },
  managePlanDesc: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
      lineHeight: "20px !important"
    }
  },
  buttonsContainer1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: "10px",
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
    padding: "1rem 0",
    maxWidth: "227px",
    width: "100%",
    marginTop: "1.5rem",
    marginBottom: "1.5rem"
  },
  changePlanContainerMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      alignItems: "center !important",
      gap: "1rem !important"
    }
  },
});

const webStyle = {
  boxContainerManagePlan:{
    marginTop: "8.5rem",
    paddingBottom: "4rem"
  },
  container: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headingText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    marginBottom: "3rem",
    marginTop: "1rem"
  },
  tableHead: {
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.airyCloudBlue,
  },
  tableBody: {
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
  },
  tableHeading: {
    color: CONSTANTS.color.darkBlue,
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "18px",
    textAlign: "start" as const,
    paddingLeft: "4rem",
  },
  tableHeadingColumn: {
    color: CONSTANTS.color.lightGrey,
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "18px",
  },
  tableHeadingRow: {
    color: CONSTANTS.color.midGrey,
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    lineHeight: "24px",
    padding: "2rem 0 2rem 0",
    width: "336px",
    textAlign: "start" as const,
    paddingLeft: "4rem",
  },
  checkIcon: {
    width: "24px",
    height: "24px",
  },
  tryUsText: {
    color: CONSTANTS.color.oliveGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  planPriceText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight700,
    textAlign: "center" as const,
    margin: "5px 0"
  },
  buttonsText: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textTransform: "none" as const
  },
  planPriceBodyText: {
    opacity: 0.7,
    color: CONSTANTS.color.oliveGrey,
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  planPriceBodyText1: {
    fontSize: "14px",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight:"normal",
    marginTop:"10px"
  },
  empText:{
    fontSize: "12px",
    color: CONSTANTS.color.oliveGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  monthlyTermsContainer: {
    display: "flex",
    flexDirection: "column" as const,
    marginTop: "0.5rem"
  },
  monthlyTickContainer: {
    display: "flex",
    flexDirection: "row" as const,
    marginTop: "1rem"
  },
  tickIconImg: {
    width: "11px",
    height: "8px",
    marginTop:"8px"
  },
  tickIconText: {
    color: CONSTANTS.color.oliveGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    marginLeft: "0.75rem"
  },
  mainHeaderText: {
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    paddingBottom: "1.5rem"
  },
  paddingBottomStyle: {
    paddingBottom: 0
  },
  planText: {
    color: CONSTANTS.color.midGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  activeText: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "24px",
  },
  changePlanContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  btnPlanContainer:{
    alignSelf:"end",
    paddingTop:"1rem"
  },
  managePlanHead: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
  },
  managePlanDesc: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "27px",
    paddingTop: "1rem"
  },
  managePlan1: {
    width: "147px",
    height: "186px",
  },
  managePlan2: {
    width: "216px",
    height: "210px",
  },
  buttonContainerCancel: {
    maxWidth: "200px",
    backgroundColor: CONSTANTS.color.white,
    border: `1px solid ${CONSTANTS.color.crimsonRed}`,
  },
  buttonContainerGoBack: {
    maxWidth: "200px",
    backgroundColor: CONSTANTS.color.white,
    border: `1px solid ${CONSTANTS.color.darkBlue}`,
  },
  cancelButtonStyle: {
    maxWidth: "156px",
    minHeight: "56px",
    border: `1px solid ${CONSTANTS.color.brightRed}`
  },
  buttonTextCancel: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  buttonTextGoBack: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  hr: {
    display: "flex",
    height: "1px",
    backgroundColor: CONSTANTS.color.paleGrey,
    margin: "2rem 10rem 2rem 0rem"
  },
  cancelPlanImage: {
    width: "145px",
    marginTop: "2em"
  },
  buttonsContainer: {
    paddingTop: "15rem",
  },
  paddingStyle: {
    paddingTop: "2rem"
  },
  buttonContainerSave: {
    maxWidth: "232px",
    backgroundColor: CONSTANTS.color.darkBlue,
  },
  cancelPlanButtonStyle: {
    maxWidth: "167px",
    minHeight: "56px"
  },
  marginStyle: {
    marginTop: "2rem"
  },
  buttonTextSave: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  crosssImage : {
    
    height:"35px"
  },
  boxImage : {
    display:"grid",
    justifyContent:"end",
    alignItem:"end"

  }
}
const ManageSnackbar = withSnackbar(ManagePlan)
export default withTranslation()(withStyles(styles)(ManageSnackbar));
// Customizable Area End.