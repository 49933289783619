import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { WithStyles } from "@material-ui/core/styles"
import { extensionDoc, extensionJpg, extensionPdf, extensionPng, extensionPpt, extensionTxt, extensionXls } from "./assets";
import { View } from "react-big-calendar";
// Customizable Area End

export interface EmployeeList {
  data?: EmployeeDetails[]
}
interface EmpItem {
  isSelected: boolean
  full_name: string
  employee_image: {
    url: string
  }
}
interface JobEvent{
  title: string;
  start: string | Date;
}
interface MockItem {
  attributes: EmpItem
}
export interface EmployeeDetails {
  id?: string,
  type?: string,
  attributes?: {
    full_name: string,
    phone_number?: string,
    email: string,
    reset_password_token?: string,
    status?: string,
    first_name?: string,
    last_name?: string,
    country_code?: null,
    country?: null,
    city?: null,
    street_address?: null,
    language?: null,
    post_code?: null,
    use_email_as_username?: boolean,
    resend_email_invitation?: boolean,
    reset_password_link?: {
      link?: string
    },
    default_billing_rate?: string,
    employee_image?: {
      url?: string
      content_type?: string,
      size?: number
    },
    billing_rates?: {
      data?: []
    },
    staff_permissions?: {
      data?: []
    }
  }
}
export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  history: any
  // Customizable Area Start
  
  getEmployeeDetail: any
  jobEditStatus: any
  employeeName: string
  mode: string
  sechudlingJob: any
  jobEditform: {
    jobTitle: string
  },
  employeeDetails:any
  getJobData: JobEvent[];
  siteId:string|number
  t: (key: string) => string;
  getEmployeeData:MockItem[]
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  teamAssigneDailog: boolean
  employeesList: EmployeeList[],
  employeeDetails: any
  isCalenderVisible: boolean,
  drawerExtensions:any
  selectDate:Date
  startDate: Date
  datePickerIsOpen: boolean
  view:View
  selectedDate: string  
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TeamEmployeeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetEmployess: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      teamAssigneDailog: false,
      selectDate:new Date(),
      startDate: new Date(),
      datePickerIsOpen: false,
      view:'month',
      selectedDate: '',
      employeesList: [],
      employeeDetails: [],
      isCalenderVisible: true,
      drawerExtensions:{
        jpg: extensionJpg,
        png: extensionPng,
        jpeg: extensionJpg,
        doc: extensionDoc,
        pdf: extensionPdf,
        xls: extensionXls,
        txt: extensionTxt,
        ppt: extensionPpt,
        docx: extensionDoc,
        xlsx: extensionXls,
        pptx: extensionPpt,
      }

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start

    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.apiCallIdGetEmployess) {
        if (responseJson && !responseJson.errors) {
          this.setState({ employeesList: responseJson.data })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  convertToCalendarEvents = (jobs:any) => {
    console.log("jobs", jobs)
    const events:any = [];
  
    jobs.forEach((job:any) => {
      let startDate = new Date(job.attributes.start_date);
      const endDate = new Date(job.attributes.end_date);
      const every = job.attributes.every || 1;

      while (startDate <= endDate) {
        events.push({
          title: job.title,
          start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 9, 0), 
          end: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 10, 0), 
        });
  
        startDate.setDate(startDate.getDate() + every);
      }
    });
  
    return events;
  };
  handleMonthChange = (date: Date) => {
    this.setState({ startDate: date }, () => {
      this.setState({ datePickerIsOpen: false })
    })
  }
  handleDecreaseDay = () => {
    this.setState((prevState)=>{
      const newDay = new Date(prevState.startDate);
      newDay.setDate(newDay.getDate()-1);
      return {startDate: newDay};
    });
  };
  handleIncreaseDay = () => {
    this.setState((prevState)=>{
      const newDay = new Date(prevState.startDate);
      newDay.setDate(newDay.getDate()+1);
      return {startDate: newDay};
    });
  };
  openDatePicker = () => {
    this.setState({ datePickerIsOpen: true })
  }
  closeDatePicker = () => {
    this.setState({ datePickerIsOpen: false })
  }
  handleViewChange = () => {
    this.setState({ view: 'week' })
  }
  handleMonthViewChange = () => {
    this.setState({ view: 'month' })
  }
  handleDayViewChange = () => {
    this.setState({ view: 'day' })
  }
  openAssignee = (propEmployeeDetails:any) => {
    if (this.props.jobEditStatus) {
      this.setState({ teamAssigneDailog: true , employeeDetails:propEmployeeDetails}, () => {
        this.getEmployess()
      });
    }
  }
  getEmployess = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = configJSON.employeesEndPoint
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetEmployess = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${apiEndPoint}?site_id=${this.props.siteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCreateEmployee = () => {
    this.props.navigation?.history.push("/CreateEmployee")
  }

  handleToggleColumn = (employee: any,event:any) => {
   
    if (event.target.checked) {
      this.setState({ employeeDetails: [...this.state.employeeDetails, { id: employee?.id, first_name: employee?.attributes?.full_name }] },()=>{
        this.props.getEmployeeDetail(this.state.employeeDetails)
      })
    } else {
      this.setState({ employeeDetails: [...this.state.employeeDetails.filter((item: any) => item.id != employee?.id)] },()=>{
        this.props.getEmployeeDetail(this.state.employeeDetails)
      })
    }
    
  }

  closeEmployeeDailog = () => {
    this.setState({ teamAssigneDailog: false });
  }
  
  showCalender=()=>{
    this.setState({ isCalenderVisible: !this.state.isCalenderVisible })

  }
  // Customizable Area End
}
