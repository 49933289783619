import React from "react";
import {
    // Customizable Area Start
    Typography, withStyles, Theme, StyleRulesCallback, Grid, Button,Tooltip,Box
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import moment from "moment";
const localizer = momentLocalizer(moment)
import TeamEmployeeController, { Props } from "./TeamEmployeeController.web";
import { CONSTANTS } from "../../../components/src/theme";
import{createStyles} from "@material-ui/core/styles"
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DatePicker from "react-datepicker";
import calendarStyles from '../../appointmentmanagement/src/Calendar.style'
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { checkEventOrNot, checkBoxShadowEventOrNot } from "../../../components/src/CommonFunction";
const selectedLanguage = localStorage.getItem("lang");
// Customizable Area End

export class JobsCalender extends TeamEmployeeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    viewDay = () => {
        if (this.state.view === 'day') {
            if (this.state.selectedDate) {
                return moment(new Date(this.state.selectedDate)).format("dddd MMM DD").toString()
            } else {
                return moment(new Date(this.state.startDate)).format("dddd MMM DD").toString()
            }
        }
    }
    dateFormate(classes: ClassNameMap<string>) {
        const curr = new Date(this.state.startDate);
        const first = curr.getDate() - curr.getDay();
        const last = first + 6;
        const firstDay = moment(new Date(curr.setDate(first))).format("MMM DD").toString();
        const lastDay = moment(new Date(curr.setDate(last))).format("MMM DD").toString();
        return <>
            <Typography className={classes.fontWeight_600} variant="subtitle1">
                {this.state.view === 'month' && moment(new Date(this.state.startDate)).format("MMMM YYYY").toString()}
                {this.state.view === 'week' && `${firstDay} - ${lastDay}`}
                {this.viewDay()}
            </Typography>
            {this.state.datePickerIsOpen === true ?
                <Button data-testid='closeMonthPicker' onClick={this.closeDatePicker} className={classes.dropdownIconBtn}>
                    <ExpandLessIcon style={{ color: 'darkGrey' }} />
                </Button> :
                <Button data-testid='openMonthPicker' onClick={this.openDatePicker} className={classes.dropdownIconBtn}>
                    <ExpandMoreIcon style={{ color: 'darkGrey' }} />
                </Button>
            }
        </>
    }
    datePicker(classes: ClassNameMap<string>) {
        return <>{this.state.datePickerIsOpen === true && this.state.view === "week" ?
            <DatePicker
                locale="ar"
                selected={this.state.startDate}
                onChange={this.handleMonthChange}
                open={this.state.datePickerIsOpen}
                showWeekPicker
                showPopperArrow={false}
                className={classes.customPicker}
                calendarClassName={classes.customWeekPicker}
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth
                }) => (
                    <div
                        style={{
                            margin: "0px 18px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span className={`${classes.cursor} ${classes.arrowIcon}`} onClick={decreaseMonth}>{"<"}</span>
                        <span className={`${classes.font_16} ${classes.fontWeight_500}`}>{moment(date).format("MMMM YYYY")}</span>
                        <span className={`${classes.cursor} ${classes.arrowIcon}`} onClick={increaseMonth}>{">"}</span>
                    </div>
                )}
                renderDayContents={(_, date) => {
                    return <span>{moment(date).format("DD")}</span>;
                }}
            />
            : null}
            {this.state.datePickerIsOpen === true && this.state.view === "month" ?
                <DatePicker
                    locale="ar"
                    renderCustomHeader={({
                        date,
                        decreaseYear,
                        increaseYear,
                    }) => (
                        <div
                            style={{
                                margin: 18,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span className={classes.cursor} onClick={decreaseYear}>{"<"}</span>
                            <span className={classes.font_22}>{moment(date).format("YYYY")}</span>
                            <span className={classes.cursor} onClick={increaseYear}>{">"}</span>
                        </div>
                    )}
                    selected={this.state.startDate}
                    onChange={this.handleMonthChange}
                    open={this.state.datePickerIsOpen}
                    showMonthYearPicker
                    showFourColumnMonthYearPicker
                    showPopperArrow={false}
                    className={classes.customPicker}
                    calendarClassName={classes.customMonthPicker}
                />
                : null
            }
            {this.dayPicker(classes)}
        </>
    }
    dayPicker(classes: ClassNameMap<string>){
        const getDayName = (date: Date) => {
            const daysOfWeek = [
                `${this.props.t('settings.sunday')}`,
                `${this.props.t('settings.monday')}`,
                `${this.props.t('settings.tuesday')}`,
                `${this.props.t('settings.wednesday')}`,
                `${this.props.t('settings.thursday')}`,
                `${this.props.t('settings.friday')}`,
                `${this.props.t('settings.saturday')}`,
            ];
            return daysOfWeek[date.getDay()];
        };
        const dayName = getDayName(this.state.startDate);
        return this.state.datePickerIsOpen === true && this.state.view === 'day' ?
            <DatePicker
                locale="ar"
                selected={this.state.startDate}
                onChange={this.handleMonthChange}
                open={this.state.datePickerIsOpen}
                showPopperArrow={false}
                className={classes.customPicker}
                calendarClassName={classes.customWeekPicker}
                renderCustomHeader={() => (
                    <div
                        style={{
                            margin: "0px 18px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span data-testid='decreaseDay' className={classes.cursor} onClick={this.handleDecreaseDay}>{"<"}</span>
                        <span className={classes.font_22}>{dayName}</span>
                    <span data-testid='increaseDay'className={classes.cursor} onClick={this.handleIncreaseDay}>{">"}</span>
                    </div>
                )}
                renderDayContents={(_, date) => {
                    return <span>{moment(date).format("DD")}</span>;
                }}
            />
            : null
    }
    dayBtn(classes: ClassNameMap<string>) {
        return <Button
            data-testid='dayView'
            className={this.state.view === 'day' ? classes.activeBtn : classes.toolBarBtn}
            onClick={this.handleDayViewChange}>
            <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>
                {this.props.t('Calendar.button.day')}
            </Typography>
        </Button>
    }
    weekBtn(classes: ClassNameMap<string>) {
        return <Button data-testid='weekView'
            className={this.state.view === 'week' ? classes.activeBtn : classes.toolBarBtn}
            onClick={this.handleViewChange}>
            <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>
                {this.props.t('Calendar.button.week')}
            </Typography>
        </Button>
    }
    monthBtn(classes: ClassNameMap<string>) {
        return <Button data-testid='monthView'
            className={this.state.view === 'month' ? classes.activeBtn : classes.toolBarBtn}
            onClick={this.handleMonthViewChange}
        >
            <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>
                {this.props.t('Calendar.button.month')}
            </Typography>
        </Button>
    }
    BgColor(events:object[] | undefined, event: Date) {
        if (checkEventOrNot({ eventDate: event, eventsArray: events })) {
            return '#E9EAFF'
        } else {
            return ''
        }
    }
    eventHorizontalLine(events: object[] | undefined, event: Date) {
        if (checkBoxShadowEventOrNot({ eventDate: event, eventsArray: events })) {
            return 'inset 4px 0em #1c18af'
        } else {
            return ''
        }
    }
    customDayPropGetter(event: Date) {
        return {
            style: {
                background: this.BgColor(this.props.getJobData, event),
                boxShadow: this.state.view === 'month' ? this.eventHorizontalLine(this.props.getJobData, event) : '',
            },
        }
    }
    dateHeaderStyle(date: { isOffRange: boolean; date: Date; }, events: object[] | undefined) {
        if (date.isOffRange === true) {
            return 'white'
        } else {
            if (checkEventOrNot({ eventDate: date.date, eventsArray: events })) {
                return '#1c18af'
            } else {
                return '#626262'
            }
        }
    }
    eventStyleGetter(event: object) {
        let style = {
            color: '#1c18af'
        };
        return {
            style: style
        };
    }
    // Customizable Area End

    render() {
        const { classes, t } = this.props;
        return (
            // Customizable Area Start
            <>
                {this.state.isCalenderVisible ?
                    <Grid container className={this.props.mode != "converted" ? classes.modeCalendarMainDiv : classes.calendarMainDiv}>
                        <Grid item xs={12} sm={12} md={12} className={classes.hideCalenderButton}>
                            <Typography
                                className={classes.hideCalenderText}
                                variant="body1"
                                data-test-id="hide_calender"
                                onClick={this.showCalender}>
                                {t('jobs.label.hideCalendar')}
                                <KeyboardArrowDownIcon />
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={3} md={3}>
                            <Button className={`${classes.todayBtn} ${classes.darkBlue_color} ${classes.text_border_gray}`}>
                                <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>{t('Calendar.button.today')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={8} sm={5} md={5}>
                            <Grid container alignItems="center" className={classes.justifyContent}>
                                {this.dateFormate(classes)}
                                {this.datePicker(classes)}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className={`${classes.assignedToBtn} ${classes.mt_30}`} style={{ alignItems: 'center', display: 'flex' }}>
                            {this.dayBtn(classes)}
                            {this.weekBtn(classes)}
                            {this.monthBtn(classes)}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.mt_23}>
                            <Calendar key={this.state.startDate.toISOString()}
                                localizer={localizer}
                                className={`${classes.jobCalendar} ${classes.calendarHeight} ${classes.customBigCalender}`}
                                defaultDate={this.props.sechudlingJob?.jobStartDate || new Date()}
                                events={this.convertToCalendarEvents(Array.isArray(this.props.getJobData) ? this.props.getJobData : [])}
                                defaultView="month"
                                timeslots={6}
                                view={this.state.view}
                                date={new Date(this.state.startDate)}
                                                                           

                            />

                        </Grid>
                    </Grid> :
                    <Grid container className={this.props.mode != "converted" ? classes.hideModeCalender : classes.calendarMainDiv1}>
                        <Grid item xs={12} sm={12} md={12} className={classes.hideCalenderButton}>
                            <Typography
                                className={classes.hideCalenderText}
                                variant="body1"
                                data-test-id="show_hide_Calender"
                                onClick={this.showCalender}>
                                {t('jobs.label.showCalendar')}
                                <KeyboardArrowRightIcon/>
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const WhiteTooltip = withStyles({
    arrow: {
        '&.MuiTooltip-arrow': {
            color: CONSTANTS.color.white,
        },
    },
    tooltip: {
        borderRadius: "5px",
        background: CONSTANTS.color.white,
        minWidth: '250px',
        boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    }
})(Tooltip)
export const CalenderStyles:StyleRulesCallback<Theme, {}, string> = (theme: Theme) =>
    createStyles({
        modeCalendarMainDiv: {
            width: "100%",
            maxWidth: "809px",
        borderRadius:10,
            "& .rbc-btn-group": {
                display: 'none'
            },
            '& .rbc-toolbar': {
                display: 'none'
            },
            '& .rbc-calendar': {
            zIndex:0,
            position:"relative"
            },
            '& .rbc-header': {
                height: '50px',
                padding: '15px 10px 30px 40px',
            [theme.breakpoints.down('sm')]:{
                    padding: '0px !important',

                },
                [theme.breakpoints.down('xs')]: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'

                }
            },
            '& .rbc-date-cell': {
                padding: '15px 30px 0px 0px'
            },
        },
        customBigCalender: {
            '& .rbc-month-view': {
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
                marginTop: '5px',
          height:'calc(100% - 5px)',
                border: 'none',
                zIndex: 0,
                '& .rbc-show-more': {
                    color: CONSTANTS.color.darkBlue,
                    marginLeft: '6px'
                },
                '& .rbc-month-header': {
                    '& .rbc-header': {
                        [theme.breakpoints.down("xs")]: {
                            padding: "10px !important"
                        },
                        padding: '25px',
                        fontWeight: 400,
                        color: 'rgb(153, 153, 153)'
                    },
                },
                '& .rbc-month-row': {
                    '& .rbc-row': {
                        '& .rbc-date-cell': {
                            padding: '10px 10px 0px 0px',
                            fontWeight: 600,
                        },
                        '& .rbc-off-range': {
                            color: 'white !important'
                        },
                        '& .rbc-row-segment': {
                            '& .rbc-event': {
                                background: 'none',
                            },
                            '& .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus': {
                                outline: 'none !important'
                            },
                            '& .rbc-event-content': {
                                color: CONSTANTS.color.darkBlue,
                                fontWeight: 600,
                                [theme.breakpoints.down('xs')]: {
                                    maxWidth: "35px"
                                }
                            }
                        }
                    },
                },
            },
            '& .rbc-time-view': {
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
                marginTop: '2px',
                border: 'none',
                zIndex:0,
                '& .rbc-header': {
                    borderBottom: 'none',
                    padding: '16px',
                },
            },
            '& .rbc-event-label': {
                whiteSpace: "pre-wrap !important",
            },
            '& .rbc-time-content': {
                overflowY: "clip",
          height:"100px"

            }
        },
        calendarHeight: {
            height: '525px',
            padding: '0px',
         overflow:'auto',
         display:'block'
        },
        mt_30: {
            [theme.breakpoints.down("xs")]: {
                marginTop: "30px"
            },
        },
        justifyContent: {
            [theme.breakpoints.down("xs")]: {
                justifyContent: "end"
            }
        },
        activeBtn: {
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
            background: CONSTANTS.color.darkBlue,
            color: CONSTANTS.color.white,
            textTransform: "capitalize",
            borderRadius: '6px',
            width: '100%',
            height: '40px',
            [theme.breakpoints.down("xs")]: {
                height: '35px',
            },
            '&:hover': {
                cursor: "pointer",
            border:"none",
                backgroundColor: CONSTANTS.color.blueViolet,
                color: theme.palette.common.white
            },
            transition: 'background-color 0.3s, color 0.3s',
            '&.MuiButton-root': {
                minWidth: '0px !important',
                width: '100% !important',
            }
        },
        toolBarBtn: {
            color: 'darkGrey',
            textTransform: "capitalize",
            width: '100%',
            height: '40px',
            '&:hover': {
                cursor: "pointer",
            border:"none",
                backgroundColor: CONSTANTS.color.blueViolet,
                color: theme.palette.common.white
            },
            transition: 'background-color 0.3s, color 0.3s',
            [theme.breakpoints.down("xs")]: {
                height: '35px',
            },
            '&.MuiButton-root': {
                minWidth: '0px !important',
                width: '100% !important',
            }
        },
        assignedToBtn: {
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
            background: CONSTANTS.color.white,
            textTransform: "capitalize",
            border: 'none',
            color: 'darkGrey',
            height: '40px',
            [theme.breakpoints.down("xs")]: {
                height: '35px',
            },
            '&.MuiButton-root': {
                minWidth: '0px !important',
                width: '112% !important',
            }
        },
        todayBtn: {
            borderRadius: "8px",
            width: '85px',
            height: '40px',
            '&:hover': {
                cursor: "pointer",
            border:"none",
                backgroundColor: CONSTANTS.color.blueViolet,
                color: theme.palette.common.white
            },
            transition: 'background-color 0.3s, color 0.3s',
            textTransform: "capitalize",
            [theme.breakpoints.down("xs")]: {
                width: '50%',
                height: '40px',
            }
        },
        darkBlue_color: {
            color: CONSTANTS.color.darkBlue,
        },
        text_border_gray: {
            border: "1px solid darkGrey",
        },
        fontWeight_500: {
            fontWeight: CONSTANTS.fontWeight.fontWeight500,
        },
        fontWeight_600: {
            fontWeight: CONSTANTS.fontWeight.fontWeight600,
        },
        mr_9: {
            marginRight: "19px"
        },
        marginLeft_9: {
            marginLeft: '9px'
        },
     mt_23:{
        marginTop:"23px"
        },
        dropdownIconBtn: {
            minWidth: "0px",
            padding: "0px"
        },
        font_14: {
            fontSize: "14px",
            [theme.breakpoints.down("xs")]: {
                fontSize: '12px !important'
            },
        },
    jobCalendar:{
            '& .rbc-day-bg': {
            borderLeft: selectedLanguage === 'ar' ? '1px solid #ddd':""
            },
            '& .rbc-month-view .rbc-month-header .rbc-header': {
            borderLeft: selectedLanguage === 'ar' ? '1px solid #ddd':""
            },
        '& .rbc-month-view':{
            borderLeft: selectedLanguage === 'ar' ? 'none' :"" 
            },
        '& .rbc-button-link':{
            whiteSpace:"normal",
            wordWrap:"brake-word",
            textAlign:"center",
            paddingBottom:"16px"
            },
        },
        calendarMainDiv: {
            marginTop: '30px',
            height: '500px',
            width: "100%",
            maxWidth: "690px",
            "& .rbc-btn-group": {
                display: 'none'
            },
            '& .rbc-toolbar': {
                display: 'none'
            },
            '& .rbc-calendar': {
                marginTop: '10px'
            },
            '& .rbc-header': {
                height: '50px',
                padding: '15px 10px 30px 40px',
                // [theme.breakpoints.down('sm')]:{
                //     padding: '0px !important',

                // },
            [theme.breakpoints.down('xs')]:{
               display:'flex',
               justifyContent:'center',
               alignItems:'center'

                }

            },
            '& .rbc-date-cell': {
                padding: '15px 30px 0px 0px'
            },
        },
        hideModeCalender: {
            marginBottom: '0px',
            height: '76px',
            width: "100%",
            maxWidth: "809px",
        },
        calendarMainDiv1: {
            marginTop: '30px',
            marginBottom: '0px',
            height: '76px',
            width: "100%",
            maxWidth: "690px",
        },
        margin: {
            marginTop: "10px"
        },
        cursor: {
            cursor: "pointer"
        },
        fontSize_22: {
            fontSize: "22px"
        },
        customPicker: {
            display: "none",
        },
        customWeekPicker: {
            marginLeft: "-18em !important",
            [theme.breakpoints.down('xs')]: {
                marginLeft: "-16em !important",
            },
        },
        hideCalenderButton: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '50px',
            marginBottom: '10px',
        },
        hideCalenderText: {
        cursor:'pointer',
        display:"flex",
        alignItems:"center",
            color: CONSTANTS.color.darkBlue,
            fontWeight: theme.typography.fontWeightRegular
        }
    });
const calendarStyle = calendarStyles(JobsCalender)
export default withStyles(CalenderStyles)(calendarStyle)
// Customizable Area End
