import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import { performAuthorizationCheck, checkLoggedInUser ,sitesvalidateFormData, sitesvalidateFormDataHandle} from "./utility.web";
import {WithStyles} from '@material-ui/core';
import { getStorageData } from "../../../framework/src/Utilities";
interface Position1 { 
  lng: number;
  lat: number;
}
interface LocationData {
  errors: string,
  longitude: number,
  latitude: number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;

  // Customizable Area Start
  history: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  siteDialogOpen: boolean;
  siteInformationFormData: {
    site_name: string;
    email: string;
    phone_number: string;
    site_address: any;
    state: string;
    city: string;
    zip: string;
    latitude: any;
    longitude: any;
    country:string
  };
  textAddress: string;
  isErrorFormData: any;
  selectedSite: any;
  createCustomerSiteLoading: boolean;
  getCustomerSiteLoading: boolean;
  showActionBtn: boolean;
  deleteDialogOpen: boolean;
  deleteCustomerSiteLoading: boolean;
  editCustomerSiteLoading: boolean;
  showUpdateButton: boolean;
  showFields: boolean;
  siteInformationData: any;
  sortingOrder: string;
  lastClickedProperty: any;
  sortingProperty: any;
  searchQuery: string;
  timer: any;
  mapsLibraries: any;
  center: {
    lat: number;
    lng: number;
  };
  selectedPlace: any;
  mapLoading: boolean;
  autoCompleteInput:string
  lang:string
  siteModal:boolean,
  currentPosition1: Position1;
  siteDetails:any
  latitude: number,
  longitude: number
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomerSitesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCustomerSiteApiCallId: string = "";
  getCustomerSiteApiCallId: string = "";
  searchCustomerSiteApiCallId: string = "";
  editCustomerSiteApiCallId: string = "";
  deleteCustomerSiteApiCallId: string = "";
  getCustomerByIdApiCallId: string = "";
  getCountryApiCallId: string = ""
  mapRef: any;
  autocomplete: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.mapRef = React.createRef();
    this.autocomplete = null;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      siteDetails:{},
      siteModal:false,
      siteDialogOpen: false,
      siteInformationFormData: {
        site_name: "",
        email: "",
        phone_number: "",
        site_address: "",
        state: "",
        city: "",
        zip: "",
        latitude: "",
        longitude: "",
        country:""
      },
      textAddress: "",
      isErrorFormData: {},
      selectedSite: "",
      createCustomerSiteLoading: false,
      getCustomerSiteLoading: false,
      editCustomerSiteLoading: false,
      deleteCustomerSiteLoading: false,
      showActionBtn: false,
      deleteDialogOpen: false,
      showUpdateButton: false,
      showFields: true,
      siteInformationData: [],
      sortingOrder: "desc",
      lastClickedProperty: null,
      sortingProperty: "id",
      searchQuery: "",
      timer: "",
      mapsLibraries: ["places"],
      center: { lat: 45.5344, lng: 23.555 },
      selectedPlace: null,
      mapLoading: false,
      autoCompleteInput:"",
      lang:"en",
      currentPosition1:{
        lng: 0,
        lat: 0
      },
      latitude:0,
      longitude:0
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getLocation()
    window.scrollTo(0, 0);
    navigator.geolocation.getCurrentPosition(position => {
      this.setState({ currentPosition1: { lng: position.coords.longitude ,lat: position.coords.latitude} });
    }, error => {
      if (error.code === error.PERMISSION_DENIED) {
        const defaultLocation = { lat: this.state.latitude, lng: this.state.longitude };
        this.setState({ currentPosition1: defaultLocation });
      }});
    const lang = await getStorageData("lang")
    this.setState({ lang });
    this.getCustomerSites();
    this.setState({ getCustomerSiteLoading: true });

    if (
      localStorage.getItem("customer_id") ||
      localStorage.getItem("editedCustomerId")
    ) {
      this.handleProps();
    }

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createCustomerSiteApiCallId) {
        if (!responseJson.errors) {
          this.setState({ siteDialogOpen: false });

          this.setState({
            siteInformationFormData: {
              ...this.state.siteInformationFormData,
              site_name: "",
              email: "",
              phone_number: "",
              site_address: "",
              city: "",
              state: "",
              zip: "",
              country:""
            },
          });
          this.props.history.push("/CustomerRequests");
          this.getCustomerSites();
        } else {
          //Check Error Response
          this.setState({
            createCustomerSiteLoading: false,
            siteDialogOpen: false,
          });
          this.handleSitesTokenExpireError(responseJson);
        }
        this.setState({
          siteDialogOpen: false,
          createCustomerSiteLoading: false,
        });
        this.parseApiCatchErrorResponse(errorResponse);
      } else if(apiRequestCallId === this.getCountryApiCallId){
        this.getCountryResponse(responseJson)
      }
    }
    this.handleGetCustomerSitesData(message);
    this.handleSearchCustomers(message);
    this.handleDeleteCustomerSitesData(message);
    this.handleEditCustomerSitesData(message);
    this.handleGetCustomerByIdData(message);
    // Customizable Area End
  }

  // Customizable Area Start
  getLocation = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token,
      "Content-Type": configJSON.dashboardContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryLocation}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCountryResponse = (responseJson: LocationData) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        latitude: responseJson.latitude,
        longitude: responseJson.longitude
      });
    }
  }
  handleGetCustomerSitesData = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleGetSitesDataCallback(
      apiRequestCallId,
      responseJson,
      errorResponse,
      this.getCustomerSiteApiCallId
    );
  };
  handleGetSitesDataCallback = async (
    apiRequestCallId: any,
    responseJson: any,
    errorResponse: any,
    getCustomerSiteApiCallId: any
  ) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === getCustomerSiteApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            return this.props.openToastHandler(
              this.props.t("customers.unAuth"),
              "error"
            );
          }
          this.setState({ getCustomerSiteLoading: false });
          this.setState({ siteInformationData: responseJson.data });
        } else {
          //Check Error Response
          this.setState({ getCustomerSiteLoading: false });
          this.handleSitesTokenExpireError(responseJson);
        }
        this.setState({ getCustomerSiteLoading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };
  handleEditCustomerSitesData = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.editCustomerSiteApiCallId) {
        if (!responseJson.errors) {
          this.setState({ editCustomerSiteLoading: false });
          this.setState({ siteDialogOpen: false });
          this.setState({ showFields: false });
          this.setState({ showActionBtn: false });
          this.setState({ showUpdateButton: false });
          this.setState({ selectedSite: "" });
          this.getCustomerSites();
        } else {
          //Check Error Response
          this.setState({
            editCustomerSiteLoading: false,
            siteDialogOpen: false,
          });
          this.handleSitesTokenExpireError(responseJson);
        }
        this.setState({
          siteDialogOpen: false,
          editCustomerSiteLoading: false,
        });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };

  handleDeleteCustomerSitesData = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteCustomerSiteApiCallId) {
        if (!responseJson.errors) {
          this.setState({ deleteCustomerSiteLoading: false , deleteDialogOpen:false});
          this.getCustomerSites();
        } else {
          //Check Error Response
          this.setState({ deleteCustomerSiteLoading: false });
          this.handleSitesTokenExpireError(responseJson);
        }
        this.setState({ deleteCustomerSiteLoading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };

  handleGetCustomerByIdData = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleGetSitesByIdDataCallback(
      apiRequestCallId,
      responseJson,
      errorResponse,
      this.getCustomerByIdApiCallId
    );
  };
  handleGetSitesByIdDataCallback = async (
    apiRequestCallId: any,
    responseJson: any,
    errorResponse: any,
    getCustomerByIdApiCallId: any
  ) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === getCustomerByIdApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            return this.props.openToastHandler(
              this.props.t("customers.unAuth"),
              "error"
            );
          }
          const { phone_number, email, latitude, longitude } =
            responseJson.data.attributes;
          this.setState({
            siteInformationFormData: {
              ...this.state.siteInformationFormData,
              email,
              phone_number: phone_number,
              latitude,
              longitude,
            },
          });
        } else {
          //Check Error Response
          await this.handleSitesTokenExpireError(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };

  handleSitesTokenExpireError = async (responseJson: any) => {
    if (
      responseJson.errors[0]?.token == "Token has Expired" ||
      responseJson.errors[0]?.token == "Invalid token"
    ) {
      this.props?.openToastHandler(responseJson?.errors[0].token, "error");
      this.sitestokenExpired();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  sitestokenExpired = async () => {
    const isEmployee = checkLoggedInUser();
    this.tokenSitesExpiredCallback(isEmployee);
  };

  tokenSitesExpiredCallback = (isEmployee: any) => {
    const redirectUrl = isEmployee ? "/Employee/Login" : "/Login";
    setTimeout(
      this.tokenSitesExpiredTimeCallback.bind(this, redirectUrl),
      1000
    );
  };

  tokenSitesExpiredTimeCallback = (url: any) => {
    localStorage.clear();
    this.props.history.push(url);
  };
  getHttpBody = (customer_id:any) => {
    const {
      site_name,
      email,
      phone_number,
      city,
      state,
      zip,
      site_address,
      latitude,
      longitude,
      country
    } = this.state.siteInformationFormData;
    const data = {
      site_name: String(site_name).trim(),
      phone_number: String(phone_number).trim(),
      email: String(email).trim(),
      city: String(city).trim(),
      state: String(state).trim(),
      zip_code: String(zip).trim(),
      site_address: String(site_address).trim(),
      latitude,
      longitude,
      country
    };
    if (customer_id) {
      return { data: { ...data, customer_id } }
    } else {
      return {data}
    }

  }
  handleSearchCustomers = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleSearchCustomersCallback(
      apiRequestCallId,
      responseJson,
      errorResponse,
      this.searchCustomerSiteApiCallId
    );
  };
  handleSearchCustomersCallback = async (
    apiRequestCallId: any,
    responseJson: any,
    errorResponse: any,
    getCustomerSiteApiCallId: any
  ) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === getCustomerSiteApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            return this.props.openToastHandler(
              this.props.t("customers.unAuth"),
              "error"
            );
          }
          this.setState({ getCustomerSiteLoading: false });
          this.setState({ siteInformationData: responseJson.data });
        } else {
          //Check Error Response
          this.setState({ getCustomerSiteLoading: false });
          this.handleSitesTokenExpireError(responseJson);
        }
        this.setState({ getCustomerSiteLoading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };
  searchCustomerSites = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };
    const customer_id = localStorage.getItem("customer_id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const sitesUrl = this.getSitesUrl(customer_id, this.state.sortingOrder);

    this.searchCustomerSiteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      sitesUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCustomerSiteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getSitesUrl = (customer_id: any, sortingOrder: any) => {
   return sortingOrder === ""
      ? `account_block/customer_data?type=sites&customer_id=${customer_id}&query=${this.state.searchQuery}&lang=${this.state.lang}`
      : `account_block/customer_data?type=sites&customer_id=${customer_id}&query=${this.state.searchQuery}&field=${this.state.sortingProperty}&order=${this.state.sortingOrder}&lang=${this.state.lang}`;
  };

  getCustomerSites = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };
    const customer_id = localStorage.getItem("customer_id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const sitesUrl = this.getSitesUrl(customer_id, this.state.sortingOrder);
    this.getCustomerSiteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      sitesUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCustomerSiteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  createCustomerSite = () => {


    const customer_id = localStorage.getItem("customer_id");
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };

    const httpBody = this.getHttpBody(customer_id)  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCustomerSiteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_location/site_informations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  updateCustomerSite = () => {

    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };

    const httpBody = this.getHttpBody("")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCustomerSiteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_location/site_informations/${this.state.selectedSite}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteCustomerSite = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCustomerSiteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_location/site_informations/${this.state.selectedSite}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getCustomerById = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };

    const editedCustomerId =
      localStorage.getItem("customer_id") ??
      localStorage.getItem("editedCustomerId");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerByIdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/customers/${editedCustomerId}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  onLoadSite = (autocomplete: any) => {
    this.autocomplete = autocomplete;
    this.setState({ mapLoading: true });
  };

  handleGoogleFormDataSite = (
    state: string,
    city: string,
    zipcode: any,
    formattedAddress: string,
    location: any,
    countrySites:string
  ) => {
    this.setState({
      siteInformationFormData: {
        ...this.state.siteInformationFormData,
        latitude: location.lat(),
        longitude: location.lng(),
      },
      autoCompleteInput:formattedAddress
    });
  };
  handleGeocodeResponseSite = (status: string, results: any) => {
    if (status === "OK") {
      const addressComponentsSites = results[0].address_components;
      const formattedAddressSites = results[0].formatted_address;
      let stateSites = "";
      let zipcodeSites = "";
      let citySites = "";
      let countrySites = "";

      addressComponentsSites.forEach((component: any) => {
        if (component.types.includes("administrative_area_level_1")) {
          stateSites = component.long_name;
        } else if (component.types.includes("postal_code")) {
          zipcodeSites = component.long_name;
        } else if (component.types.includes("locality")) {
          citySites = component.long_name;
        } else if (component.types.includes("country")) { // Check for country
          countrySites = component.long_name;
        }
      });
      this.handleGoogleFormDataSite(
        stateSites,
        citySites,
        zipcodeSites,
        formattedAddressSites,
        results[0].geometry.location,
        countrySites
      );
    }
  };
  handleGeocodeCallbackSite = (
    addressComponents: any,
    formattedAddress: string,
    location: any
  ) => {
    let stateSitesV1 = "";
    let zipcodeSitesV1 = "";
    let citySitesV1 = "";
    let countrySitesV1 = "";

    addressComponents.forEach((component: any) => {
      if (component.types.includes("administrative_area_level_1")) {
        stateSitesV1 = component.long_name;
      } else if (component.types.includes("postal_code")) {
        zipcodeSitesV1 = component.long_name;
      } else if (component.types.includes("locality")) {
        citySitesV1 = component.long_name;
      }else if (component.types.includes("country")) {
        countrySitesV1 = component.long_name;
      }
    });
    this.handleGoogleFormDataSite(
      stateSitesV1,
      citySitesV1,
      zipcodeSitesV1,
      formattedAddress,
      location,
      countrySitesV1
    );
  };

  handleGeocodeCallSite = async (place: any) => {
    const addressComponentsSitesV1 = place.address_components;
  
    const formattedAddressSitesV1 = place.formatted_address;
    if (addressComponentsSitesV1 && formattedAddressSitesV1) {
      this.handleGeocodeCallbackSite(
        addressComponentsSitesV1,
        formattedAddressSitesV1,
        place.geometry.location
      );
    } else {
      const placeV1 = this.autocomplete?.getPlace();
      const geocoder = new window.google.maps.Geocoder();
      try {
        await geocoder.geocode(
          { placeId: placeV1 ? placeV1.place_id : place.place_id },
          (results: any, status: any) => {
            this.handleGeocodeResponseSite(status, results);
          }
        );
      } catch (error) {}
    }
  };

  fetchAddressSite = async (place: any) => {
    try {
      await this.handleGeocodeCallSite(place);
      if (place.geometry) {
        this.setState({ selectedPlace: place.geometry.location }, () => {
          const map = this.mapRef.current;
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(place.geometry.location);
          map.fitBounds(bounds, 100);
          map.setZoom(15);
        });
      }
    } catch (error) {}
  };

  handleKeyPressSite = async (event: any) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value;

      const geocoder = new window.google.maps.Geocoder();
      try {
        await geocoder.geocode({ address: inputValue }, (results, status) => {
          if (status === "OK" && results && results.length > 0) {
            const place = results[0];
            this.fetchAddressSite(place);
          }
        });
      } catch (error) {}
    }
  };
  onPlaceChangedSite = async () => {
    if (this.autocomplete != null) {
      const place = this.autocomplete?.getPlace();

      try {
        await this.handleGeocodeCallSite(place);
        if (place.geometry) {
          this.setState({ selectedPlace: place.geometry.location }, () => {
            const map = this.mapRef.current;
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(place.geometry.location);
            map.fitBounds(bounds, 100);
            map.setZoom(15);
          });
        }
      } catch (error) {}
    }
  };

  handleProps = () => {
    this.getCustomerById();
  };

  handleChangeSearchCustomerSite = (e: any) => {
    const inputValue = e.target.value;
    clearTimeout(this.state.timer);
    const timer = setTimeout(this.changeTime, 500);
    this.setState({ searchQuery: inputValue, timer });
  };

  changeTime = () => {
    this.executeInputChange();
  };

  executeInputChange() {
    // Perform the input change logic here, e.g., call an API with the new input value.
    this.setState({ getCustomerSiteLoading: true });
    this.searchCustomerSites();
  }

  handleClickOpenDialog = () => {
    this.setState({ deleteDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleClickProceedDelete = async () => {
    const isAuthorized = await checkLoggedInUser();
    this.handleClickProceedDeleteCallback(isAuthorized);
  };
 
  handleClickProceedDeleteCallback = async (isAuthorized: any) => {
    if (!isAuthorized) {
      //false = either BO or no permissions
      this.deleteCustomerSite();
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };
  handleEdit = async (item: any) => {
    const isAuthorized = await performAuthorizationCheck("customers_data"); //true = not authorized , false = authorized
    this.handleEditSitesCall(isAuthorized, item);
  };
  handleEditSitesCall = (isAuthorized: any, item: any) => {
    if (!isAuthorized) {
      this.setState({ siteDialogOpen: true, isErrorFormData: {} });
      this.setState({ showFields: false });
      this.setState({ showActionBtn: true });
      this.setState({ showUpdateButton: false });
      const map = this.mapRef.current;

      if (map) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(this.state.selectedPlace);
        map.fitBounds(bounds, 100);
        map.setZoom(10);
      }
      this.setState({
        siteInformationFormData: {
          ...this.state.siteInformationFormData,
          site_name: item.site_name,
          email: item.email,
          phone_number: item.phone_number,
          site_address: item.site_address,
          city: item.city,
          state: item.state,
          zip: item.zip_code,
          country:item.country
        },
      });

      this.setState({
        textAddress: item.address,
      });
      this.setState({ selectedSite: item.id });
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };
  handleSelectedSite = async (item: any) => {
    const isAuthorized = await performAuthorizationCheck("customers_data");
    this.handleSelectedSiteCall(isAuthorized, item);
  };

  handleSelectedSiteCall = (isAuthorized: any, item: any) => {
    if (!isAuthorized) {
      this.setState({
        selectedPlace: {
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        },
      });
      //false = either BO or no permissions
      this.setState({ selectedSite: item.id });
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };

  handleEditButton = () => {
    this.setState({ showUpdateButton: true });
    this.setState({ showFields: true });
  };

  handleUpdateCall = () => {
    if (Object.keys(this.state.isErrorFormData).length === 0) {
      this.setState({ editCustomerSiteLoading: true });
      this.updateCustomerSite();
    }
  };

  handleUpdateButton = () => {
    this.setState({ showUpdateButton: false });
    this.setState(
      {
        isErrorFormData: sitesvalidateFormData(
          this.state.siteInformationFormData,this.props.t
        ),
      },
      this.handleUpdateCall
    );
  };

  handleAddNewSite = async () => {
    const customer_id = localStorage.getItem("customer_id");
    await this.checkCreatedCustomer(customer_id);
  };

  checkCreatedCustomer = async (customer_id: any) => {
    if (customer_id) {
      const isAuthorized = await performAuthorizationCheck("customers_data");
      this.handleAddNewSiteCall(isAuthorized);
    } else {
      this.props.openToastHandler(this.props.t("customers.noCustomer"), "error");
    }
  };

  handleAddNewSiteCall = (isAuthorized: any) => {
    if (!isAuthorized) {
      //false = either BO or no permissions
      this.setState({ siteDialogOpen: true });
      this.setState({ showFields: true });
      this.setState({ showActionBtn: false });
      this.setState({
        siteInformationFormData: {
          ...this.state.siteInformationFormData,
          site_name: "",
          email: "",
          phone_number: "",
          site_address: "",
          city: "",
          state: "",
          zip: "",
          country:""
        },
      });
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };

  handleCloseSiteDialog = () => {
    this.setState({ siteDialogOpen: false, selectedSite: "" ,  });
  };

  handleChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      siteInformationFormData: {
        ...this.state.siteInformationFormData,
        [e.target.name]: e.target.value,
      },
    },()=>{
      this.setState({
        isErrorFormData: sitesvalidateFormDataHandle(
          this.state.siteInformationFormData,this.props.t, e.target.name
        ),
      })
    });
  };
 
  handleChangeFormDataInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      autoCompleteInput: e.target.value,
    },()=>{
      this.setState({
        isErrorFormData: sitesvalidateFormData(
          this.state.siteInformationFormData,this.props.t
        ),
      })
    });
  };
  sortByProperty = (property: any) => {
    this.setState(
      {
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
        sortingProperty: property,
      },
      this.sortByPropertyCallback
    );
  };

  sortByPropertyCallback = () => {
    this.setState({ getCustomerSiteLoading: true });
    this.getCustomerSites();
  };

  handleSaveCall = () => {
    if (Object.keys(this.state.isErrorFormData).length === 0) {
      this.createCustomerSite();
    }
  };

  handleSaveSiteInformation = () => {
    this.setState({ showActionBtn: false });
    this.setState(
      {
        isErrorFormData: sitesvalidateFormData(
          this.state.siteInformationFormData,this.props.t
        ),
      },
      this.handleSaveCall
    );
  };

  handleMarkerDragSites = async (event: any) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    try {
      const { address }: any = await this.getAddressFromCoordinatesSites(newLat, newLng);
      this.setState({
        siteInformationFormData: {
          ...this.state.siteInformationFormData,
          latitude:newLat,
          longitude:newLng
        },
        autoCompleteInput:address
      });
    } catch (error) {
      this.props.openToastHandler(this.props.t("customers.addressErr"), "error");
    }
  };

  getAddressFromCoordinatesSites = async (lat: any, lng: any) => {
    const apiKey = "AIzaSyD--KtNzcav1Kh1toELW4RHw-NqiFVpeH8";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      return this.getAddressFromCoordinatesSitesCallback(data);
    } catch (error) {
      this.props.openToastHandler(this.props.t("customers.addressErr"), "error");
      return null;
    }
  };
  getAddressFromCoordinatesSitesCallback = (data: any) => {
    if (data.results.length > 0) {
      const result = data.results[0];
      const formattedAddress = result.formatted_address;

      // Filtering out the country name from address components
      const countryComponent = result.address_components.find(
        (component: any) => component.types.includes("country")
      );
      const country = countryComponent ? countryComponent.long_name : "";

      return { address:formattedAddress, country };
    } else {
      this.props.openToastHandler(this.props.t("customers.noDataFound"), "error");
      return null;
    }
  };
}
// Customizable Area End
