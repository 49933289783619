import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18n from 'i18next';
import { SwiperRef } from "swiper/react";

interface CustomI18nType {
  changeLanguage(language: string): void;
}

interface LanguageData {
  id: number;
  name: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  quoteNameShow: boolean;
  homeData: any;
  homeLoading: boolean;
  email: string;
  emailError: string;
  email2: string;
  emailError2: string;
  languageModal: boolean;
  language: string;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  homePageApiCallId: string = "";
  swiper: RefObject<SwiperRef> | null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      quoteNameShow: false,
      homeData: {},
      homeLoading: false,
      email: "",
      emailError: "",
      email2: "",
      emailError2: "",
      languageModal: false,
      language: "English",
      languageSelected: ""
    };
    this.swiper = React.createRef();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    this.handleQuoteNameShow();
    this.homePage();

    this.setState({ homeLoading: true });

    const selectedLanguage = await getStorageData("lang") || "en";
    if(selectedLanguage === "en"){
      setStorageData("lang", "en");
    }
    this.setState({ language: selectedLanguage === "ar" ? "عربي" : "English" });
    this.setState({ languageSelected: selectedLanguage });
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.homePageApiCallId) {
          if (!responseJson?.errors) {
            this.setState({ homeLoading: false });
            this.setState({ homeData: responseJson });
          } else {
            //Check Error Response
            this.setState({ homeLoading: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleClickOpenlanguageModal = () => {
    this.setState({ languageModal: !this.state.languageModal });
  }

  handleClickCloselanguageModal = () => {
    this.setState({ languageModal: false });
  }

  handleClickSelectlanguage = async (lng: LanguageData) => {
    setStorageData("lang", lng.value);
    const selectedLanguage = getStorageData("lang");
    const languageDirection = lng.value === "ar" ? "rtl" : "ltr"
  
    this.setState({
      language: await selectedLanguage === "ar" ? "عربي" : "English" || lng.name,
      languageModal: false,
      emailError: "",
      emailError2: "",
      languageSelected: await selectedLanguage
    }, () => {
      const htmlElement = window.document.querySelector("html")
      if (htmlElement) {
        htmlElement.setAttribute("dir", languageDirection)
      }
      (i18n as CustomI18nType).changeLanguage(lng.value)
      this.homePage();
      this.setState({ homeLoading: true });
    });
  }
  
  handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  }
  
  handleGetStartedForFree = (e: React.FormEvent<HTMLFormElement>) => {
    const { t } = this.props;
    e?.preventDefault();
    const regexEmail = /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
    if (!this.state.email) {
      this.setState({ emailError: t("landingPage.Home.emailConfirmation") });
    } else if (!regexEmail.test(this.state.email)) {
      this.setState({ emailError: t("landingPage.Home.invalidEmail") });
    } else {
      this.props.navigation.history.push("/Register", { data: this.state.email.trim() });
    }
  }

  handleChangeEmail2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email2: e.target.value });
  }
  
  handleGetStartedForFree2 = (e: React.FormEvent<HTMLFormElement>) => {
    const { t } = this.props;
    e?.preventDefault();
    const regexEmail = /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
    if (!this.state.email2) {
      this.setState({ emailError2: t("landingPage.Home.emailConfirmation") });
    } else if (!regexEmail.test(this.state.email2)) {
      this.setState({ emailError2: t("landingPage.Home.invalidEmail") });
    } else {
      this.props.navigation.history.push("/Register", { data: this.state.email2.trim() });
    }
  }
  
  handleQuoteNameShow = () => {
    if(window.innerWidth < 1280) {
      this.setState({ quoteNameShow: true });
    } else {
      this.setState({ quoteNameShow: false });
    }
  }

  handleNavigate = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleButtonLearnMore = () => {
    this.handleNavigate("WhyLamsa");
  }

  handleButtonBookADemo = () => {
    this.handleNavigate("ContactUs");
  }

  handleSliderPrevious = () => {
    this.swiper && this.swiper.current && this.swiper.current.swiper.slidePrev();
  }

  handleSliderNext = () => {
    this.swiper && this.swiper.current && this.swiper.current.swiper.slideNext();
  }

  homePage = async () => {
    const selectedLanguage = await getStorageData("lang") || "en";
    const headerType = {
      "Content-Type": configJSON.landingPageAPIContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.homePageApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.landingPageAPIEndPoint}?lang=${selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerType)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End