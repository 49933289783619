import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  activeCheck,
  inactiveCheck,
} from "./assets";
import { getAuthTokenDashboard } from "../../dashboard/src/utility.web";
import { getStorageData } from "../../../framework/src/Utilities";

interface PlanDetailsAttributes {
  id: number;
  title: string;
  description: string;
  offer: string;
  created_at: string;
  updated_at: string;
  logo: {
    id: number;
    url: string;
    name: string;
    content_type: string;
    size: number;
  };
}

interface PlanDetails {
  id: string;
  type: string;
  attributes: PlanDetailsAttributes;
}

export type PricingPlanMonthly = {
  id: string;
  activeIcon: string; // Assuming activeIcon and icon are both strings
  icon: string;
  title: string;
  planPrice: string;
  currency: string;
  body: string;
  planTerms: {
    id: number;
    plan_type: string;
    discription: string;
  }[];
  buttonBody: string;
  buttonText: string;
  choosen: boolean;
}

type TryUsApiResponse = {
  message: string;
  errors?: { token: string }[];
  error:string
}

export type CreateChargeResponse = {
  id: string;
  amount: number;
  currency: string;
  threeDSecure: boolean;
  card_threeDSecure: boolean;
  save_card: boolean;
  transaction: {
    url: string;
  };
  errors: {
    code: string;
    description: string;
    token:string
  }[];
}

export type GetChargeResponse = {
  id: string;
  amount: number;
  currency: string;
  status: string;
  card_threeDSecure: boolean;
  save_card: boolean;
  order: {
    order_id: null | string;
  };
  transaction: {
    authorization_id: null | string;
    timezone: string;
    created: string;
  };
  response: {
    code: string;
    message: string;
  };
  gateway: {
    response: {
      code: null | string;
      message: null | string;
    };
  };
  errors: {
    code: string;
    description: string;
    token:string
  }[];
}


const responseCodes = ["000","001", "100","200"];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  disabled: any;
  route: any;
  planModal: boolean;
  oldProps: any;
  activePlan: any;
  planType: string;
  location: { pathname: string, search: string } | undefined;
  openToastHandler: Function;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: string;
  activePlan: string;
  pricingPlanMonthlyData: PricingPlanMonthly[];
  pricingPlanYearlyData: PricingPlanMonthly[];
  pricingPlan: PlanDetails[];
  loading:boolean;
  redirect_url:string;
  planType:string,
  planModal:boolean,
  selectedPlan:PricingPlanMonthly | null;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PricingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pricingPlanPageMonthlyApiCallId: string = "";
  pricingPlanPageYearlyApiCallId: string = "";
  pricingPlanApiCallId: string = "";
  createChargeAPICallId: string = ""
  getChargeApiCallId: string = ""
  tryUsApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      activeTab: "Monthly",
      activePlan: "",
      pricingPlanMonthlyData: [],
      pricingPlanYearlyData: [],
      pricingPlan: [],
      loading:false,
      redirect_url:"",
      planModal:false,
      planType:"",
      selectedPlan:null,
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedLanguage = await getStorageData("lang") || "en";
    
    this.setState({ languageSelected: selectedLanguage })
    
    window.scrollTo(0, 0);
    const params = new URLSearchParams(window.location?.search);
    const tap_id = params.get('tap_id');
    this.checkTapId(tap_id)
    const redirect_url = this.props.navigation.history.location.pathname;
    this.setState({redirect_url},this.initializeCalls)
    // Customizable Area End
  }

  // Customizable Area Start
  initializeCalls = ()=>{
    this.pricingPlanMonthlyPage();
    this.pricingPlanYearlyPage()
    this.pricingPlan();//plan details
  }
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.pricingPlanPageMonthlyApiCallId) {
        if (!responseJson.errors) {
          const pricingData = responseJson.data;
          if (Array.isArray(pricingData)) {
            const pricingPlanMonthly = pricingData.map((item) => ({
              id: item.id,
              activeIcon: activeCheck,
              icon: inactiveCheck,
              title: item.attributes.plan_type.toLowerCase().replace(/_/g, " ").replace(/\b\w/g, (c:string) => c?.toUpperCase()),
              planPrice: item.attributes.plan_price,
              currency: item.attributes.currency,
              body: item.attributes.details,
              planTerms: item.attributes.plan_description,
              buttonBody: item.attributes.additional_feature,
              buttonText: item.attributes.button_text,
              choosen: false
            }));
            this.setState({ pricingPlanMonthlyData: pricingPlanMonthly });
          }
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.createChargeAPICallId) {
        this.createChargeResponse(responseJson)
      } else if (apiRequestCallId === this.getChargeApiCallId) {
        this.getChargeApiResponse(responseJson)
      } else if (apiRequestCallId === this.tryUsApiCallId) {
        this.tryUsApiResponse(responseJson)
      }

      this.pricingPlanYearly(message);
      this.handlePricingPlan(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  gotToDashboard = ()=>{
    this.props.navigation.navigate("Dashboard")
  }
  tryUsApiResponse = (responseJson: TryUsApiResponse) => {
    const { message } = responseJson
    this.setState({ loading: false })
    if (responseJson && !responseJson.errors && !responseJson.error) {
      this.props.openToastHandler(message, "success")
      setTimeout(this.gotToDashboard, 500)
    } else if (responseJson && responseJson.errors) {
      const { token } = responseJson.errors[0];
      this.props.openToastHandler(token || message, "error")

    } else if (responseJson.error) {
      this.props.openToastHandler(responseJson.error, "error")
    }
  };

  createChargeResponse = (responseJson: CreateChargeResponse ) => {
    this.setState({loading:false})
    if (responseJson && !responseJson.errors) {
      window.location.href = responseJson.transaction.url
    } else if (responseJson && responseJson.errors) {
      const { token, description } = responseJson.errors[0]
      this.props.openToastHandler(token || description, "error")
    }
  };

  getChargeApiResponse = (responseJson: GetChargeResponse) => {
    const { message } = responseJson.gateway.response
    this.setState({ loading: false })
    if (responseCodes.includes(responseJson.response.code)) {
      this.props.openToastHandler(message, "success")
      setTimeout(this.gotToDashboard, 500)
    } else if ((responseJson && responseJson.errors)) {
      const { token } = responseJson?.errors[0];
      this.props.openToastHandler(token || message, "error")

    } else if (!responseCodes.includes(responseJson.response.code)) {
      this.props.openToastHandler(message, "error")
    }
  };

  pricingPlanYearly = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.pricingPlanPageYearlyApiCallId) {
        if (!responseJson.errors) {
          const pricingData = responseJson.data;
            if (Array.isArray(pricingData)) {
              const pricingPlanYearly = pricingData.map((item) => ({
                id: item.id,
                icon: inactiveCheck,
                activeIcon: activeCheck,
                title: item.attributes.plan_type.toLowerCase().replace(/_/g, " ").replace(/\b\w/g, (c: string) => c?.toUpperCase()),
                currency: item.attributes.currency,
                planPrice: item.attributes.plan_price,
                body: item.attributes.details,
                planTerms: item.attributes.plan_description,
                buttonText: item.attributes.button_text,
                buttonBody: item.attributes.additional_feature,
                choosen: false
              }));
              this.setState({ pricingPlanYearlyData: pricingPlanYearly });
            }
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handlePricingPlan = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.pricingPlanApiCallId) {
        if (!responseJson.errors) {
          this.setState({ pricingPlan: responseJson.data });
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  
  handleClosePlanModal = () => {
    this.setState({ planModal: false });
  };

  handleClickProceedPlan = () => {
    this.setState({ planModal: false });
    if (this.state.activePlan === "0" && (this.props.route === "Pricing" || this.props.route === "FreeTrial")) {
      this.handleFreeTrial();
     
    } else {
     
      this.handleCreateCharge(this.state.selectedPlan);
    }
  };

  openProceedModal = (item: PricingPlanMonthly)=>{
    if (this.props.route === "Pricing") {
      this.setState({
        planModal: true,
        activePlan: item.planPrice,
        planType: item.title,
        selectedPlan:item
      })
    } else if (this.props.route === "FreeTrial") {
      if (item.planPrice !== "0") {
        this.props.oldProps?.setState({
          planModal: true,
          activePlan: this.state.activePlan,
          planType: item.title,
          selectedPlan:item
        });
      }else{
        this.setState({
          planModal: true,
          activePlan: item.planPrice,
          planType: item.title,
          selectedPlan:item
        })
      }
    }
  }

  handleButtonStartUsing = async (item: PricingPlanMonthly) => {
    const token = await getAuthTokenDashboard();
    this.redirectToLogin(token, item)
   
  }

  redirectToLogin = (token: string, item: PricingPlanMonthly) => {
    if (token) {
      this.openProceedModal(item)
    } else {
      this.props.navigation.history.push("/Login")
    }
  }

  handleClickMonthly = () => {
    this.setState({ activeTab: "Monthly" });
  }

  handleClickYearly = () => {
    this.setState({ activeTab: "Yearly" });
  }

  handleSelectPlan = (planPrice: string) => {
    this.setState({ activePlan: planPrice });
  }

  checkTapId = (tap_id: string | null)=>{
    if(tap_id){
      this.setState({loading:true})
      this.handleGetCharge(tap_id)
    }
  }

  //apis
  handleGetCharge= (tap_id: string) => {
    const header = {
      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChargeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChargeAPIEndPoint}?tap_id=${tap_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.getAPIMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleCreateCharge= (item:PricingPlanMonthly |null) => {
    this.setState({loading:true})
    
    const header = {
      "token": localStorage.getItem("authToken"),
    };
    let formdata = new FormData();

    const body = {
      redirect_url:this.state.redirect_url,
      token_id:"src_card",
      currency_code: item && item.currency,
      pricing_plan_id:item && item.id
    }
    formdata.append("redirect_url", body.redirect_url);
    formdata.append("token", body.token_id);
    formdata.append("currency_code", String(body.currency_code));
    formdata.append("pricing_plan_id", String(body.pricing_plan_id));


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createChargeAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createChargeAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  handleFreeTrial= () => {
    this.setState({loading:true})
    
    const header = {
      "token": localStorage.getItem(configJSON.authToken),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.tryUsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tryUsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const tryUs = new FormData()
    tryUs.append("pricing_plan_id", "22");

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      tryUs
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  pricingPlanMonthlyPage = async () => {
    const selectedLanguage = await getStorageData("lang") || "en";
    const headerType = {
      "Content-Type": configJSON.pricingPlanAPIContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pricingPlanPageMonthlyApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pricingPlanMonthlyAPIEndPoint}?lang=${selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.pricingPlanAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerType)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true;
  }

  pricingPlanYearlyPage = async () => {
    const selectedLanguage = await getStorageData("lang") || "en";
    const headerType = {
      "Content-Type": configJSON.pricingPlanAPIContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pricingPlanPageYearlyApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pricingPlanYearlyAPIEndPoint}&lang=${selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.pricingPlanAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerType)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true;
  }

  pricingPlan = async () => {
    const selectedLanguage = await getStorageData("lang") || "en";
    const headerType = {
      "Content-Type": configJSON.pricingPlanAPIContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pricingPlanApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pricingPlanAPIEndPoint}?lang=${selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.pricingPlanAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerType)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)

    return true;
  }

}
// Customizable Area End