import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface TermsConditionsData {
  id: string;
  type: string;
  attributes: {
    id?: string | number;
    title: string;
    body: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeId: number | string;
  termsConditionsData: TermsConditionsData[];
  termsLoading: boolean;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TermsAndConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  termsConditionsPageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      activeId: 1,
      termsConditionsData: [],
      termsLoading: false,
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);

    const selectedLanguage = await getStorageData("lang") || "en";
    this.setState({ languageSelected: selectedLanguage });
    
    this.termsConditionsPage();
    this.setState({ termsLoading: true });
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.termsConditionsPageApiCallId) {
          if (!responseJson.errors) {
            this.setState({ termsConditionsData: responseJson.data });
            this.setState({ termsLoading: false });
          } else {
            //Check Error Response
            this.setState({ termsLoading: false });
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectSection = (e: any, item: any) => {
    e.preventDefault();
    this.setState({ activeId: item?.attributes?.id });
    const element = document.getElementById(item?.attributes?.id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  termsConditionsPage = async () => {
    const selectedLanguage = await getStorageData("lang") || "en";
    const headerType = {
      "Content-Type": configJSON.termsConditionsAPIContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.termsConditionsPageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.termsConditionsAPIEndPoint}?lang=${selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.termsConditionsAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerType)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End