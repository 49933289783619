import React from "react";
import {
    Grid, Typography
    // Customizable Area Start
    , InputLabel,
    Popover,
    Box, IconButton,
    InputAdornment,
    TextField,
    Button
    // Customizable Area End
} from "@material-ui/core";
import {
    withStyles, createStyles, Theme
} from "@material-ui/core/styles";
// Customizable Area Start
import "react-big-calendar/lib/css/react-big-calendar.css";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import DateTimeSchedulingController from "./DateTimeSchedulingController.web";
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import TimePicker from "../../../components/src/TimePicker";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { calenderActive, calenderNonActive } from "./assets";
import CustomDatePicker from "../../../components/src/DatePicker";
import { CONSTANTS } from "../../../components/src/theme";
import CloseIcon from "@material-ui/icons/Close";
import { ContainedButton, CustomizedTextField } from "../../../components/src/CustomizableComponents.web";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import moment from "moment";
const selectedLanguage = localStorage.getItem("lang");
export const StyledTextField = withStyles((theme: Theme) => ({
    root: {
        padding: "0 0.2rem",
        "& .MuiInputBase-root": {
            height: "55px",
            borderRadius: "10px",
            color: "#707070",
            fontSize: "20px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "18px",
            }
        },
        "& .MuiOutlinedInput-input": {
            cursor: "pointer",
            padding: "15px 7px",
            color: CONSTANTS.color.midGrey,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: CONSTANTS.color.thickBlue,
            borderWidth: "2px",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "2rem",
            marginTop: "-4px",
        }
    }
}))(TextField);

export const StyledTextFieldDuration = withStyles((theme: Theme) => ({
    root: {
        padding: "0 0.2rem",
        "& .MuiInputBase-root": {
            height: "55px",
            borderRadius: "10px",
            color: "#707070",
            fontSize: "20px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "18px",
            }
        },
        "& .MuiOutlinedInput-input": {
            cursor: "pointer",
            padding: 0,
            color: CONSTANTS.color.midGrey,
            textAlign: "right",
            [theme.breakpoints.down("xs")]: {
                textAlign: "center",
            },
            [theme.breakpoints.up("md")]: {
                padding: selectedLanguage === 'ar' ? "0 12px 0 0" : "0 2px 0 0",
            },
            [theme.breakpoints.up("lg")]: {
                padding: selectedLanguage === 'ar' ? "0 14px 0 0" : "0 4px 0 0",
            },

        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: "10px",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: CONSTANTS.color.thickBlue,
            borderWidth: "2px",
        },
        "& .MuiInputAdornment-positionEnd": {
            margin: 0
        },
        "& .MuiIconButton-edgeEnd": {
            padding: "0 18px 0 0",
        },
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: 0
        },
        "& .MuiSvgIcon-root": {
            fontSize: "2rem",
            marginTop: "-4px",
        }
    }
}))(TextField);

export const StaticTextField = withStyles((theme: Theme) => ({
    root: {

        "& .MuiInputBase-root": {
            height: "40px",

            color: "#707070",
            fontSize: "16px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "14px",
            }
        },
        "& .MuiOutlinedInput-input": {
            cursor: "pointer",
            padding: "15px 7px",
            color: CONSTANTS.color.midGrey,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: CONSTANTS.color.thickBlue,
            borderWidth: "2px",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "2rem",
            marginTop: "-4px",
        }
    }
}))(TextField);

export const MonthlyTextField = withStyles((theme: Theme) => ({
    root: {

        "& .MuiInputBase-root": {
            height: "40px",
            color: "#707070",
            backgroundColor: "white",
            fontSize: "16px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "14px",
            }
        },
        "& .MuiIconButton-root": {
            padding: 0
        },
        "& .MuiOutlinedInput-input": {
            cursor: "pointer",
            padding: "15px 0 15px 7px",
            color: CONSTANTS.color.midGrey,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: CONSTANTS.color.thickBlue,
            borderWidth: "2px",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "2rem",
            marginTop: "-4px",
        }
    }
}))(TextField);


export const SimpleTextField = withStyles((theme: Theme) => ({
    root: {

        "& .MuiInputBase-root": {
            height: "40px",
            width: "73px",
            color: "#707070",
            fontSize: "20px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "18px",
            }
        },
        "& .MuiIconButton-root": {
            padding: 0,
            borderRadius: 0,
            borderLeft: "1px solid lightgray"
        },
        "& .MuiIconButton-label": {
            flexDirection: "column"
        },
        "& .MuiOutlinedInput-input": {
            cursor: "pointer",

            color: CONSTANTS.color.midGrey,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: CONSTANTS.color.thickBlue,
            borderWidth: "2px",
        },
        "& .MuiSvgIcon-root": {
            height: "20px",
            borderBottom: "1px solid lightgray"
        }

    }
}))(TextField);
const CancelButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.palette.text.primary}`,
            backgroundColor: theme.palette.common.white,
            width: "83px",
            height: "38px",
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "16px",
            borderRadius: 4,
            color: theme.palette.text.primary,
            textTransform: "capitalize",
            '&:hover': {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.text.primary,
            },

        }
    }))(Button)

const ContainedOkayButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: CONSTANTS.color.darkBlue,
            width: "83px",
            height: "38px",
            borderRadius: 4,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "16px",
            textTransform: "capitalize",
            '&:hover': {
                backgroundColor: CONSTANTS.color.darkBlue,
                color: theme.palette.common.white,
            },

        }
    }))(Button)
// Customizable Area End

export class DateTimeScheduling extends DateTimeSchedulingController {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getOptions = () => {
        const { frequency } = this.state.recurringJobData;

        switch (frequency) {
            case `${this.props.t('jobs.scheduling.frequency.daily')}`:
                return `${this.props.t('jobs.scheduling.frequency1.day')}`;
            case `${this.props.t('jobs.scheduling.frequency.weekly')}`:
                return `${this.props.t('jobs.scheduling.frequency1.week')}`;
            case `${this.props.t('jobs.scheduling.frequency.monthly')}`:
                return `${this.props.t('jobs.scheduling.frequency1.month')}`;
            case `${this.props.t('jobs.scheduling.frequency.yearly')}`:
                return `${this.props.t('jobs.scheduling.frequency1.year')}`;
            default:
                return "";
        }
    };

    recurringJobContent = () => {
        const { classes, t } = this.props
        return <>
            {this.state.isActiveOneOffJob && <Grid item xs={12} sm={6} md={6} lg={6} >
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="my-input" className={classes.inputLabel}>{t('jobs.label.endDate')}</InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomDatePicker
                            minDate={this.state.oneOffJob.jobStartDate}
                            disabled={!this.props.editStatus}
                            selectedDate={this.state.oneOffJob?.jobEndDate || this.props.oneOffJobDetails?.jobEndDate}
                            dateChange={this.handleEndDate}
                        />
                        <ErrorMessage message={(this.state.oneOffJob?.jobEndDate || this.props.oneOffJobDetails?.jobEndDate < new Date()) ? this.props.isErrorFormData?.jobEndDate : ''} />
                    </Grid>

                </Grid>

            </Grid>}
        </>
    }
    oneOffJobContent = () => {
        const { classes, t } = this.props
        const frequency = this.state.recurringJobData?.duration?.frequency ?? "";
        const frequencyChange = frequency.includes(".") 
            ? frequency.split(".").pop() ?? "" 
            : frequency;
        const day = this.state.recurringJobData?.duration?.day.includes(".")
        ? this.state.recurringJobData?.duration?.day.split(".")[0]
        : this.state.recurringJobData?.duration?.day; // Use the value as is
        return (
            <>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={this.state.isActiveOneOffJob ? 6 : 7} md={this.state.isActiveOneOffJob ? 6 : 4} lg={6} >
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="my-input" className={classes.inputLabel}>{t('jobs.tableRow.startDate')}</InputLabel>
                            </Grid>
                            <Grid item xs={12}>

                                <CustomDatePicker

                                    disabled={!this.props.editStatus}
                                    selectedDate={this.state.oneOffJob?.jobStartDate || this.props.oneOffJobDetails?.jobStartDate}
                                    dateChange={this.handleStartDate}
                                />
                                    <ErrorMessage message={(this.state.oneOffJob?.jobStartDate || this.props.oneOffJobDetails?.jobStartDate < new Date()) ? this.props.isErrorFormData?.jobStartDate : ''} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.recurringJobContent()}

                    <Grid item xs={12} sm={6} md={this.state.isActiveOneOffJob ? 6 : 4} lg={this.state.isActiveOneOffJob ? 6 : 3}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="my-input" className={classes.inputLabel}>{t('jobs.tableRow.startTime')}</InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                                {this.props.editStatus ?
                                    <TimePicker
                                        data-test-id="start_time"
                                        time={this.state.oneOffJob?.jobStartTime}
                                        onChange={(timeValue: any) => this.handleJobChangeHours(timeValue)}
                                        timeFormat={this.state.timeFormat}
                                        t={t}
                                        languageSelected={this.props.languageSelected}
                                    />
                                    :
                                    <CustomizedTextField
                                        fullWidth
                                        variant="outlined"

                                        disabled
                                        value={this.convertToArabicTime(this.state.oneOffJob.jobStartTime)}
                                    />
                                }
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6} md={this.state.isActiveOneOffJob ? 6 : 4} lg={this.state.isActiveOneOffJob ? 6 : 3}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="my-input" className={classes.inputLabel}>{t('jobs.tableRow.endTime')}</InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                                {this.props.editStatus ?
                                    <TimePicker
                                        data-test-id="end_time"
                                        time={this.state.oneOffJob?.jobEndTime}
                                        onChange={(timeValue: any) => this.handleJobChangeEndTime(timeValue)}
                                        timeFormat={this.state.timeFormat}
                                        t={t}
                                        languageSelected={this.props.languageSelected}
                                    />
                                    :
                                    <CustomizedTextField
                                        fullWidth
                                        variant="outlined"
                                        value={this.convertToArabicTime(this.state.oneOffJob?.jobEndTime)}

                                        disabled
                                    />
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                    {!this.state.isActiveOneOffJob &&
                        <>
                            {/* freq start*/}
                            <Grid item xs={12} sm={6} md={6} lg={6} data-test-id="freqModal" onClick={this.openFreqModal.bind(this, "FREQUENCY")}>
                                <Typography className={classes.formText} variant="body1" >
                                    {t('jobs.label.frequency')}
                                </Typography>
                                <Box>
                                    <StyledTextField
                                        fullWidth
                                        variant="outlined"
                                        value={this.state.recurringJobData.frequency}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" aria-label="your-icon">
                                                        <ArrowDropDownIcon /> {/* Your icon component */}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                </Box>
                            </Grid>
                            {/* freq end */}
                            {/* dura start*/}
                            <Grid item xs={12} sm={6} md={6} lg={6} >
                                <Typography className={classes.formText} variant="body1" >
                                    {t('jobs.label.duration')}
                                </Typography>
                                <Grid xs={12} sm={12} md={12} lg={12} style={{ display: "flex" }}>
                                    <Grid item lg={3} md={3} sm={4} xs={4} data-test-id="openDurationModal" onClick={this.openDurationModal} >
                                        <StyledTextFieldDuration
                                            variant="outlined"
                                            value={day}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" aria-label="your-icon">
                                                            <ArrowDropDownIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),

                                            }}

                                        />
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={8} xs={8} data-test-id="durationModal" onClick={this.openFreqModal.bind(this, "DURATION")}>
                                        <StyledTextField
                                            fullWidth
                                            variant="outlined"
                                            value={frequencyChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" aria-label="your-icon">
                                                            <ArrowDropDownIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* dura end*/}
                        </>
                    }
                </Grid>
            </>
        )
    }
    editOneOffJob = () => {
        const { classes, t } = this.props
        return <div className={classes.boxStyle}>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1"
                                className={classes.detailsHeading}>
                                {t('jobs.tableRow.startDate')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={`${classes.detailsText}`}>
                                {moment(this.state.oneOffJob.jobStartDate || this.props.oneOffJobDetails.jobStartDate).format("MMM DD,yyyy").toString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1"
                                className={classes.detailsHeading}>
                                {t('jobs.label.endDate')}
                            </Typography>
                            <Typography variant="body1" className={classes.detailsText}>
                                {moment(this.state.oneOffJob.jobEndDate || this.props.oneOffJobDetails.jobEndDate).format("MMM DD,yyyy").toString()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1"
                                className={classes.detailsHeading}>
                                {t('jobs.tableRow.startTime')}
                            </Typography>
                            <Typography variant="body1"
                                className={classes.detailsText}>
                                {this.state.oneOffJob.jobStartTime}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1"className={classes.detailsHeading}>
                                {t('jobs.tableRow.endTime')}
                            </Typography>
                            <Typography variant="body1" className={classes.detailsText}>
                                {this.state.oneOffJob.jobEndTime}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    }
    editJobContent = () => {
        const { classes, t } = this.props
        const day = this.state.recurringJobData?.duration?.day.includes(".")
            ? this.state.recurringJobData?.duration?.day.split(".")[0]
            : this.state.recurringJobData?.duration?.day;
        const frequency = this.state.recurringJobData?.duration?.frequency.includes(".")
            ? this.state.recurringJobData?.duration?.frequency.split(".")[0]
            : this.state.recurringJobData?.duration?.frequency;
        return <>{this.state.isActiveOneOffJob ? this.editOneOffJob() : <div className={classes.boxStyle}>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1"
                                className={classes.detailsHeading}
                            >
                                {t('jobs.tableRow.startDate')}
                            </Typography>
                            <Typography variant="body1"
                                className={`${classes.detailsText}`}
                            >
                                {moment(this.state.oneOffJob.jobStartDate || this.props.oneOffJobDetails.jobStartDate).format("MMM DD,yyyy").toString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1"
                                className={classes.detailsHeading}>
                                {t('jobs.tableRow.startTime')}
                            </Typography>
                            <Typography variant="body1"
                                className={classes.detailsText}>{this.state.oneOffJob.jobStartTime}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1"
                                className={classes.detailsHeading}>
                                {t('jobs.tableRow.endTime')}
                            </Typography>
                            <Typography variant="body1"
                                className={classes.detailsText}>
                                {this.state.oneOffJob.jobEndTime}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1"
                                className={classes.detailsHeading}
                            >{t('jobs.label.frequency')} </Typography>
                            <Typography variant="body1"
                                className={classes.detailsText}>
                                {this.state.recurringJobData.frequency || '-'} 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="body1"
                                className={classes.detailsHeading}>
                                 {t('jobs.label.duration')}
                            </Typography>
                            <Typography variant="body1"
                                className={classes.detailsText}>
                                {day}{" "}{frequency} 
                            </Typography>
                        </Grid>
                        {
                            this.props.jobType === "recurring_job" ? "" :
                            <Grid item xs={12} sm={4} data-testid="endDateSection">
                                <Typography
                                    variant="body1"
                                    className={classes.detailsHeading}>
                                    {t('jobs.label.endDate')}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className={classes.detailsText}
                                >
                                    {moment(this.state.oneOffJob.jobEndDate || this.props.oneOffJobDetails.jobEndDate).format("MMM DD,yyyy").toString()}
                                </Typography>
                            </Grid>
                        } 
                    </Grid>
                </Grid>
            </Grid>
        </div>}
        </>
    }
    renderDailyStyle = (current:boolean) => {
        let style = {
            textAlign: "center" as const,
            color: "black",
            backgroundColor: "white",
            border: "1px solid lightgray"
        }
        return this.state.recurringJobData.frequency === `${this.props.t('jobs.scheduling.frequency.daily')}` ?
            { ...style, backgroundColor:current?'#1c18af': "#d8d8d8",color:'white' } : style
    }
    renderPopovers = (classes: ClassNameMap<string>, t: (key: string) => string, mockFrequency: string[], mockFrequencyDuration:string[], mockDuration: string[], mockWeekDays: string[], monthlyData: string[]) => {
        return (
            <>
                <Popover
                    id={this.state.freqAnchorId}
                    open={Boolean(this.state.freqAnchorEl)}
                    anchorEl={this.state.freqAnchorEl}
                    data-test-id="more-action-close"
                    onClose={this.closeFreqModal}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                <Grid container direction="column" className={classes.freqPopoverContainer}>
                {this.state.isModal === "FREQUENCY"
                    ? mockFrequency.map((item: string) => (
                        <Grid key={item} item className={classes.freqPopoverMapped}>
                        <Typography
                            data-test-id="setFrequency"
                            onClick={this.setFrequency.bind(this, item)}
                            style={{
                            color: "#4c4c4c",
                            cursor: "pointer",
                            fontWeight: 500,
                            fontSize: "20px",
                            }}
                        >
                            {item}
                        </Typography>
                        </Grid>
                    ))
                    : mockFrequencyDuration.map((item: string) => (
                        <Grid key={item} item className={classes.freqPopoverMapped}>
                        <Typography
                            data-test-id="setDuration"
                            onClick={this.setFrequency.bind(this, item)}
                            style={{
                            color: "#4c4c4c",
                            cursor: "pointer",
                            fontWeight: 500,
                            fontSize: "20px",
                            }}
                        >
                            {item}
                        </Typography>
                        </Grid>
                    ))}
                </Grid>
                </Popover>
                <Popover
                    id={this.state.durationAnchorId}
                    open={Boolean(this.state.durationAnchorEl)}
                    anchorEl={this.state.durationAnchorEl}
                    data-test-id="duration-close"
                    onClose={this.closeDurationModal}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Grid container direction="column" className={classes.durationPopoverContainer}>
                        {mockDuration.map((item: string) => {
                            return (
                                <Grid key={item} item className={classes.durationPopoverMapped}>
                                    <Typography data-test-id="setDuration" onClick={this.setDurationDay.bind(this, item)}
                                        style={{ color: "#4c4c4c", textAlign: "center", cursor: "pointer", fontWeight: 500, fontSize: "20px" }}>{item}</Typography>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Popover>
                <Popover
                    id={this.state.secondAnchorId}
                    open={Boolean(this.state.secondAnchorEl)}
                    
                    onClose={this.closeSecondModal.bind(this, "") }

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Grid container className={classes.secondPopoverContainer}>
                        <Grid item xs={11} className={classes.secondPopoverMapped}>
                            <Grid item >
                                <Box>
                                    <Typography variant='body1'> {t('jobs.label.frequency')}</Typography>

                                </Box>
                            </Grid>
                            <Grid item onClick={this.openFreqModal.bind(this, "FREQUENCY")}>
                                <StaticTextField
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.recurringJobData.frequency}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" aria-label="your-icon">
                                                    <ArrowDropDownIcon /> {/* Your icon component */}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Box display={"flex"} flexDirection={"row"} gridGap={"0.4rem"} alignItems={"center"}>
                                    <Typography variant='body2'>{t('jobs.scheduling.every')}</Typography>
                                    <Box>
                                        <SimpleTextField

                                            fullWidth
                                            variant="outlined"
                                            value={this.state.recurringJobData.day}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" style={{ display: "flex", flexDirection: "column" }} aria-label="your-icon">
                                                            <ExpandLessOutlinedIcon data-test-id="incrementDay" onClick={this.incrementDay} />
                                                            <ExpandMoreOutlinedIcon data-test-id="deccrementDay" onClick={this.decrementDay} />

                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </Box>
                                    <Typography variant='body2'>{this.getOptions()}(s) {t('jobs.scheduling.on')}</Typography>


                                </Box>
                            </Grid>
                            <Grid item style={{
                                ...webStyle.secondPopoverChild,
                                outline: this.state.recurringJobData.frequency ===
                                    `${this.props.t('jobs.scheduling.frequency.yearly')}` ? 0 : "1px solid lightgray"
                            }}>
                                {this.state.recurringJobData.frequency !== `${this.props.t('jobs.scheduling.frequency.yearly')}` &&
                                     this.state.recurringJobData.frequency !== `${this.props.t('jobs.scheduling.frequency.monthly')}` &&
                                         mockWeekDays.map((item: string, index: number) => {
                                    let style = {
                                        textAlign: "center" as const,
                                        color: "white",
                                        backgroundColor: "#d8d8d8",
                                        border: "1px solid lightgray"
                                    };
                                    return (
                                        <Box key={item} width={"32px"} data-test-id={`dayFreModal_${index}`}
                                            onClick={()=>{this.state.recurringJobData.frequency === "Weekly" && this.handleSelectDay(item,index)}}
                                         >
                                            {this.state.recurringJobData.frequency === "Weekly" && 
                                        <Typography  
                                            style={this.state.recurringJobData.frequency === `${this.props.t('jobs.scheduling.frequency.weekly')}` &&
                                                this.state.recurringJobData.weekDays
                                                .split(", ")
                                                .map(Number) 
                                                .includes(index)
                                                ?
                                                { ...style, backgroundColor: "#1c18af" } :
                                                this.renderDailyStyle(this.state.selectedDayForRequiringJobIndex == index)
                                                } >{item}</Typography>}
                                        </Box>        
                                    )
                                })}
                                {this.state.recurringJobData.frequency === `${this.props.t('jobs.scheduling.frequency.monthly')}` &&

                                    <MonthlyTextField
                                        data-test-id="monthlyModal"
                                        onClick={this.openMonthlyModal}
                                        fullWidth
                                        variant="outlined"
                                        value={this.state.recurringJobData.monthlyDay.length > 21 ? this.state.recurringJobData.monthlyDay.slice(0, 21) + "..." : this.state.recurringJobData.monthlyDay}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" style={{ display: "flex", flexDirection: "column" }} aria-label="your-icon">
                                                        <ArrowDropDownIcon /> {/* Your icon component */}

                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }

                            </Grid>
                            {this.state.recurringJobData.frequency === "Daily" ? "" : <hr />}
                            <Grid item>
                                <Typography variant='body2'>{t('jobs.scheduling.summary')} : {this.getSummary()}</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: "auto" }}>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <CancelButton
                                        data-test-id="cancelButton"
                                        variant="outlined"
                                        onClick={this.closeSecondModal.bind(this, "")}
                                    >
                                        <Typography variant='body2' >{t('invoice.button.cancel')}</Typography>
                                    </CancelButton>
                                    <ContainedOkayButton
                                        data-test-id="okayButton"
                                        className={classes.mt_1}
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.closeSecondModal.bind(this, "OKAY")}
                                    >
                                        <Typography variant='body2'>{t('jobs.scheduling.button.okay')}</Typography>
                                    </ContainedOkayButton>
                                </Box>


                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <CloseIcon className={classes.CloseIcon} onClick={this.closeSecondModal.bind(this, "")} />
                        </Grid>

                    </Grid>
                </Popover>
                <Popover
                    id={this.state.monthlyAnchorId}
                    open={Boolean(this.state.monthlyAnchorEl)}
                    anchorEl={this.state.monthlyAnchorEl}
                    data-test-id="more-action-close"
                    onClose={this.closeMonthlyModal}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Grid container direction="column" className={classes.monthlyPopoverContainer}>
                        {monthlyData.map((item: string, index: number) => {
                            return (
                                <Typography style={{
                                    ...webStyle.monthlyPopoverMapped,
                                    borderBottom: index === monthlyData.length - 1 ? "none" : "1px solid"
                                }} onClick={this.setMonthlyDay.bind(this, item)} key={item} variant="caption">{item}</Typography>
                            )
                        })}
                    </Grid>

                </Popover>
            </>
        )
    }
    jobTypeBtnStyle = () => {
        const { classes, languageSelected } = this.props
        if (languageSelected === 'en') {
            return this.state.isActiveOneOffJob ?
                `${classes.primaryButton} ${classes.borderRadiusLeft}` :
                `${classes.secondaryButton} ${classes.borderRadiusLeft}`
        } else {
            return this.state.isActiveOneOffJob ?
                `${classes.primaryButton} ${classes.borderRadiusRight}` :
                `${classes.secondaryButton} ${classes.borderRadiusRight}`
        }
    }
    jobStyleBtnStyle1 = () =>{
        const { classes, languageSelected } = this.props
        if (languageSelected === 'en') {
            return !this.state.isActiveOneOffJob ?
                `${classes.primaryButton} ${classes.borderRadiusRight}` :
                `${classes.secondaryButton} ${classes.borderRadiusRight}`
        } else {
            return !this.state.isActiveOneOffJob ?
                `${classes.primaryButton} ${classes.borderRadiusLeft}` :
                `${classes.secondaryButton} ${classes.borderRadiusLeft}`
        }
    }
    // Customizable Area End

    render() {
        const { classes, t } = this.props
        const mockFrequency = [
            `${t('jobs.scheduling.frequency.daily')}`,
            `${t('jobs.scheduling.frequency.weekly')}`,
            `${t('jobs.scheduling.frequency.monthly')}`,
            `${t('jobs.scheduling.frequency.yearly')}`
        ]
        const mockFrequencyDuration = [
            `${t('jobs.scheduling.frequency1.days')}`,
            `${t('jobs.scheduling.frequency1.weeks')}`,
            `${t('jobs.scheduling.frequency1.months')}`,
            `${t('jobs.scheduling.frequency1.years')}`
        ]
        const mockDuration = [
            `${t('jobs.scheduling.duration.one')}`,
            `${t('jobs.scheduling.duration.two')}`,
            `${t('jobs.scheduling.duration.three')}`,
            `${t('jobs.scheduling.duration.four')}`,
            `${t('jobs.scheduling.duration.five')}`,
            `${t('jobs.scheduling.duration.six')}`,
            `${t('jobs.scheduling.duration.seven')}`,
            `${t('jobs.scheduling.duration.eight')}`,
            `${t('jobs.scheduling.duration.nine')}`,
            `${t('jobs.scheduling.duration.ten')}`,
        ];
        const mockWeekDays = [
            `${t('jobs.scheduling.mockWeekDays.s')}`,
            `${t('jobs.scheduling.mockWeekDays.m')}`,
            `${t('jobs.scheduling.mockWeekDays.t')}`,
            `${t('jobs.scheduling.mockWeekDays.w')}`,
            `${t('jobs.scheduling.mockWeekDays.t')}`,
            `${t('jobs.scheduling.mockWeekDays.f')}`,
            `${t('jobs.scheduling.mockWeekDays.s')}`
        ];
        let dateinstring
        if (this.props.oneOffJobDetails?.jobStartDate) {
            dateinstring = new Date(this.props.oneOffJobDetails?.jobStartDate)
          } 
        else if (this.state.oneOffJob?.jobStartDate) {
            dateinstring = new Date(this.state.oneOffJob?.jobStartDate)
        } 
        else {
        dateinstring = new Date();
        }
        const gettheDate =  dateinstring.getDate()
        const dayOfWeek = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(dateinstring);
        const weekOfMonth = this.getWeekOfMonth(dateinstring);
        const ordinalString = this.numberToOrdinalString(weekOfMonth);
        const monthlyData = [
            `Monthly on day ${gettheDate}`,
            `Monthly on ${ordinalString} ${dayOfWeek}`,
        ]
        return (
            // Customizable Area Start
            <>
                <div className={classes.requestUserDetails}>
                    {/* Top toggle buttons */}
                    {this.renderPopovers(classes, t, mockFrequency, mockFrequencyDuration, mockDuration, mockWeekDays, monthlyData)}
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={classes.titleHeading26}>{t('jobs.tableRow.scheduling')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="flex-start">
                                <Grid item xs={6}>
                                    <ContainedButton
                                        fullWidth
                                        variant="contained"
                                        data-test-id='oneOffJobBtn'
                                        className={this.jobTypeBtnStyle()}
                                        onClick={this.handleActiveOneOff}
                                    >
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={1}>
                                                <img src={this.state.isActiveOneOffJob ? calenderActive : calenderNonActive} width={19} height={20} />
                                            </Grid>
                                            <Grid xs={11}>
                                                <Grid container direction="row" className={classes.marginLeftRight}>
                                                    <Grid item xs={12} style={{ display: "flex" }}>
                                                        <Typography variant="body1" className={this.state.isActiveOneOffJob ? classes.mainHeader : classes.notActiveMainHeader}>{t('jobs.scheduling.oneOffJobHeading')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} container >
                                                        <Typography variant="caption" className={this.state.isActiveOneOffJob ? classes.subHeader : classes.notActiveSubHeader}>{t('jobs.scheduling.oneOffSubHeading')}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ContainedButton>
                                </Grid>
                                <Grid item xs={6} className={classes.recurringSpacing}>
                                    <ContainedButton
                                        fullWidth
                                        variant="contained"
                                        data-test-id='recurringJobBtn'
                                        className={this.jobStyleBtnStyle1()}
                                        onClick={this.handleActiveReoccuring}
                                    >
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={1}>
                                                <img src={!this.state.isActiveOneOffJob ? calenderActive : calenderNonActive} width={19} height={20} />

                                            </Grid>
                                            <Grid xs={11}>
                                                <Grid container direction="row" className={classes.marginLeftRight}>
                                                    <Grid item xs={12} style={{ display: "flex" }}>
                                                        <Typography variant="body1" className={!this.state.isActiveOneOffJob ? classes.mainHeader : classes.notActiveMainHeader}>{t('jobs.scheduling.recurringJobHeading')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} container>
                                                        <Typography variant="caption" className={!this.state.isActiveOneOffJob ? classes.subHeader : classes.notActiveSubHeader}>{t('jobs.scheduling.recurringJobSubHeading')}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ContainedButton>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>


                    {/* One off job */}

                    <>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                        {this.props.editStatus === true ?
                            this.oneOffJobContent() :
                            this.editJobContent()
                        }
                        </Grid>
                    </>

                </div>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const DateTimeSchedulingStyles = (theme: Theme) =>
    createStyles({
        freqPopoverContainer: {
            width: "274px",
            borderRadius: "5px",
            backgroundColor: CONSTANTS.color.white,
            gap: "6px",
        },
        freqPopoverMapped: {
            padding: "0.5rem 0.5rem 0.5rem 1rem",
            borderBottom: "1px solid rgba(0,0,0,0.1)"
        },
        marginLeftRight: {
            marginLeft: "0.35rem",
            marginRight: "1.35rem"
        },
        durationPopoverContainer: {
            width: "63px",
            borderRadius: "5px",
            backgroundColor: CONSTANTS.color.white,
            height: "406px",
            flexDirection: "row"
        },
        durationPopoverMapped: {
            padding: "0.3rem 1rem",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            width: "100%"
        },
        detailsHeading: {
            color: CONSTANTS.color.textLightGrey,
            fontWeight: theme.typography.fontWeightRegular
        },
        detailsText: {
            color: CONSTANTS.color.silverGrey,
            fontWeight: theme.typography.fontWeightMedium
        },
        secondPopoverContainer: {
            width: "274px",
            borderRadius: "5px",
            backgroundColor: CONSTANTS.color.white,
            height: "333px",
            flexDirection: "row",
            padding: "1rem",
        },        
        secondPopoverMapped: {
            display: "flex", flexDirection: "column",
            gap: "0.7rem",
        },
        monthlyPopoverContainer: {
            width: "203px",
            border: "1px solid lightgray",
            backgroundColor: CONSTANTS.color.white,
        },

        formText: {
            [theme.breakpoints.down("xs")]: {
                fontSize: "18px !important",

            },
            fontWeight: CONSTANTS.fontWeight.fontWeight400,
            lineHeight: "16px",
            color: "#707070",
            paddingBottom: "0.5rem"
        },
        grayText: {
            color: 'darkgray'
        },
        minW30: {
            minWidth: '30%'
        },
        blackText: {
            color: 'black !important'
        },
        justifyCenter: {
            justifyContent: 'center'
        },
        justifyEnd: {
            justifyContent: 'end'
        },
        requestUserDetails: {
            marginTop: '15px',
        },
        boxStyle: {
            border: "1px solid rgba(0,0,0,0.1)",
            width: "100%",
            height: "auto !important",
            padding: "1rem 1rem",
            borderRadius: "8px",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "auto !important",
                maxWidth:"none"
            },
        },
        primaryButton: {
            width: "100%",
            maxWidth: "520px",
            height: "62px",
            borderRadius: 8,
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            }
        },
        secondaryButton: {
            width: "100%",
            maxWidth: "520px",
            backgroundColor: CONSTANTS.color.greySilver,
            height: "62px",
            borderRadius: "8px",
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            }
        },
        borderRadiusRight:{
            borderRadius: "8px",
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                borderRadius: '0px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
            }
        },
        borderRadiusLeft:{
            borderRadius: "8px",
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                borderRadius: '0px',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
            }
        },
        mainHeader: {
            textAlign: 'left',
            color: theme.palette.common.white,
            marginLeft: 5,
            fontWeight: theme.typography.fontWeightMedium,
            [theme.breakpoints.down('xs')]: {
                fontSize: 15
            }
        },
        subHeader: {
            textAlign: 'left',
            color: theme.palette.common.white,
            marginLeft: 5,
            marginTop:"-6px",
            fontWeight: theme.typography.fontWeightRegular
        },
        notActiveMainHeader: {
            textAlign: 'left',
            color: CONSTANTS.color.silverWhite,
            marginLeft: 5,
            [theme.breakpoints.down('xs')]: {
                fontSize: 15
            }
        },
        notActiveSubHeader: {
            marginLeft: 6,
            textTransform: 'none',
            textAlign: 'left',
            color: CONSTANTS.color.silverWhite,
            fontWeight: 400,
            marginTop:"-6px"
        },
        closeIcon: {
            cursor: "pointer"
        },
        recurringSpacing:{
            padding:"0 0 0 10px",
            [theme.breakpoints.down('xs')]: {
                padding:"0px",
            }
        },
    });
const webStyle = {
    secondPopoverChild: {
        display: "flex", flexDirection: "row" as const,
        borderRadius: "2px",
    },

    monthlyPopoverMapped: {
        color: "#626262",
        cursor: "pointer",
        padding: "0.5rem",
    },
    containerWidth:{
        width:"72%"
    }
}

const SchedulingStyles = withStyles(DateTimeSchedulingStyles)(DateTimeScheduling);
export default withCommonStyles(SchedulingStyles)
// Customizable Area End
