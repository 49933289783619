Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings Body";

exports.btnExampleTitle = "CLICK ME";

exports.account_id = "account_id";
exports.authToken = "authToken";
exports.settings_id = "settings_id";
exports.product_id = "product_id";
exports.service_id = "service_id";

// Company Information
exports.settingsContentType = "application/json";

exports.notificationSettingAPIEndPoint = "bx_block_settings/notification_settings";
exports.companyInformationAPIEndPoint = "bx_block_settings/company_informations";
exports.getPlanAPIEndPoint = 'bx_block_subscriptions/subscriptions/get_plans'
exports.updatePlanAPIEndPoint = 'bx_block_subscriptions/subscriptions'
exports.getSubscriptionByIdAPIEndPoint = 'bx_block_subscriptions/subscriptions'
exports.getFeatures = 'bx_block_dashboard/lamsa_features'
exports.createChargeAPIEndPoint = 'bx_block_amazonpayintegration/create_charge'
exports.getCardAPIEndPoint = 'bx_block_amazonpayintegration/get_cards'
exports.addCardAPIEndPoint = 'bx_block_amazonpayintegration/add_card'
exports.getCurrency='bx_block_languagesupport/languagesupports/currency_symbol'
exports.deleteCardAPIEndPoint = 'bx_block_amazonpayintegration/delete_card'
exports.setDefaultCardAPIEndPoint = 'bx_block_amazonpayintegration/set_default'
exports.createCompanyInformationAPIMethod = "POST";
exports.getCompanyInformationAPIMethod = "GET";
exports.updateCompanyInformationAPIMethod = "PUT";
exports.countriesAPIEndPoint = "bx_block_settings/region_selection";
exports.getCountiresAPIMethod = "GET";

// Products and Services
exports.productsAPIEndPoint = "bx_block_settings/products";
exports.createAPIMethod = "POST";
exports.getAPIMethod = "GET";
exports.putAPIMethod = "PUT";
exports.deleteAPIMethod = "DELETE";
exports.patchAPIMethod = "PATCH";
exports.servicesAPIEndPoint = "bx_block_settings/services";
exports.getCurrency='bx_block_languagesupport/languagesupports/currency_symbol'
exports.getInvoiceData='bx_block_amazonpayintegration/invoice_infos'
//tap payments
exports.createChargeApiEndPoint = "bx_block_amazonpayintegration/create_charge"
exports.getChargeAPIEndPoint="bx_block_amazonpayintegration/get_charge"
exports.getChargeSliderAPIEndPoint = "bx_block_subscriptions/subscriptions/get_charge_for_slider"
exports.createChargeForSliderAPIEndPoint = "bx_block_subscriptions/subscriptions/create_charge_for_slider"

// Labels - Company Information
exports.companySettingsLabel = "Company Settings";
exports.productAndServicesLabel = "Product & Services";
exports.manageYourPlanLabel = "Manage Your Plan";
exports.billingLabel = "Billing";
exports.addPaymentLabel = "Add Payment";
exports.companyDetailsLabel = "Company Details";
exports.companyLogoLabel = "Company Logo";
exports.hexColorLabel = "Company Primary Color (Please select the Hex code of the color)";
exports.uploadFilesLabel = "Upload Files";
exports.changeLogoImageLabel = "Change Logo Image";
exports.removeImageLabel = "Remove Image";
exports.companyNameLabel = "Company Name";
exports.phoneNumberLabel = "Phone Number";
exports.emailAddressLabel = "Email Address";
exports.websiteUrl = "Website URL";
exports.regionalSettingsLabel = "Regional Settings";
exports.countryLabel = "Country";
exports.currencyLabel = "Currency";
exports.selectCountryLabel = "Select Country";
exports.selectCurrencyLabel = "Select Currency";
exports.timezoneLabel = "Timezone";
exports.selectTimezoneLabel = "Select Timezone";
exports.dateFormatLabel = "Date Format";
exports.selectDateFormatLabel = "Select Date Format";
exports.timeFormatLabel = "Time Format";
exports.selectTimeFormatLabel = "Select Time Format";
exports.firstDayOfTheWeekLabel = "First Day Of The Week";
exports.selectFirstDayOfTheWeekLabel = "Select First Day Of The Week";
exports.buisnessHoursLabel = "Business Hours";
exports.closedLabel = "Closed";
exports.opensAtLabel = "Opens At:";
exports.closesAtLabel = "Closes At:";
exports.taxSettingsLabel = "Tax Settings";
exports.taxIdNameLabel = "Tax ID Name";
exports.taxIdNumberLabel = "Tax ID Number";
exports.manageYourTaxRatesLabel = "Manage your tax rates";
exports.manageTaxRatesDescLabel = "Manage one or multiple tax rates to apply in quotes & invoices.";
exports.manageTaxRatesLabel = "Manage Tax Rates";
exports.taxNameLabel = "Tax Name";
exports.taxRateLabel = "Tax Rate (%)";
exports.taxDescriptionLabel = "Tax Description";
exports.manageTaxRatesPlusLabel = "+ Manage Tax Rates";
exports.cancelLabel = "Cancel";
exports.saveLabel = "Save";
exports.updateLabel = "Update";
exports.updateSettingsLabel = "Update Settings";
exports.notificationsLabel = "Notifications";
exports.notificationLabel = "Notification";
exports.hoverLabel = "Add the number of days to follow up, the range should be between 1- 14 days prior.";

// Labels - Products And Services
exports.productsAndServicesLabel = "Product & Services";
exports.productsAndServicesHeading = "Add your products to manage your inventory";
exports.productsAndServicesDesc = "Easily add and update your products to keep your management organized";
exports.productLabel = "Product";
exports.productsLabel = "Products";
exports.createAProductLabel = "Create A Product";
exports.deleteProductLabel = "Delete Product?";
exports.deleteProductDescription = "Are you sure you want to delete this Product?";
exports.servicesHeading = "Make your life easier managing your service";
exports.servicesDesc = "Easily add and update your services to keep your management organized";
exports.servicesLabel = "Services";
exports.createAServiceLabel = "Create A Service";
exports.deleteServiceLabel = "Delete Service?";
exports.deleteServiceDescription = "Are you sure you want to delete this Service?";
exports.nameOfTheServicesLabel = "Name of the Service";
exports.serviceNamePlaceHolderLabel = "E.g, Teaching, Security, Etc";
exports.serviceDescriptionLabel = "Service Description";
exports.priceLabel = "Price";
exports.costPlaceHolderLabel = "Cost";
exports.unitPricePlaceHolderLabel = "Unit Price";
exports.zeroPlaceholder = '0.00'
exports.markupPlaceHolderLabel = "Markup (%): 0.00";
exports.totalPricePlaceHolderLabel = "Total Price";
exports.exemptTaxLabel = "Exempt Tax";
exports.serviceImagesLabel = "Service Images:";
exports.uploadFilesLabel = "Upload Files";
exports.nameOfTheproductLabel = "Name of the Product";
exports.productNamePlaceHolderLabel = "E.g: Laptop, TV, Etc";
exports.productDescLabel = "Product Description";
exports.productImagesLabel = "Product Images:";
exports.serviceNameLabel = "Service Name";
exports.servicePriceLabel = "Service Price";
exports.markUpLabel = "Markup (%)";
exports.productNameLabel = "Product Name";
exports.productPriceLabel = "Product Price";
exports.totalLabel = "Total";
exports.markUpLabel = "Markup";
exports.startSymbol = "*";

exports.billingLabel = "Billing";
exports.accountInfoLabel = "Account Info";
exports.accountStatusLabel = "Account Status";
exports.activeLabel = "Active";
exports.activeSinceLabel = "Active Since Date";
exports.activeFromDateLabel = "Jan 31, 2023";
exports.subscriptionDetailsLabel = "Subscription details";
exports.planLabel = "Plan";
exports.goldLabel = "Gold";
exports.manageAndCancelPlanLabel = "You can manage and cancel your subscription plan here";
exports.managePlanLabel = "Manage plan";
exports.paymentMethodDetailsLabel = "Payment Method Details";
exports.noCardInfoLabel = "NO card info available. Add your card to make payment";
exports.addCardLabel = "Add Card";
exports.invoiceLabel = "Invoice";
exports.invoiceInfoLabel = "Invoice Info";
exports.findYourPaymentsLabel = "Find your payments here when your trial ends";
exports.dateLabel = "Date";
exports.invoiceTitleLabel = "Invoice Title";
exports.amountLabel = "Amount";
exports.statusLabel = "Status";

exports.manageYourPlanLabel = "Manage Your Plan";
exports.currentPlanLabel = "Current Plan";
exports.goldLabel = "Gold";
exports.changeMyPlanLabel = "Change my plan";
exports.changeMyPlanDescLabel = "You can change your plan it will be effective from n day onwards";
exports.changePlanLabel = "Change Plan";
exports.cancelMyPlanLabel = "Cancel my Plan";
exports.cancelMyPlanDescLabel = "You can change your plan it will be effective from n day onwards";
exports.cancelPlanLabel = "Cancel Plan";
exports.cancelPlanTitleLabel = "Are you sure you want to cancel your current plan?";
exports.cancelPlanDescLabel = "If you do so your active plan will be cancelled after completion of this plan. And you won’t charged further. All your premium services will be halt.";

exports.americanExpressLabel = "American Express";
exports.cardHolderLabel = "Card Holder";
exports.expireLabel = "Expire";
exports.doubleClickDeleteLabel = "Double click to delete the card.";
exports.removeCardLabel = "Remove Card";
exports.plusAddNewCardLabel = "+ Add New Card";
exports.addPaymentLabel = "Add Payment";
exports.myCardLabel = "My Card(s)";
exports.addNewCardLabel = "Add New Card";
exports.cardNameLabel = "Card Name";
exports.cardNumberLabel = "Card Number";
exports.expDateLabel = "Exp. Date";
exports.cvvLabel = "CVV";
exports.threeDigitLabel = "The last 3 digit number displayed on back of your card.";
exports.savePaymentLabel = "Save this payment for future purchases.";
exports.deleteCardLabel = "Delete Card?";
exports.deleteCardDescLabel = "Are you sure you want to delete this Card?";

exports.productCreatedMessage = "Product created successfully";
exports.serviceCreatedMessage = "Service created successfully";
exports.productDeletedMessage = "Product deleted successfully";
exports.serviceDeletedMessage = "Service deleted successfully";
exports.productUpdatedMessage = "Product updated successfully";
exports.serviceUpdatedMessage = "Service updated successfully";


exports.tokenExpired = "Token has Expired";
exports.invalidToken = "Invalid token";
exports.errorLabel = "error";

exports.mockWeeklyLabel = {
  time: "09:00 AM",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
}

// Regex
exports.regexName = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
exports.regexNumbers = /^\d+(\.\d{1,2})?$/;
exports.regexDecimals = /^(\d+|\d*\.\d+)$/;
exports.regexDesc = /^(?:[a-zA-Z][a-zA-Z.,]*)(?:\s[a-zA-Z.,]+)*$/;
exports.regexWebsite = /^[a-zA-Z.]+(?:\s[a-zA-Z.]+)*$/;
exports.regexPhone = /^\d+$/;
exports.regexTaxIdNumber = /^[a-zA-Z0-9]+$/;
exports.regexEmail = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

// Error messages
exports.companyInformationErrorText = {
  alphabeticErrorMessage: "Please enter in alphabetical characters",
  numericalErrorMessage: "Please enter in numerical characters",
  alphaNumericalErrorMessage: "Please enter in alpha numerical characters",
  invalidPhoneErrorMessage: "This is not a valid Phone Number",
  invalidEmailErrorMessage: "Please enter a valid Email Address",
  nameLengthErrorMessage: "Tax Name should be less than 23 characters",
  companyNameErrorMessage: "Company Name is required",
  companyNameLengthErrorMessage: "Company Name should be less than 40 characters",
  countryCodeErrorMessage: "Country Code is required",
  phoneNumberErrorMessage: "Phone Number is required",
  emailErrorMessage: "Email is required",
  countryNameErrorMessage: "Country is required",
  webSiteUrlErrorMessage: "Please enter a valid Website Url",
  taxIdNameErrorMessage: "Tax ID Name should be less than 23 characters",
}

exports.productAndServicesErrorText = {
  productNameErrorMessage: "Product Name is required",
  productNameLengthErrorMessage: "Product Name should be less than 23 characters",
  productDescLengthErrorMessage: "Product Description should be less than 500 characters",
  productUnitPriceErrorMessage: "Unit Price is required",
  productCostErrorMessage: "Cost is required",
  numericErrorMessage: "Please enter numeric values",
  unitPriceErrorMessage: "Unit Price is required",
  serviceNameErrorMessage: "Service Name is required",
  serviceNameLengthErrorMessage: "Service Name should be less than 23 characters",
  serviceDescLengthErrorMessage: "Service Description should be less than 500 characters",
}

// Navigation labels
exports.Login = "Login";
exports.CompanyInformation = "CompanyInformation";
exports.ProductAndServices = "ProductAndServices";
exports.Billing = "Billing";
exports.Notification = "Notification";
// Customizable Area End