import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config.js");
import React from "react";
import {TaxRateResponse} from "../../QuoteManagement/src/PriceSectionController.web"
import { t } from "i18next";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
import { performInvoiceAuthorizationCheck,performInvoiceDeleteAuthorizationCheck } from "../../CustomisableUserProfiles/src/utility.web";
import { docExtensionIcon, jpgExtensionIcon, pdfExtensionIcon, pngExtensionIcon, pptExtensionIcon, txtExtensionIcon, xlsExtensionIcon } from "../../RequestManagement/src/assets";
import {extensionDoc, extensionJpg, extensionPdf, extensionPng, extensionPpt, extensionTxt,extensionXls} from '../../JobListing2/src/assets';
import { apiCall, checkTokenExpired } from "../../../components/src/CommonFunction";
export interface InvoiceCustomer {
  id?: string,
  type?: string,
  attributes?: InvoiceCustomerAttributes

}
interface JobProductData{
  attributes:{
    products:{
      data:[{
        attributes:{
          product_id:number,
          service_id: number 
          description: string 
          quantity: number
          unit_price: number
          total: number
          exempt_tax: boolean;
          files:[],
        },
        id:number
      }]
    }

    discount:string
    discount_type:string
    customer_id:string
    site_id:string
    total:number
    tax_name:string
    tax:number
    currency:string
    invoice_deposits:[{
      amount:string
      payment_method:string
      transaction_date:string
      pay_balance:boolean
      collected:boolean
      details:string
    }]
  }
}
export interface InvoiceCustomerAttributes {
  name?: string,
  email?: string,
  phone_number?: string,
  address?: string,
  state?: string,
  city?: string,
  zip_code?: string,
  notes?: string,
  quote_follow_up?: boolean,
  job_follow_up?: boolean,
  invoice_follow_up?: boolean,
  latitude?: string,
  longitude?: string,
  files?: null,
}
interface JobStatusData {
  id:number,
  title:string,
  statusName:string
}
interface StatusDateType{
  id:number,
  title:string,
  value:string
}
interface ProductServiceType {
  product_id: number,
  service_id: number,
  description: number,
  quantity: number,
  unitPrice: number,
  totalCost: number,
  files: object,
  completed: boolean,
  progress: number,
  exempt_tax:boolean
}
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    history: any
    // Customizable Area Start
    location: any
    openToastHandler:any
    t?: (key: string) => string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    checkedC: boolean,
    isEdit: boolean,
    isTitleEdit: boolean;
    jobReference: any,
    startDate: any,
    files:any
    endDate: any,
    methodName:any,
    productServices: any,
    productUploadFiles: any,
    internalNotesDoc: any,
    internalNotesMessage: string;
    selectedCustomerDetails: InvoiceCustomer,
    quoteSendEmail: any,
    anchorNEl: any;
    dialogOpen: boolean,
    customersList: any,
    searchCustomer: string,
    customerDetails: any,
    collectedPaymentForm:any;
    siteDailog: boolean,
    customerSitesList: any,
    requestDetails: any,
    siteDetails: any,
    searchSite: string,
    isCustomerSelected: boolean,
    anchorEl: any,
    internalAttachments: any,
    location: any,
    invoiceForm: any
    priceSectionData:any,
    progress: any;
    invoicePdf:boolean
    editStatus:boolean
    vertical:string
    horizontal:string
    currency:string
    invoiceStatusData:boolean
    SaveInvoiceStatus:boolean
    EditInvoiceStatus:boolean
    nextModal:any;
    completed:boolean;
    UpdateInvoiceStatus:boolean
    isAlreadyCreated:boolean;
    toasterStatus:boolean
    fileToaster:any
    collectedPayment:boolean
    openDeleteDailog: boolean,
    openSelectJob:boolean
    errorMessage:any;
    showErrorMessage:boolean;
    openNextModal:any;
    token:any
    internalNotes: string,
    singleRequestDetails:any
    getInvoiceLoading:boolean
    invoicePdfLoading:boolean
    discountDialog:boolean
    selectCurrency:string
    selected:boolean
    imgToasterText:any;
    getCustomerPlace:any,
    badDebtStatus:boolean
    count:any,
      toasterText1:any,
      extensionIcons:any;
      isEditable:boolean;
    selectedJob:any,
    isSelectedJob:boolean
    JobId:string
    extensionIconsEmail:any;
    createdInvoiceData:any,
    invoicePdfData:any,
    removedInternalAttachment:any,
    isErrorFormData:any
    EmailData:any,
    toasterText: string;
    status:any,
    payment_method:any,
    clientAttachments:any,
    requestEditStatus:boolean
    productData:any
  emailProgress: any
  emailCompleted: any
  emailCount: any
  customerId: any
  selectLan:string
  statusModal: boolean
  searchJobs: string
  invoiceNote:string
  dateModal: boolean
  checkedItems:string[]
  dateStatus:string
  selectedStatusForAPI:string
  selectedDate:{
    startDate: Date,
    endDate: null|Date
  },
  allJobsData:object
  sortDirection: string;
  sortColumn: string;
  jobCustomerId:string
  jobSiteId:string
  editEmail: string;
  markAsCollectdPayment:object
    // Customizable Area End
}

interface InvoiceError {
  id: number;
  formName: string;
  errorName: string;
}

interface SS {
    id: any;
    // Customizable Area Start
    datePickerRef: React.RefObject<DatePicker>
    // Customizable Area End
}

const errorData:InvoiceError[]=[{
  id:1,
  formName:'title',
  errorName:'title'
},
{
  id:2,
  formName:'customerSelect',
  errorName:'selectCustomer'
},
{
  id:3,
  formName:'change-product-name',
  errorName:'product_id' 
},
{
  id:4,
  formName:'openJobPopup',
  errorName:'selectedJob' 
}]
export default class InvoiceController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    allCustomersApiCallId: string = "";
    getCustomerDetailsApiCallId: string = '';
    updateInvoiceApiCallId: string = "";
    getCustomerSitesApiCallId: string = "";
    createInvoiceApiCallId: string = "";
    deleteInvoiceApiCallId:string = "";
    getProductApiCallId:string = "";
    getInvoicePdfApiCallId: string = "";
    invoicePaymentApiCallId: string = "";
    getInvoiceDataApiCallId:string=''
    getSiteDetailApiCallId:string=''
    getCurrencyApiCallId:string=''
    getJobApiCallId:string=''
    getSelectJobApiCallId:string=''
    mapRef: any;
    datePickerRef: React.RefObject<DatePicker>;
    markAsCompletedApiCallId:string=''
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.mapRef = React.createRef();
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChangePaymentMethodRef=this.handleChangePaymentMethodRef.bind(this)
   
    this.handleProductChange = this.handleProductChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleRemoveFile = this.handleRemoveFile.bind(this)
    this.removeUploadAttachmentFile = this.removeUploadAttachmentFile.bind(this)
    this.uploadAttachmentFile = this.uploadAttachmentFile.bind(this)
    this.handleDiscountDialogOpen = this.handleDiscountDialogOpen.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      checkedC: true,
      invoiceStatusData: false,
      openDeleteDailog: false,
      badDebtStatus:false,
      openSelectJob:false,
      SaveInvoiceStatus: false,
      selected:false,
      selectedCustomerDetails:{},
      fileToaster: false,
      files:[],
      imgToasterText: '',
      completed:false,
      EditInvoiceStatus: false,
      editStatus:true,
      UpdateInvoiceStatus: false,
      progress: 0,
      clientAttachments:[],
      getInvoiceLoading: false,
      invoicePdfLoading:false,
      toasterStatus: true,
      customerId: '',
      isErrorFormData:{},
      isEditable:true,
      jobReference: '',
      openNextModal:false,
      emailProgress:0,
      emailCompleted: false,
      emailCount: 0,
      nextModal:'',
      location: '',
      isEdit: false,
      isAlreadyCreated:false,
      methodName: "",
      isTitleEdit: false,
      quoteSendEmail: false,
      vertical:'top',
     horizontal:'center',
      anchorNEl: false,
      startDate: new Date(),
      endDate: '',
      productServices: [{
        product_id: "",
        service_id:'',
        description: "",
        quantity: 0,
        unitPrice: 0,
        totalCost: 0,
        files: [],
        completed:false,
        exempt_tax:false
      }],
      currency:"",
      selectLan:"",
      EmailData: {
        is_email: "",
        attachments: [],
        to: '',
        body: '',
        subject: ''
      },
      extensionIconsEmail: {
        jpg: extensionJpg,
        png: extensionPng,
        jpeg: extensionJpg,
        doc: extensionDoc,
        pdf: extensionPdf,
        xls: extensionXls,
        txt: extensionTxt,
        ppt: extensionPpt,
        docx: extensionDoc,
        xlsx: extensionXls,
        pptx: extensionPpt,
    },
      productUploadFiles: [],
      internalNotesDoc: [],
      internalNotesMessage: '',
      dialogOpen: false,
      customersList: [],
      searchCustomer: "",
      customerDetails: [],
      errorMessage:'',
      showErrorMessage:false,
      siteDailog: false,
      customerSitesList: [],
      selectedJob: this.props && this.props.location.state && this.props.location.state.jobId? this.props.location.state.jobId :'',
      requestDetails: [],
      isSelectedJob: this.props && this.props.location.state && this.props.location.state.isSelectedJob ? true : false,
      JobId:this.props&&this.props.location&&this.props.location.state&&this.props.location.state.selectJobId,
      toasterText: '',
      siteDetails: {},
      searchSite: "",
      isCustomerSelected: false,
      anchorEl: null,
      collectedPaymentForm: {
        amount:'',
        payment_method:'',
        transaction_date:new Date(),
        details:'',
        pay_balance: false,
        collected: false
        },
        selectCurrency:"",
        priceSectionData:{
          sub_total:"",
          total:"",
          tax:"",
          discount:"",
        required_deposit:"",
        },
        getCustomerPlace: null,
        discountDialog:false,
      invoiceForm: {
        title: '',
        customer_id: '',
        site_id: '',
        issue_date:  new Date(),
        payment_due: new Date(),
        internal_notes: '',
        product_id: '',
        service_id:'',
        quantity: '',
        unit_price: '',
        total: '',
        job_id: ""
      },
      payment_method:"",
      
      invoicePdf:false,
      status: 'all',
      count:0,
      toasterText1:'',
      extensionIcons:{
        jpg: jpgExtensionIcon,
        png: pngExtensionIcon,
        jpeg: jpgExtensionIcon,
        doc: docExtensionIcon,
        pdf: pdfExtensionIcon,
        xls: xlsExtensionIcon,
        txt: txtExtensionIcon,
        ppt: pptExtensionIcon,
        docx: docExtensionIcon,
        xlsx: xlsExtensionIcon,
        pptx: pptExtensionIcon,
      },
      createdInvoiceData: {},
      invoicePdfData:{},
      internalNotes: '',
      collectedPayment: false,
      internalAttachments: [],
      removedInternalAttachment:[],
      singleRequestDetails: [],
      productData:[],
      requestEditStatus:false,
      statusModal: false,
      searchJobs: "",
      invoiceNote:"",
      dateModal: false,
      selectedDate: {
        startDate: new Date(),
        endDate: null
      },
      dateStatus: `${t('dashboard.today')}`,
      selectedStatusForAPI:'today',
      checkedItems: ["all", "upcoming","in_progress","invoiced","completed","late","cancelled"],
      allJobsData:[],
      sortDirection: "asc",
      sortColumn:"",
      token: localStorage.getItem("authToken"),
      jobCustomerId:'',
      jobSiteId:'',
      editEmail:'',
      markAsCollectdPayment:{}
      // Customizable Area End
    };
    this.datePickerRef = React.createRef<DatePicker>()
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.allCustomersApiCallId) {
          this.getAllCustomerResponseCheck(responseJson)
        }else if (apiRequestCallId === this.getCustomerSitesApiCallId) {
          this.customerSiteResponseCheck(responseJson)
        } else if (apiRequestCallId === this.createInvoiceApiCallId) {
          this.createInvoiceResponse(responseJson)
        } else if (apiRequestCallId === this.getProductApiCallId) {
          this.getProductResponseCheck(responseJson)
        } else if (apiRequestCallId === this.deleteInvoiceApiCallId) {
          this.deleteInvoiceResponse(responseJson)
        }else if (apiRequestCallId === this.invoicePaymentApiCallId) {
          this.invoicePaymentResponse(responseJson)
        }else if (apiRequestCallId === this.updateInvoiceApiCallId) {
          this.updateInvoiceResponse(responseJson)
        }else if (apiRequestCallId === this.getInvoiceDataApiCallId) {
          this.getInvoiceDataResponse(responseJson)
        }else if (apiRequestCallId === this.getCustomerDetailsApiCallId) {
          this.getCustomerDetailsApiResponse(responseJson)
        }else if(apiRequestCallId === this.getSiteDetailApiCallId){
          this.getSiteDetailApiResponse(responseJson)
        }else if(apiRequestCallId === this.getCurrencyApiCallId){
          this.getCurrencyApiResponse(responseJson)
        }else if(apiRequestCallId === this.getJobApiCallId){
          this.getJobResponse(responseJson)
        }else if(apiRequestCallId === this.getSelectJobApiCallId){
          this.getSelectJobResponse(responseJson)
        }
        this.getMarkAsCollectedPayment(apiRequestCallId,responseJson) 

        
      }
        // Customizable Area End 
    }
    // Customizable Area Start
        
  getMarkAsCollectedPayment = (apiRequestCallId: string, responseJson:{data:{},error:{[key: string]: string[]}} ) => {
    if (apiRequestCallId === this.markAsCompletedApiCallId) {
      if (responseJson && !responseJson.error) {
        setTimeout(() => {
          this.setState({ markAsCollectdPayment: responseJson.data })
          this.props.openToastHandler(`${t('invoice.PaymentCollectionSuccess')}`, "success")
         
          this.handleCollectionPaymentClose()
        }, 2000)
        
      } else if (responseJson&&responseJson.error) {
          this.props.openToastHandler(responseJson.error, "error")
          this.handleCollectionPaymentClose()
          for (let key in responseJson.error) { 
            this.props.openToastHandler(`${key} ${responseJson.error[key][0]}`, "error")
            this.handleCollectionPaymentClose()
          } 
          this.setState({ markAsCollectdPayment: [] })
        }

    }

    
  }


    async componentDidMount() {
      // Customizable Area Start
      const selectedLanguage = await getStorageData("lang");
      this.setState({ selectLan: selectedLanguage })
      window.scrollTo(0, 0);
      if(this.props&&this.props.location&&this.props.location.state&&this.props.location.state.invoiceId){
        this.setState({isAlreadyCreated:true})
        this.allCustomers()
       
      } else {
      }
      if(this.state.JobId){
        this.getSelectJobData()
      }
      if(localStorage.getItem("createFromCustomer")){
        this.getCustomerDetail()
        this.getSiteDetail()
      }
      this.getCurrencySymbol()
      const customerTabId = localStorage.getItem('customerTabId')
      if(customerTabId){
        this.getInvoiceDataAPI()
        this.getSiteDetail()
        this.getCustomerDetail()
        this.allCustomers()
      }
      // Customizable Area End
    }
    // internalNotes upload file
    removeFileCallBackFun = () => {
      if (this.state.files.length === 0)
        this.setState({ completed: false, count: 0, progress: 0 });
    };
  removeUploadAttachmentFile = (file: any) => {
    if(this.state.isEditable){
    const { files } = this.state;
    const filesFiltered = files?.filter((f: any) => {
      return f.name !== file?.name;
    });
    if (file.id) {
      this.setState({removedInternalAttachment:[...this.state.removedInternalAttachment,file.id],files: filesFiltered})
    } else {
      this.setState({ files: filesFiltered||[], internalAttachments: filesFiltered.filter((item: any) => !item.id) },
        () => {
          this.removeFileCallBackFun()
        }
      );
  }
  }
}
    uploadAttachmentFile = (event: any) => {
      const files = event.target?.files;
      this.fileUploadProceed(Array.from(files))
    };
    fileUploadProceed = (files: any) => {
      const stateFiles=this.state.files||[]
      if ([...stateFiles, ...files].length > 0 && [...stateFiles, ...files].length < 21) {
        // Update state with new unique files
        this.setState({ files: [...stateFiles, ...files]||[] }, () => {
          this.progressFileUpload(files);
        });
      } else {
        this.props.openToastHandler(t("customers.maxFileCount"), "error");
      }
    };
    progressFileUpload = (files: any) => {
      if (files) {
        for (const file of files) {
          const lastindexDot = file.name?.lastIndexOf(".");
          const ext = lastindexDot !== -1 ? file.name?.substring(lastindexDot + 1) : "";
          const fileSize = 10 * 1024 * 1024; // 10MB file size limit
          if (file.size > fileSize) {
            this.props.openToastHandler(`${t('invoice.fileSizeName')}`, "error");
            continue; // Skip the file and proceed to the next iteration
          }
          if (!this.state.extensionIcons[ext]) {
            this.props.openToastHandler(`${t('invoice.fileName')}`, "error");
            continue; // Skip the file and proceed to the next iteration
          }
          this.updateInternalAttachments(Array.from(files))
          const fileReader = new FileReader();
          fileReader.onloadstart = this.AttachmentFileUploadLoadStart;
          fileReader.onprogress = (event: any) => this.fileUploadOnProgress(event);
          fileReader.onload = this.AttachmentFileUploadOnLoad;
          fileReader.readAsDataURL(file);
        }
      }
    };
    updateInternalAttachments = (files: any) => {
      this.setState({ internalAttachments: [...this.state.internalAttachments,...files] })
    }
    updateInternalNotes = (internalNotes: any) => {
      this.setState({ internalNotes: internalNotes })
    }
  AttachmentFileUploadLoadStart = () => {
    this.setState({ progress: 0 });
  };

  fileUploadOnProgress = (event: any) => {
    if (event.lengthComputable) {
      let lodedFile = 0;
      let totalFileSize = 0;
      for (let file of this.state.files) {
        lodedFile += file.size * (event.loaded / event.total);
        totalFileSize += file.size;
      }

      const completedPercentage = (lodedFile / totalFileSize) * 100;

      this.setState({ progress: completedPercentage });
    }
  };
  onLoadReadCallbackTimeout = () => {
    this.setState({ completed: true });
  };

  onLoadReadCallBackFun = () => {
    if (this.state.count === this.state.files.length) {
      this.setState({ progress: 100 });
      setTimeout(this.onLoadReadCallbackTimeout, 500);
    }
  };
  AttachmentFileUploadOnLoad = () => {
    this.setState(
      (prevState) => ({
        count: prevState.count + 1,
      }),
      this.onLoadReadCallBackFun
    );
  };
    handleChangePaymentMethodRef(selected:any){
      this.setState({collectedPaymentForm:{...this.state.collectedPaymentForm,payment_method:selected.value}})
    }
  selectCurrency = (selected: { value: string; label: string}) => {
    const { priceSectionData } = this.state;
    this.setState({
      priceSectionData: { ...priceSectionData, discount: 0 },
      selectCurrency: selected.value
    });
  }
  handleDiscountDialogOpen = () => {
    if (this.state.isEditable) {
      this.setState({ discountDialog: !this.state.discountDialog });
    }
  }
  getSubTotal = (productServices: any) => {
    let total: number = 0;
    productServices?.forEach((item: any) => {
      if (item.quantity && item.unitPrice) {
        total += item.quantity * parseFloat(item.unitPrice);
      } else {
        total += parseFloat(item.totalCost);
      }
    });
    return total;
  };
    currencyDiscount = (discount: number,subTotal:number) =>{
      if(this.state.selectCurrency !== '%'){
        return subTotal - this.state.priceSectionData.discount
      }else{
        return discount
      }
    }
    subTotalAfterDiscount = (currencyDiscountAmount:number,subTotal:number) =>{
      if(this.state.selectCurrency !== '%'){
        return currencyDiscountAmount
      }else{
        return subTotal - currencyDiscountAmount
      }
    }
    calculateTotal = () => {
      const subTotal = this.getSubTotal(this.state.productServices);
          const discount = (subTotal * this.state.priceSectionData.discount) / 100;
      const currencyDiscountAmount = this.currencyDiscount(discount, subTotal);
      const subtotalAfterDiscount = this.subTotalAfterDiscount(currencyDiscountAmount, subTotal);
          const nonExemptItems = this.state.productServices.filter((item: any) => item.exempt_tax === false);
            const nonExemptSubTotal = this.getSubTotal(nonExemptItems);
          const taxAmount = (nonExemptSubTotal * this.state.priceSectionData.tax) / 100;
          const total = subtotalAfterDiscount + taxAmount;
          return total.toFixed(2);
    };  
    // product service section functions
  addField = () => {
    this.setState({
      productServices: [...this.state.productServices, {
        product_id: "",
        service_id:'',
        description: "",
        quantity: '',
        unitPrice: '',
        totalCost: '',
        files: [],
        completed:false,
        exempt_tax:false
      }]
    });
  }
  handleRemoveFile = (index:any,file:any) => {
    if(this.state.isEditable){
    const values = [...this.state.productServices];
    const filesFiltered = Array.from(values[index]['files']).filter((f: any) => {
      return f.name !== file.name;
    });
    values[index]['files'] = filesFiltered
    values[index]['completed'] = true
    values[index]['progress'] = 100
    if(values[index]['file_id'] && values[index]['file_id'].length>0){
      values[index]['file_id'] = [...values[index]['file_id'],file.id]
    } else{
      values[index]['file_id'] = [file.id]
    }
    
    this.setState({productServices:values})
  }
  };
  handleRemoveEmailFile= () => {
    this.setState({EmailData:{...this.state.EmailData,attachments:[]},emailCompleted:false,emailProgress:0})
  };
  removeField = (index:any) => {
    const values = [...this.state.productServices];
    values.splice(index, 1);
    this.setState({productServices:values})
  };
  handleChangeInput = (index:any, event:any) => {
    const values = [...this.state.productServices];
    if(event?.target){
      values[index][event.target.name] = event.target.value;
      this.setState({productServices:values})
    }else{
      if(event.value.type==='service'){
        values[index]['service_id'] = event.value.id;
        values[index]['product_id'] = null;
        values[index]['quantity'] = 1
        values[index]['totalCost'] = event.value.total_price
        values[index]['unitPrice'] = event.value.total_price
      }else{
        values[index]['service_id'] = null;
       values[index]['product_id'] = event.value.id;
        values[index]['quantity'] = 1
        values[index]['unitPrice'] = event.value.total_price
      }
      values[index]['description'] = event.value.description;
      values[index]['exempt_tax'] = event.value.exempt_tax || false;  
      this.setState({productServices:values})
    }
  };
  handleDownloadFile = async (file: any) => {
    
    try {
      const url = file.url ? file.url : URL.createObjectURL(file);

      const response = await fetch(url);
      const blob = await response.blob();

      // Create an anchor element
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = file.name;

      // Trigger a click on the anchor link
      downloadLink.click();

      // Clean up the object URL
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      this.props.openToastHandler(`Error downloading the file, please try again.`, 'error')
    }
  }
  handleChangeProductFiles = (index:any, event:any) => {
    const values = [...this.state.productServices];
    const files:any = event.target?.files;
    if (files.length>0 && [ ...values[index].files,...files].length > 4) {
      this.props.openToastHandler(`${t('invoice.maxFile')}`, "error")
    } else if (files.length>0 && [ ...values[index].files,...files].length > 0&&[ ...values[index].files,...files].length < 5) {
      this.setState({
        showErrorMessage: false,
        errorMessage: "",
      });
          if(values[index].files && values[index].files.length===0){
            values[index][event.target?.name]=[...files]
          }else{
            values[index][event.target?.name]=[ ...values[index].files,...files]
          }
      this.setState({productServices:values,fileToaster: false, imgToasterText: ""}, () => {
        this.handleFileUpload(files,index);
      });
    }
   
  };
  handleFileUpload = (files: any,index:any) => {
    
    if (files) {
      for (const file of files) {
        
        const reader:any = new FileReader();
        reader.onloadstart = this.readUploadOnLoadStart(index);
        reader.onprogress =(event:any)=> this.readUploadOnProgress(event,index);
        reader.onload = this.readUploadOnLoad(index);

        reader.onerror = this.readUploadOnError(index);
        reader.readAsDataURL(file);
      }
    }
  };
  readUploadOnLoadStart = (index:any) => {
    const values = [...this.state.productServices];
    values[index]['progress'] = 0
    this.setState({ productServices:values});
  };
  readUploadOnProgress = (event: any,index:any) => {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      const values = [...this.state.productServices];
      values[index]['progress'] = percentComplete
      this.setState({ productServices:values});
    }
  };
  readUploadOnLoadCallbackTimeout = (index:any) => {
    const values = [...this.state.productServices];
      values[index]['completed'] = true
      this.setState({ productServices:values,count:0});
  };
  
  readUploadOnLoadCallback:any = (index:any) => {
    setTimeout(() => { this.readUploadOnLoadCallbackTimeout(index) }, 500)
  };
  readUploadOnLoad = (index:any) => {
    const values = [...this.state.productServices];
      values[index]['progress'] = 100
      setTimeout(() => {
        this.setState({ productServices:values});
        this.setState(
          (prevState) => ({
            count: prevState.count + 1,
          }),
          ()=>this.readUploadOnLoadCallback(index)
        );
      }, 500);
  };

  readUploadOnError = (index:any) => {
    this.setState({
      toasterText1: "File could not be read!",
      fileToaster: true,
    });
  };
   
   
  handleDateChange(date: any, name: any) {
    if (name === 'startDate') {
      this.setState({ startDate: date })
    }
    if (name === 'endDate') {
      this.setState({ endDate: date })
    }
  }
  handleProductChange = (e: any) => {
    if(e.target?.name){
      this.setState({
        productServices: { ...this.state.productServices, [e.target?.name]: e.target?.value }
      });
    }else{
        this.setState({
        productServices: { ...this.state.productServices, 'product_id': e.value }
        });
      }
  }
  handleEmailChange = (e: any) => {
    this.setState({
      EmailData: { ...this.state.EmailData, [e.target?.name]: e.target?.value, editEmail: e.target?.value }
    });
  }
  handleProductDocument = (e: any) => {
    let reader = new FileReader();
    const files = e.target?.files[0];
    if (files) {
      reader.readAsDataURL(files);
    }
    this.setState({
      productServices: { ...this.state.productServices, [e.target?.name]: e.target?.files||[] }
    });
  }
  readEmailUploadOnLoadStart = () => {
    this.setState({ emailProgress: 0 });
  };
  readEmailUploadOnProgress = (event: any) => {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;

      this.setState({ emailProgress: percentComplete });
    }
  };
  readEMailUploadOnLoadCallbackTimeout = () => {
    this.setState({ emailCompleted: true });
  };
  
  readEMailUploadOnLoadCallback = () => {
      setTimeout(this.readEMailUploadOnLoadCallbackTimeout, 500);
  };
  readEmailUploadOnLoad = () => {
    this.setState({ emailProgress: 100 });
    this.setState(
      (prevState) => ({
        emailCount: prevState.emailCount + 1,
      }),
      this.readEMailUploadOnLoadCallback
    );
  };

  readEmailUploadOnError = () => {
    this.setState({
      toasterText1: "File could not be read!",
      fileToaster: true,
    });
  };
   
   
  handleEmailFileUpload = (files: any) => {
    
    if (files) {
      
        const reader = new FileReader();
        reader.onloadstart = this.readEmailUploadOnLoadStart;
        reader.onprogress = this.readEmailUploadOnProgress;
        reader.onload = this.readEmailUploadOnLoad;

        reader.onerror = this.readEmailUploadOnError;
        reader.readAsDataURL(files[0]);
    }
  };
  handleEmailDocument = (e: any) => {
    if (e.target?.files.length > 1) {
      this.props.openToastHandler(`${t('invoice.1File')}`, "error")
    } else if (e.target?.files.length > 0&&e.target?.files.length < 2) {
      let reader = new FileReader();
      const files = e.target?.files[0];
      if (files) {
        reader.readAsDataURL(files);
      }
      this.setState({
        EmailData: { ...this.state.EmailData, attachments: files}
      })
      setTimeout(() => {
        this.setState({emailCompleted:true,emailProgress:0})
      }, 1000);
      this.handleEmailFileUpload(e.target?.files||[]);
    }
  }
  handleInternalNotesMessageChange = (e: any) => {
    this.setState({
      internalNotesMessage: e.target&&e.target.value
    })
  }
 
saveSiteMapData = (customer: any) => {
  if(customer?.latitude){
  const selectedPlace = {
    lat: parseFloat(customer?.latitude),
    lng: parseFloat(customer?.longitude),
  };
  this.setState({ getCustomerPlace: selectedPlace });

  const map = this.mapRef.current;
  if (map) {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(selectedPlace);
    map.fitBounds(bounds, 100);
    map.setZoom(15);
  }
}
}
getEditCustomerDetails = (customerDetails: any) => {
  this.setState({
      selectedCustomerDetails: customerDetails,
      customerId: customerDetails.id
  })
}
getCustomerDailogStatus = (Status: boolean, selected: boolean, siteDetails: any) => {
  this.setState({
      dialogOpen: Status,
      isCustomerSelected: selected,
      siteDetails: siteDetails
  })
}
getCustomerSelectedStatus = (customerSelected: boolean, customerDailog: boolean) => {
  this.setState({
    dialogOpen: customerDailog,
  })
}
getCustomerDetails = (details: any) => {
  this.setState({
    selectedCustomerDetails: details
  })
}
  handleCollectionPaymentModal = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("invoice"); //true = not authorized , false = authorized
    this.handlePaymentInvoiceButtonCallback(isAuthorized);
  };

  handlePaymentInvoiceButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.handleButtonClose()
      this.setState({collectedPaymentForm:{
        amount:this.state.collectedPaymentForm.amount,
      payment_method:this.state.collectedPaymentForm.payment_method,
      transaction_date:this.state.collectedPaymentForm.transaction_date,
      details:this.state.collectedPaymentForm.details,
      pay_balance:this.state.collectedPaymentForm.pay_balance,
      collected:this.state.collectedPaymentForm.collected,
        }})
      if(this.state.isAlreadyCreated){
        this.updateInvoice()
      }else{
        this.setState({ isErrorFormData: this.validateForm() },
        () => {
          if (Object.keys(this.state.isErrorFormData).length === 0) {
            this.createInvoice();
          }
        }
      )
      }
    
      this.setState({ nextModal: 'payment' });
    } else {
      this.props.openToastHandler(`${t('invoice.BoAuthError')}`, "error")

    }
  };
  handleSavePaymentAndEmailReceipt = () => {
    this.invoicePayment()
    if(this.state.isAlreadyCreated){
      this.updateInvoice()
    }else{
      this.createInvoice()
    }
  
    this.setState({ nextModal: 'email' });
    
  }
  handleCollectionPaymentClose = () => {
    this.setState({
      openNextModal: 'payment'
      , collectedPayment: false, nextModal: '', collectedPaymentForm: { amount: this.state.collectedPaymentForm.amount, payment_method: this.state.collectedPaymentForm.payment_method, transaction_date: this.state.collectedPaymentForm.transaction_date, details: this.state.collectedPaymentForm.details}
    }, () => this.invoicePayment());
  }
  handleCollectionPaymentSave = () =>{
    this.setState({collectedPaymentForm:{
      amount:this.state.collectedPaymentForm.amount,
      payment_method:this.state.collectedPaymentForm.payment_method,
      transaction_date:this.state.collectedPaymentForm.transaction_date,
      details:this.state.collectedPaymentForm.details,
      pay_balance: this.state.collectedPaymentForm.pay_balance,
      collected: this.state.collectedPaymentForm.collected
      }})
    this.invoicePayment()
  }
  
  handleOpenInvoicePdf = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("invoice"); //true = not authorized , false = authorized
    this.handleInvoicePdfButtonCallback(isAuthorized);
  };

  handleInvoicePdfButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.handleButtonClose()
      if(this.state.isAlreadyCreated){
        this.updateInvoice()
        this.setState({ invoicePdfLoading:true });
      }else{
        this.setState({ isErrorFormData: this.validateForm() },
        () => {
          if (Object.keys(this.state.isErrorFormData).length === 0) {
            this.createInvoice();
            this.setState({ invoicePdfLoading:true });
          }
        }
      )
      }
      this.setState({ nextModal: 'pdf' });
    } else {
      this.props.openToastHandler(`${t('invoice.BoAuthError')}`, "error")
    }
  };
  handleCloseInvoicePdf=()=>{
    this.setState({  openNextModal: 'pdf' },()=>this.updateInvoice());
  }
  handleChange = (e: any) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, [e.target?.name]: e.target?.value } });
  }
  handleTransactionDate = (date: Date) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, transaction_date: date } })
  }
  handlePriceSection = (e: any) => {
    this.setState({ priceSectionData: { ...this.state.priceSectionData, [e.target?.name]: e.target?.value } });
  }
  handlePay_balence = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, pay_balance: event.target?.checked } });
  }
  handleCollected = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, collected: event.target?.checked } });
  }
  selectTaxRateInvoice = (taxRate: TaxRateResponse) => {
    this.setState({
      priceSectionData:{
        ...this.state.priceSectionData,
        tax_name: taxRate.name,
        tax: taxRate.rate,
      }
    })
  }
  emptyPriceSectionDataInvoice = (key: string) => {
    if (key === "discount") {
      this.setState({
        priceSectionData: {
          ...this.state.priceSectionData,
          discount: "",
        }
      })
    } else {
      this.setState({
        priceSectionData: {
          ...this.state.priceSectionData,
          tax_name: "",
          tax: "",
        }
      })
    }
    
  }
  handleArchivedDialogue = async () => {
    const isAuthorized = await performInvoiceDeleteAuthorizationCheck("invoice"); //true = not authorized , false = authorized
    this.handleDeleteInvoiceButtonCallback(isAuthorized);
  };

  handleDeleteInvoiceButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({
        openDeleteDailog: true
      })
    }else {
      this.props.openToastHandler(`${t('invoice.BoAuthError')}`, "error")
    }
  }
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDailog: false })
  }
  handleSelectJob = () => {
    if(this.state.isEditable){
      this.setState({ openSelectJob: true },()=>this.getJobReference())
    }
  }
  handleCloseSelectJob = () => {
    this.setState({ openSelectJob: false })
    if (!this.state.selectedJob) {
          this.jobOnBlur()
      }
  }
  handleStatusJobModalOpen = () => {
    this.setState({
      statusModal: true, searchJobs: "", dateModal: false
    });
  }
  handleStatusJobModalClose = () => {
    this.setState({ statusModal: false, })
  }
  handleSelectStatus = (item:JobStatusData,jobStatusData:JobStatusData[], event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.checked) {
      if (item.statusName === "all") {
        this.setState({
          checkedItems:jobStatusData.map((subItem) => subItem.statusName),
          status: "all"
        }, () => {
          this.getJobReference();
        })
      } else {
        this.setState({
          checkedItems: [...this.state.checkedItems,
          item.statusName
          ],
          status: [...this.state.checkedItems,
          item.statusName
          ].join(",")
        }, () => {
          this.getJobReference();
        })
      }
    } else {
      if (item.statusName === "all") {
        this.setState({
          checkedItems: [],
          status: ""
        }, () => {
          this.getJobReference();
        })
      } else {
        this.setState({
          checkedItems: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all"),
          status: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all").join(",")
        }, () => {
          this.getJobReference();
        })
      }
    }
  }
  handleDateModalOpen = () => {
    this.setState({ dateModal: !this.state.dateModal });
  }
  handleStatusModalClose = () => {
    if (this.state.dateStatus !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }
  handleDateChanges = (dates: [Date, null]) => {
    const [startDate, endDate] = dates;
    this.setState({
      selectedDate: { ...this.state.selectedDate, startDate: startDate, endDate: endDate },
    }, () => {
      if (startDate && endDate) {
        this.getJobReference();
        this.setState({ getInvoiceLoading: true });
        this.setState({ dateModal: false });
      }
    });
  };
  handleSelectDateStatus = (item: {value:string, title: string; }) => {
    const { startDate, endDate } = this.state.selectedDate;
    this.setState({ dateStatus: item.title,selectedStatusForAPI:item.value }, () => {
      if (item.title === t('dashboard.custom')) {
        if (startDate && endDate) {
          this.getJobReference();
          this.setState({ getInvoiceLoading: true });
        }
      } else {
        this.getJobReference();
        this.setState({ getInvoiceLoading: true });
      }
    });
    if (item.title !== t('dashboard.custom')) {
      this.handleStatusModalClose()
    }
  }
  handleChangeNotes= (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ invoiceNote: event.target.value});
  }
  handleSearchJobs = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchJobs: event.target.value }, () => {
      this.getJobReference();
    });
  }
  handleViewJob = (item: { attributes: { job_id: string }, id: string }) => {
    this.setState({ selectedJob: item.attributes.job_id, JobId: item.id, isSelectedJob: true, UpdateInvoiceStatus: false }, () => {
      this.handleCloseSelectJob()
      if (this.state.JobId) {
        this.getSelectJobData()
      }
    })
  }
  handleSort = (columnKey: string) => {
    let newSortDirection: "asc" | "desc" = "asc";
    if (columnKey === this.state.sortColumn) {
      newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
    }
    this.setState({
      sortDirection: newSortDirection,
      sortColumn: columnKey
    }, () => {
      this.getJobReference()
    });
  };
  handleInvoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ invoiceForm: { ...this.state.invoiceForm, [event.target?.name]: event.target?.value } },
      () => {
        this.setState({ isErrorFormData: { ...this.state.isErrorFormData, ...this.invoiceTitleValidation() } })
      });
  }
  handleOnBlur = () => {
    this.setState({ isErrorFormData: {...this.state.isErrorFormData,...this.invoiceTitleValidation()} })
  }
  productOnBlur = () => {
    this.setState({ isErrorFormData: {...this.state.isErrorFormData,...this.productServiceValidation()} })
  }
  jobOnBlur = () => {
    this.setState({ isErrorFormData: {...this.state.isErrorFormData,...this.invoiceJobValidation()} })
  }
  invoiceTitleValidation = () => {
    const errors = { title: "" }
    this.validateTextFields(this.state.invoiceForm.title, errors, 'title')
    if (!this.state.invoiceForm.title) {
        errors.title = `${t('errorMessage.title')}`
    } else if ((this.state.invoiceForm.title).length > 40) {
        errors.title = `${t('errorMessage.titleLength')}`
    }
    this.clean(errors)
    return errors;
}
productServiceValidation = () => {
    const errors = { product_id: [], service_id: [] }
    this.validateTextFieldsForArray(this.state.productServices, errors);
    this.clean(errors)
    return errors;
}
handleCusValidation=()=>{
  this.setState({ isErrorFormData: {...this.state.isErrorFormData,...this.invoiceCustomerValidation()} })
}
invoiceCustomerValidation=()=>{
  const errors = { customer_id: '',siteId:"" }
  this.validateTextFields(this.state.siteDetails?.customer_id, errors,'customer_id')
  if (!this.state.siteDetails?.customer_id) {
    errors.customer_id = `${t('errorMessage.customerId')}`
  }
  if (!this.state.siteDetails?.id) {
    errors.siteId = `${t('errorMessage.siteId')}`
  }
    this.clean(errors)
    return errors;
}
invoiceJobValidation=()=>{
  const errors = { selectedJob: '' }
    if (!this.state.selectedJob) {
      errors.selectedJob = `${t('errorMessage.job')}`
    }
    this.clean(errors)
    return errors;
}
  handleActions = (event: any) => {
    this.setState({ anchorNEl: event?.currentTarget });
  }
  handleNClose = () => {
    this.setState({ anchorNEl: null });
  };
  handleEmailSendOpenDialog = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("invoice"); //true = not authorized , false = authorized
    this.handleEmailInvoiceButtonCallback(isAuthorized);
  };

  handleEmailInvoiceButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.handleButtonClose()
    if(this.state.isAlreadyCreated){
      this.updateInvoice()
    }else{
      this.setState({ isErrorFormData: this.validateForm() },
      () => {
        if (Object.keys(this.state.isErrorFormData).length === 0) {
          this.createInvoice();
        }
      }
    )
    }
    this.setState({ nextModal: 'email' });
    } else {
      this.props.openToastHandler(`${t('invoice.BoAuthError')}`, "error")
    }
  };
  handleEmailClose = (email: any) => {
    this.setState({ openNextModal: 'email', editEmail:email }, () => this.updateInvoice())
  }
  handleEmailModalClose=()=>{
    this.setState({quoteSendEmail:false,openNextModal:'',nextModal:'',getInvoiceLoading:false,
    emailCompleted:false,
    emailProgress:0,
    EmailData:{
      is_email: "",
      attachments: [],
      to: '',
      body: '',
      subject: ''
    }})
  }
  
  handlePaymentModalClose=()=>{
    this.setState({collectedPayment:false,anchorEl: null ,openNextModal:'',nextModal:'',getInvoiceLoading:false,collectedPaymentForm:{amount:this.state.collectedPaymentForm.amount,payment_method:this.state.collectedPaymentForm.payment_method,transaction_date:this.state.collectedPaymentForm.transaction_date,details:this.state.collectedPaymentForm.details}})
  }
  handlePdfModalClose=()=>{
    this.setState({invoicePdf:false,openNextModal:'',nextModal:'',getInvoiceLoading:false})
  }
  Invoices = () => {
    this.props?.navigation?.navigate("Invoices")
  }
  //edit title
  handleEdit = () => {
    this.setState({
      isTitleEdit: true,
    })
  }
  handleCancel = () => {
    this.setState({ EditInvoiceStatus: true, UpdateInvoiceStatus: false })
    if(this.state.EditInvoiceStatus){
      this.Invoices()
    }
  }
 //istanbul ignore next
 handleSaveInvoice = () => {
  this.setState({ 
      isErrorFormData: this.validateForm() 
  }, () => {
      const { isErrorFormData } = this.state;
      if (Object.keys(isErrorFormData).length === 0) {
         
          this.createInvoice();
          this.setState({
              isTitleEdit: false,
              EditInvoiceStatus: true,
              editStatus: false,
              getInvoiceLoading: true,
              discountDialog: false
          });
      } else {
         
          const errorKeys = Object.keys(isErrorFormData);

          for (const errorKey of errorKeys) {
              
              const matchedErrorData = errorData.find((error: InvoiceError) => error.errorName === errorKey);

              if (matchedErrorData) {

                setTimeout(() => {

                  const element = document.querySelector(`[data-test-id="${matchedErrorData.formName}"]`);
                  if (element) {
                      
                      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  } else {
                      console.error(`Element with data-test-id "${matchedErrorData.formName}" not found.`);
                  }
              }, 100); 
        }}}});
}



  handleEditInvoice = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("invoice"); //true = not authorized , false = authorized
    this.handleEditInvoiceButtonCallback(isAuthorized);
  };

  handleEditInvoiceButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({
        EditInvoiceStatus: false,
        UpdateInvoiceStatus: true,
        isEditable: true,
      });
    } else {
      this.props.openToastHandler(`${t('invoice.BoAuthError')}`, "error")
    }
  };
 //istanbul ignore next
  handleUpdateInvoice = () => {
    this.setState({ 
        isErrorFormData: this.validateForm() 
    }, () => {
        const { isErrorFormData } = this.state;
        if (Object.keys(isErrorFormData).length === 0) {
            this.updateInvoice();
            this.setState({
                UpdateInvoiceStatus: false,
                EditInvoiceStatus: true,
                isTitleEdit: false,
                discountDialog: false
            });
          } else {
         
            const errorKeysData = Object.keys(isErrorFormData);
  
            for (const errorKey of errorKeysData) {
                
                const matchedError = errorData.find((error: InvoiceError) => error.errorName === errorKey);
  
                if (matchedError) {
                   
                    const element = document.querySelector(`[data-test-id="${matchedError.formName}"]`);
                    
                    if (element) {
                        
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        break;
                    }
                }
            }
        }
    });
}


  handleCloseToaster = () => {
    this.setState({ toasterStatus: false })
  }
  handleRequestChange = (e: any) => {
    this.setState({ invoiceForm: { ...this.state.invoiceForm, [e.target?.name]: e.target?.value } });
  }
  handleOpenDialog = () => {
    if(this.state.isEditable){
      this.setState({ dialogOpen: true }, () => {
      });
    }
  }
  clearCustomer = () => {
    this.setState({
      isCustomerSelected: false,
      customerDetails: {},
      siteDetails: {}
    },()=>{
      this.setState({isErrorFormData:{...this.state.isErrorFormData,...this.invoiceCustomerValidation()}})
  })
  }
  invoiceEdit = () => {
    this.setState({ requestEditStatus: true })
  }
  handleSiteClose = () => {
    this.setState({ siteDailog: false })
  }
  handleButton = (event:any) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleButtonClose = () => {
    this.setState({ anchorEl: null })
  };
  searchSites = (e: any) => {
    this.setState({ searchSite: e.target?.value }, () => {
      this.siteSearch()
    })
  }
  selectedSiteCustomer = (data: any) => {
    this.setState({
      siteDetails: data,
      siteDailog: false,
      isCustomerSelected: true
    })
  }
  getSiteDetails = (details: any) => {
    this.setState({
      siteDetails: details
    },()=>{
        this.saveSiteMapData(this.state.siteDetails)
    })
  }
  getCustomerDailogStatus1 = (selected: boolean) => {
    this.setState({
      isCustomerSelected: selected,
    })
  }
  clearCustomerSite = () => {
    this.setState({
      siteDetails: {},
      customerDetails: {},
      isCustomerSelected: false,
      getCustomerPlace:null,
      dialogOpen: false,
    })
  }
  onMapLoad = (map: google.maps.Map) => {
    this.mapRef.current = map;
    const zoomInButton = document.createElement('button');
    zoomInButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#1c18af"/>
        <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" fill="#1c18af"/>
    </svg>`;
    zoomInButton.style.backgroundColor = 'white';
    zoomInButton.style.cursor = 'pointer';
    zoomInButton.style.padding = '6px 3px 0px 5px';
    zoomInButton.style.borderRadius = '4px';
    zoomInButton.style.marginTop = '10px';
    zoomInButton.style.border = 'none';
    zoomInButton.style.marginBottom = '10px';
    zoomInButton.style.paddingLeft = '5px';
    zoomInButton.title = 'Zoom In';
    zoomInButton.addEventListener('click', () => map?.setZoom((map?.getZoom() ?? 0) + 1));
    const zoomOutButton = document.createElement('button');
    zoomOutButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path fill="#1c18af" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
    </svg>`;
    zoomOutButton.style.border = 'none';
    zoomOutButton.style.borderRadius = '4px';
    zoomOutButton.style.cursor = 'pointer';
    zoomOutButton.style.backgroundColor = 'white';
    zoomOutButton.style.marginBottom = '0px';
    zoomOutButton.style.paddingLeft = '5px';
    zoomOutButton.style.padding = '6px 3px 0px 5px';
    zoomOutButton.title = 'Zoom Out';
  
    zoomOutButton.addEventListener('click', () =>   map?.setZoom((map?.getZoom() ?? 0) - 1));
    const controlDiv = document.createElement('div');
    controlDiv.style.position = 'absolute';
    controlDiv.style.marginRight = '15px';
    controlDiv.style.zIndex = '1';
    controlDiv.style.marginBottom = '10px';
    controlDiv.style.right = '10px';
    controlDiv.style.justifyContent = 'space-between'; 
    controlDiv.style.display = 'flex';
    controlDiv.style.flexDirection = 'column';
    controlDiv.appendChild(zoomInButton);
    controlDiv.appendChild(zoomOutButton);
    const controlPosition = google.maps.ControlPosition.RIGHT_BOTTOM;
    map.controls[controlPosition].push(controlDiv);
  }; 
  getcurentLocation = () => {
    const googleMapsUrl = `https://www.google.com/maps?q=${this.state.getCustomerPlace.lat},${this.state.getCustomerPlace.lng}&z=15&markers=${this.state.getCustomerPlace.lat},${this.state.getCustomerPlace.lng}`;
    window.open(googleMapsUrl, '_blank');
  }
      // API Response fun
  getAllCustomerResponseCheck(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ customersList: responseJson.data })
      this.getSelectJobData()
    } else {
      if (responseJson&&responseJson.errors) {
        checkTokenExpired(responseJson,this.props.openToastHandler,this.props.history)
        for (let key in responseJson.errors) { 
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        } 
        this.setState({ customersList: [] })
      }
    }
  }
  
  customerSiteResponseCheck(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ customerSitesList: responseJson.data })
    } else {
      if (responseJson&&responseJson.errors) {
        checkTokenExpired(responseJson,this.props.openToastHandler,this.props.history)
        for (let key in responseJson.errors) { 
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")

          
        } 
        this.setState({ customerSitesList: [] })
      }
    }
  }
  getProductData(){
    if (this.props.location?.state?.jobId) {
      this.setState({
        productServices:this.props.location.state.productServices.map((item:ProductServiceType)=>{
         return{
          product_id: item.product_id,
          service_id:item.service_id,
          description: item.description,
          quantity: item.quantity||0,
          unitPrice: item.unitPrice||0,
          totalCost: item.totalCost,
          files:item.files||[],
          completed: true,
          exempt_tax:item.exempt_tax||false,
          progress: 100
         }
        })
      })
      
    }
  }
  getProductResponseCheck(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ productData: responseJson })
      if(this.props.location?.state?.invoiceId){
        this.getInvoiceDataAPI()
        
      }else if(this.props.location?.state?.customerId){
        this.getCustomerDetail()
        this.getSiteDetail()
      }else{
        this.setState({getInvoiceLoading:false})
      }
      this.getProductData()
    } else {
      if (responseJson&&responseJson.errors) {
        checkTokenExpired(responseJson,this.props.openToastHandler,this.props.history)
        for (let key in responseJson.errors) { 
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        } 
      }
    }
  }
 redirectFromCreateData(){
  if(this.state.nextModal==='email'){
    this.setState({ quoteSendEmail: true });
  }
  if(this.state.nextModal==='payment'){
    this.setState({ collectedPayment: true });
  }
  if(this.state.nextModal==='pdf'){
    this.setState({ invoicePdf: true });
  }
 }
 //istanbul ignore next
  createInvoiceResponse(responseJson: any) {
    if (responseJson && (!responseJson.errors && !responseJson.error)) {
      this.setState({ 
        createdInvoiceData: responseJson.data,
        EditInvoiceStatus:true, 
        isAlreadyCreated:true,
        SaveInvoiceStatus: true,
        isTitleEdit:false ,
        isEditable:false,
        completed:true,
        progress:100,
        internalAttachments:[],
        files:responseJson.data?.attributes?.files,
        productServices: responseJson.data?.attributes?.products?.data?.map((item: any) => {
          return {
            product_id: item?.attributes?.product_id,
            service_id:item?.attributes?.service_id,
            description: item?.attributes?.description,
            quantity: item?.attributes?.quantity,
            unitPrice: item?.attributes?.unit_price,
            totalCost: item?.attributes?.total,
            product_item_id: item?.id,
            files:item?.attributes?.files,
            completed:true,
            exempt_tax:item?.attributes?.exempt_tax || false,
            progress:100,
          }
        })
      })
      this.setState({ getInvoiceLoading: false })
      this.props.openToastHandler(`${t('invoice.invoiceCreate')}`, "success")
      this.redirectFromCreateData()
    } else {
      if (responseJson&&responseJson.errors) {
        checkTokenExpired(responseJson,this.props.openToastHandler,this.props.history)
        for (let key in responseJson.errors) { 
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        } 
        this.setState({ getInvoiceLoading: false})
      }else (
        this.setState({ getInvoiceLoading: false })
      )
    }
  }
  siteDetails = (siteId:string|null,cusId:string|null) =>{
    if(!siteId){
      this.setState({
        siteDetails: { 
            customer_id: this.state?.customersList.find((item: { id: number; }) => item.id == this.props.location&&this.props.location.state?.customerId||cusId)?.id, 
            id: this.props.location.state?.siteId || siteId
          }
      },()=>{
        this.getCustomerDetail()
        this.getSiteDetail()
      })
    }
  }
  //istanbul ignore next
  getInvoiceDataResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      const siteId = localStorage.getItem('invoiceSiteId1')
      const cusId = localStorage.getItem('invoiceCusId')
      this.siteDetails(siteId,cusId)
      this.setState({
        createdInvoiceData: responseJson.data, SaveInvoiceStatus: true,
        isCustomerSelected: true, selectedCustomerDetails: this.state.customersList.find((item: any) => item.id == this.props.location&&this.props.location.state.customerId||cusId),
        invoiceForm: { title: responseJson?.data?.attributes?.title, issue_date: new Date(responseJson?.data?.attributes?.issue_date),payment_due:new Date(responseJson?.data?.attributes?.payment_due) },
        isTitleEdit: false, isEditable: false, EditInvoiceStatus: true,
        productServices: responseJson.data?.attributes?.products?.data?.map((item: any) => {
          return {
            product_id: item?.attributes?.product_id,
            service_id:item?.attributes?.service_id,
            description: item?.attributes?.description,
            quantity: item?.attributes?.quantity||0,
            unitPrice: item?.attributes?.unit_price||0,
            totalCost: item?.attributes?.total,
            product_item_id: item?.id,
            files:item?.attributes?.files||[],
            exempt_tax:item?.attributes?.exempt_tax || false,
            completed:true,
            progress:100,
          }
        }),
        priceSectionData: {
            tax: responseJson.data?.attributes?.tax,
            tax_name: responseJson.data?.attributes?.tax_name,
            discount: responseJson.data?.attributes?.discount,
            required_deposit: responseJson.data?.attributes?.currency,
        },
        currency: responseJson.data?.attributes?.currency,
        selectCurrency: responseJson.data?.attributes?.discount_type,
        collectedPaymentForm: {
          amount: responseJson.data?.attributes?.invoice_deposits[0]?.amount,
          payment_method: responseJson.data?.attributes?.invoice_deposits[0]?.payment_method,
          transaction_date:new Date(responseJson.data?.attributes?.invoice_deposits[0]?.transaction_date),
          details: responseJson.data?.attributes?.invoice_deposits[0]?.details,
          pay_balance: responseJson.data.attributes?.invoice_deposits[0]?.pay_balance,
          collected: responseJson.data.attributes?.invoice_deposits[0]?.collected,

        },
        internalNotes: responseJson.data?.attributes?.notes,
        invoiceNote:responseJson.data?.attributes?.term_and_condition,
        completed:true,
        progress:100,
        files:responseJson.data?.attributes?.files,
        getInvoiceLoading: false ,
        isSelectedJob:true,
        selectedJob: responseJson.data?.attributes?.job_id
      }
      )
      this.redirectFromCreateData()
      localStorage.removeItem('customerTabId')
      localStorage.removeItem('invoiceCusId')
      localStorage.removeItem('invoiceSiteId1')
    } else {
      if (responseJson&&responseJson.errors) {
        for (let key in responseJson.errors) {
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        }
        this.setState({ getInvoiceLoading: false })
      }
    }
  }
  getSiteDetailApiResponse=(responseJson:any)=>{
    if(responseJson&&!responseJson.errors){
        this.setState({
            siteDetails:responseJson.data
        },()=>{
        this.saveSiteMapData(this.state.siteDetails)
        })
       const siteId = localStorage.getItem('invoiceSiteId1')
        if(this.props.location&&this.props.location.state&&this.props.location.state.siteId||siteId){
          this.setState({
            isCustomerSelected: true,
            siteDetails:responseJson.data
            })
        }
    }

}
  getCustomerDetailsApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
        this.setState({
            selectedCustomerDetails: responseJson.data,
        })
        this.saveSiteMapData(responseJson.data)
        if(this.props.location&&this.props.location.state&&this.props.location.state.customerId){
          this.setState({
            isCustomerSelected: true,
            selectedCustomerDetails: responseJson.data,
            })
        }
    } 
}
  invoicePaymentResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ collectedPayment: false})
      this.props.openToastHandler(`${t('invoice.invoicePayment')}`, "success")
      if(this.state.openNextModal==='payment'){
        this.setState({ collectedPayment: false ,isAlreadyCreated:true});
      }
    } else {
      if (responseJson&&responseJson.errors) {
        for (let key in responseJson.errors) { 
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        } 
      }
    }
  }
  checkNextModalAfterUpdate(){
    if(this.state.nextModal==='email'){
      this.setState({ quoteSendEmail: true});
    }
    if(this.state.nextModal==='pdf'){
      this.setState({ invoicePdf: true });
    }
    if(this.state.nextModal==='payment'){
      this.setState({ collectedPayment: true });
    }
    if(this.state.openNextModal==='email'){
      this.setState({ quoteSendEmail: false ,emailCompleted:false,nextModal:'', openNextModal:'' ,
        emailProgress:0,EmailData:{
        is_email: "",
        attachments: [],
        to: '',
        body: '',
        subject: ''
      }});
      this.props.openToastHandler(`${t('invoice.emailSuccessMsg')}`, "success")
    }
    if(this.state.openNextModal==='pdf'){
      this.setState({ invoicePdf: false, nextModal:'' });
    }
    if(this.state.openNextModal==='payment'){
      this.setState({ collectedPayment: false , nextModal:''});
    }
  }
  updateInvoiceResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ createdInvoiceData: responseJson.data.data,isTitleEdit:false,isEditable:false,anchorEl:null})
      this.setState({ getInvoiceLoading: false })
      if(this.state.nextModal===''){
        this.props.openToastHandler(`${t('invoice.updateInvoice')}`, "success")
      }
      this.checkNextModalAfterUpdate()
    } else {
      if (responseJson && responseJson.errors) {
        for (let key in responseJson.errors) { 
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        } 
        this.setState({ getInvoiceLoading: false })
      }
    }
  }
  deleteInvoiceResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.props.history.push('/Invoices',{ showArchiveMessage:true})
    } else {
      if (responseJson&&responseJson.errors) {
        for (let key in responseJson.errors) { 
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        } 
      }
    }
  }
  getCurrencyApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ currency:responseJson.currency_code })
    } else {
      if (responseJson&&responseJson.errors) {
        for (let key in responseJson.errors) { 
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        } 
      }
    }
  }
  getJobResponse = (responseJson: { message: string }) => {
    if (responseJson.message === "you are not authorized") {
      this.setState({ invoicePdfLoading: false, getInvoiceLoading: false });
      this.props.openToastHandler(`${t('invoice.BoAuthError')}`, "error");
    } else {
      this.setState({ invoicePdfLoading: false, getInvoiceLoading: false });
      this.setState({ allJobsData: responseJson }, () => {
        this.handleStatusJobModalClose()
      })
    }
  }
  getSelectJobResponse = (responseJson: { message: string, data:JobProductData }) => {
    if (responseJson.data) {
      this.setState({
        productServices: 
          responseJson.data.attributes.products&&
            responseJson.data.attributes.products.data&&
          responseJson.data.attributes.products.data.map((item) => {
          return {
            product_id: item.attributes.product_id,
            service_id: item.attributes.service_id,
            description: item.attributes.description,
            quantity: item.attributes.quantity || 0,
            unitPrice: item.attributes.unit_price || 0,
            totalCost: item.attributes.total,
            product_item_id: item.id,
            files: item.attributes.files || [],
            completed: true,
            exempt_tax:item.attributes.exempt_tax,
            progress: 100,
          }
        }),
        priceSectionData: {
          tax: responseJson.data.attributes.tax,
          tax_name: responseJson.data.attributes.tax_name,
          discount: responseJson.data.attributes.discount,
          required_deposit: responseJson.data.attributes.currency,
        },
        selectCurrency: responseJson.data.attributes.discount_type,
        jobCustomerId: responseJson.data.attributes.customer_id,
        jobSiteId: responseJson.data.attributes.site_id,
        collectedPaymentForm: {
          amount: responseJson.data.attributes.invoice_deposits[0]&&responseJson.data.attributes.invoice_deposits[0].amount,
          payment_method: responseJson.data.attributes.invoice_deposits[0]&&responseJson.data.attributes.invoice_deposits[0].payment_method,
          transaction_date:new Date(responseJson.data.attributes.invoice_deposits[0]&&responseJson.data.attributes.invoice_deposits[0].transaction_date),
          details: responseJson.data.attributes.invoice_deposits[0]&&responseJson.data.attributes.invoice_deposits[0].details,
          pay_balance: responseJson.data.attributes.invoice_deposits[0]&&responseJson.data.attributes.invoice_deposits[0].pay_balance,
          collected: responseJson.data.attributes.invoice_deposits[0]&&responseJson.data.attributes.invoice_deposits[0].collected,
        }, 
        isCustomerSelected: true, 
        selectedCustomerDetails: this.state.customersList&&this.state.customersList.find((item:{id:string}) =>
          item.id == this.props.location.state.customerId),
          siteDetails: { 
          customer_id: this.state.customersList&&this.state.customersList.find((item:{id:string}) => 
          item.id == this.props.location.state&&this.props.location.state.customerId)?.id, 
          id: this.props.location.state&&this.props.location.state.siteId 
        },
      },()=>{
        this.getProduct()
        this.getCustomerDetail()
        this.getSiteDetail()
      });
    }
  }
  allCustomers = () => {
    const token: any = this.state.token
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allCustomersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomersList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCustomerDetail = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token
    };
    const cusId = localStorage.getItem("invoiceCusId")
    let getCustomerUrl 
    if(this.props.location?.state?.customerId){
      getCustomerUrl= `${configJSON.allCustomers}/${this.props.location?.state?.customerId}?lang=${this.state.selectLan}`
    } else if(this.state.isSelectedJob===true){
      getCustomerUrl= `${configJSON.allCustomers}/${this.state.jobCustomerId}?lang=${this.state.selectLan}`
    } else if(cusId){
      getCustomerUrl= `${configJSON.allCustomers}/${cusId}?lang=${this.state.selectLan}`
    }else{
      getCustomerUrl= `${configJSON.allCustomers}/${this.state.createdInvoiceData?.attributes?.customer_id}?lang=${this.state.selectLan}`
    }
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomerDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getCustomerUrl
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}
 getSiteDetail = () => {
  const token = localStorage.getItem(configJSON.storageToken)
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
  };
  const siteId = localStorage.getItem("invoiceSiteId1")
  let getSiteUrl 
  if(this.props.location?.state?.customerId){
    getSiteUrl= `${configJSON.getCustomerSites}/${this.props.location?.state?.siteId}?lang=${this.state.selectLan}`
  } else if(this.state.isSelectedJob===true){
    getSiteUrl= `${configJSON.getCustomerSites}/${this.state.jobSiteId}?lang=${this.state.selectLan}`
  } else if(siteId){
    getSiteUrl= `${configJSON.getCustomerSites}/${siteId}?lang=${this.state.selectLan}`
  }else{
    getSiteUrl= `${configJSON.getCustomerSites}/${this.state.siteDetails&&this.state.siteDetails.id}?lang=${this.state.selectLan}`
  }
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getSiteDetailApiCallId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getSiteUrl
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
  siteSearch = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerSitesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_location//search_site?name=${this.state.searchSite}?lang=${this.state.selectLan}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // validations code
  
  validateTextFields = (title: string, errors: { [x: string]: string; }, key: string) => {
    const errorText: Record<string, {
      titleEmptyText: string;
    }> = {
      to: { titleEmptyText: `${t('errorMessage.email')}` },
      title: { titleEmptyText: `${t('errorMessage.title')}` },
      customer_id: { titleEmptyText: `${t('errorMessage.customerId')}` },
      siteId: { titleEmptyText: `${t('errorMessage.siteId')}` },
      selectedJob: { titleEmptyText: `${t('errorMessage.job')}` },
      product_id: { titleEmptyText: `${t('errorMessage.productService')}` },
      service_id: { titleEmptyText: `${t('errorMessage.productService')}` },
    }
    if (!title) {
      errors[key] = errorText[key].titleEmptyText

    }
  }
  validateTextFieldsForArray = (array: any, errors: any) => {
    array.forEach((item:any,index:any) => { 
      if ((item.product_id === null || item.product_id === '') && (item.service_id === null || item.service_id === '')) {
        errors.product_id[index] = `${t('errorMessage.productService')}`
      } 
    });
  }
  
  clean(obj:any) {
    for (let propName in obj) {
      if (obj[propName]?.length===0) {
        delete obj[propName];
      }
    }
    return obj
  }
  
  validateForm = () => {
    const errors: any = {product_id:[],service_id:[]};
    this.validateTextFields(this.state.invoiceForm.title, errors,'title')
    if (!this.state.invoiceForm.title) {
      errors.title = `${t('errorMessage.title')}`
    } else if ((this.state.invoiceForm.title).length > 40) {
      errors.title = `${t('errorMessage.titleLength')}`
    } 
    this.validateTextFields(this.state.siteDetails?.customer_id, errors,'customer_id')
    this.validateTextFields(this.state.siteDetails?.id, errors,'siteId')
    this.validateTextFields(this.state.selectedJob, errors,'selectedJob')
    this.validateTextFieldsForArray(this.state.productServices,errors); 
    this.clean(errors)
    return errors;
  }
  //istanbul ignore next
  createInvoice = () => {
    localStorage.removeItem('createFromCustomer')
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({getInvoiceLoading:true})
    
    let bodyDataInvoice:any = new FormData();
    bodyDataInvoice.append("data[title]", this.state.invoiceForm.title);
    bodyDataInvoice.append("data[customer_id]", this.state.siteDetails?.customer_id);
    bodyDataInvoice.append("data[site_id]", this.state.siteDetails?.id);
    bodyDataInvoice.append("data[issue_date]", this.state.invoiceForm.issue_date);
    bodyDataInvoice.append("data[payment_due]", this.state.invoiceForm.payment_due);
    bodyDataInvoice.append("data[notes]", this.state.internalNotes); 
    bodyDataInvoice.append("data[term_and_condition]", this.state.invoiceNote); 
    bodyDataInvoice.append("data[sub_total]", this.getSubTotal(this.state.productServices));
    bodyDataInvoice.append("data[total]", this.calculateTotal());
    bodyDataInvoice.append("data[tax]", this.state.priceSectionData.tax); 
    bodyDataInvoice.append("data[tax_name]", this.state.priceSectionData.tax_name); 
    bodyDataInvoice.append("data[discount]", this.state.priceSectionData.discount);
    bodyDataInvoice.append("data[currency]", this.state.currency);
    bodyDataInvoice.append("data[discount_type]", this.state.selectCurrency);
    if(this.state.collectedPaymentForm?.length > 0){
    bodyDataInvoice.append("data[invoice_deposit_attributes][payment_method]", this.state.collectedPaymentForm.payment_method);
    bodyDataInvoice.append("data[invoice_deposit_attributes][amount]",this.state.collectedPaymentForm.amount);
    bodyDataInvoice.append("data[invoice_deposit_attributes][transaction_date]", this.state.collectedPaymentForm.transaction_date);
    bodyDataInvoice.append("data[invoice_deposit_attributes][pay_balance]", this.state.collectedPaymentForm.pay_balance);
    bodyDataInvoice.append("data[invoice_deposit_attributes][details]",this.state.collectedPaymentForm.details);
    bodyDataInvoice.append("data[invoice_deposit_attributes][collected]", this.state.collectedPaymentForm.collected);
    }
    if(this.state.selectedJob){
      bodyDataInvoice.append("data[job_id]", JSON.stringify(parseInt(this.state.selectedJob.match(/(\d+)/)[0], 10)));
    }
    this.state.productServices?.forEach(function (value:any, index:any) {
      bodyDataInvoice.append(`data[product_items_attributes][${[index]}][description]`,value.description);
      bodyDataInvoice.append(`data[product_items_attributes][${[index]}][quantity]`,value.quantity);
      bodyDataInvoice.append(`data[product_items_attributes][${[index]}][unit_price]`,value.unitPrice);
      bodyDataInvoice.append(`data[product_items_attributes][${[index]}][total]`, value.quantity * value.unitPrice||value.totalCost);
      if (value.files) {
        value.files.forEach(function (value1: any, index1: any) {
          bodyDataInvoice.append(`data[product_items_attributes][${[index]}][files][]`, value1);
        })
      }
      
      if (value.product_id) {
        bodyDataInvoice.append(`data[product_items_attributes][${[index]}][product_id]`, value.product_id);
      }
      if (value.service_id) {
        bodyDataInvoice.append(`data[product_items_attributes][${[index]}][service_id]`, value.service_id);
      }
    })
    
    this.state.internalAttachments.forEach(function (value:any, index:any) {
      bodyDataInvoice.append(`data[files][]`, value);
    })
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createInvoiceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createInvoiceAPIEndPoint}?lang=${this.state.selectLan}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), bodyDataInvoice
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createInvoiceAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getInvoiceDataAPI = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    const customerInvoiceId = localStorage.getItem("customerTabId")
    this.setState({getInvoiceLoading:true}) 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoiceDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.createInvoiceAPIEndPoint}/${this.props.location?.state?.invoiceId||customerInvoiceId}&lang=${this.state.selectLan}`
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProduct = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.products_and_servicesEndPoint}?lang=${this.state.selectLan}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCurrencySymbol = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    const getCurrencyApiCall = apiCall({
      header: header,
      httpBody: null,
      url: `${configJSON.getCurrency}`,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.getCurrencyApiCallId = getCurrencyApiCall.messageId;
    runEngine.sendMessage(getCurrencyApiCall.id, getCurrencyApiCall);
  }
  getInvoicePdf = () => {
    const token = localStorage.getItem("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.setState({invoicePdfLoading:true})
    this.getInvoicePdfApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getInvoicePdf}?id=${this.state?.createdInvoiceData?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateProductService=(updateInvoiceBody:any)=>{
    this.state.productServices?.forEach(function (value: any, index: any) {
      updateInvoiceBody.append(`data[product_items_attributes][${[index]}][description]`,value.description);
      updateInvoiceBody.append(`data[product_items_attributes][${[index]}][quantity]`, value.quantity);
      updateInvoiceBody.append(`data[product_items_attributes][${[index]}][unit_price]`, value.unitPrice);
      updateInvoiceBody.append(`data[product_items_attributes][${[index]}][total]`, value.quantity * value.unitPrice||value.totalCost);
      if (value.file_id && value.file_id.length > 0) {
        updateInvoiceBody.append(`data[product_items_attributes][${[index]}][file_id]`, value.file_id);
      }
      if(value.files){
        value.files.forEach(function (value1:any, index1:any) {
          if(!value1.id){
          updateInvoiceBody.append(`data[product_items_attributes][${[index]}][files][]`, value1);
        }
        })
      }
      if(value.product_item_id){
        updateInvoiceBody.append(`data[product_items_attributes][${[index]}][product_item_id]`, value.product_item_id);
      }
      if(value.product_id){
        updateInvoiceBody.append(`data[product_items_attributes][${[index]}][product_id]`,value.product_id);
      }
      if(value.service_id){
        updateInvoiceBody.append(`data[product_items_attributes][${[index]}][service_id]`,value.service_id);
      }
    })
  }
  
  updateInvoice = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({getInvoiceLoading:true})
    let updateInvoiceBody:any = new FormData();
   
    if (this.state.nextModal === 'email') {
      updateInvoiceBody.append("is_email", true);
      //istanbul ignore next
      updateInvoiceBody.append("to",this.state.editEmail.length===0? this.state.selectedCustomerDetails.attributes?.email: this.state.editEmail);
      updateInvoiceBody.append("body", this.state.EmailData.body);
      updateInvoiceBody.append("subject", this.state.EmailData.subject);
      updateInvoiceBody.append("data[id]", this.state.createdInvoiceData.id);
      if(this.state.EmailData.attachments.length!==0){
        updateInvoiceBody.append("attachments[]",this.state.EmailData.attachments);
      }
      
    } else {
      updateInvoiceBody.append("data[title]", this.state.invoiceForm.title);
      updateInvoiceBody.append("data[customer_id]", this.state.siteDetails?.customer_id);
      updateInvoiceBody.append("data[site_id]", this.state.siteDetails?.id);
      updateInvoiceBody.append("data[issue_date]", this.state.invoiceForm.issue_date);
      updateInvoiceBody.append("data[payment_due]", this.state.invoiceForm.payment_due);
      updateInvoiceBody.append("data[notes]", this.state.internalNotes);
      updateInvoiceBody.append("data[term_and_condition]", this.state.invoiceNote);
      updateInvoiceBody.append("data[sub_total]", this.getSubTotal(this.state.productServices));
      updateInvoiceBody.append("data[total]", this.calculateTotal());
      updateInvoiceBody.append("data[tax]", this.state.priceSectionData.tax);
      updateInvoiceBody.append("data[tax_name]", this.state.priceSectionData.tax_name); 
      updateInvoiceBody.append("data[discount]", this.state.priceSectionData.discount);
      updateInvoiceBody.append("data[currency]", this.state.currency);
      updateInvoiceBody.append("data[discount_type]", this.state.selectCurrency);
      updateInvoiceBody.append("data[invoice_deposits_attributes][amount]",this.state.collectedPaymentForm.amount);
      updateInvoiceBody.append("data[invoice_deposits_attributes][transaction_date]", this.state.collectedPaymentForm.transaction_date);
      updateInvoiceBody.append("data[invoice_deposits_attributes][details]",this.state.collectedPaymentForm.details); 
      this.state.createdInvoiceData?.attributes?.invoice_deposits?.map((item:any)=>{
        updateInvoiceBody.append("data[invoice_deposits_attributes][id]",item.id);
      })
      if(this.state.selectedJob){
        updateInvoiceBody.append("data[job_id]", JSON.stringify(parseInt(this.state.selectedJob.match(/(\d+)/)[0], 10)));
      }
      if(typeof this.state.collectedPaymentForm.payment_method==='string'){
        updateInvoiceBody.append("data[invoice_deposits_attributes][payment_method]", this.state.collectedPaymentForm.payment_method);
      }
      if(this.state.badDebtStatus===true){
        updateInvoiceBody.append("data[status]",'bad_debt');
      }
      {this.updateProductService(updateInvoiceBody)}
      this.state.internalAttachments.forEach(function (value:any, index:any) {
        updateInvoiceBody.append(`data[files][]`, value);
      });
      updateInvoiceBody.append("data[file_id][]", this.state.removedInternalAttachment);
      updateInvoiceBody.append("data[total]", this.state.productServices&&this.state.productServices[0]?.totalCost);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateInvoiceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createInvoiceAPIEndPoint}/${this.state?.createdInvoiceData?.id}?lang=${this.state.selectLan}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), updateInvoiceBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.paymentApiMethodtype
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  invoicePayment = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
        "token": token
    };
    let invoicePaymentBody = new FormData();
    invoicePaymentBody.append("data[invoice_deposit_attributes][amount]", this.state.collectedPaymentForm.amount);
    invoicePaymentBody.append("data[invoice_deposit_attributes][payment_method]", this.state.collectedPaymentForm.payment_method);
    invoicePaymentBody.append("data[invoice_deposit_attributes][transaction_date]", this.state.collectedPaymentForm.transaction_date);
    invoicePaymentBody.append("data[invoice_deposit_attributes][details]",this.state.collectedPaymentForm.details);
    invoicePaymentBody.append("data[invoice_deposit_attributes][pay_balance]",this.state.collectedPaymentForm.pay_balance);
    invoicePaymentBody.append("data[invoice_deposit_attributes][collected]",this.state.collectedPaymentForm.collected);
    this.state.createdInvoiceData.attributes?.invoice_deposits?.map((item:any)=>{
      invoicePaymentBody.append("data[invoice_deposit_attributes][id]",item.id);
    })
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.invoicePaymentApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createInvoiceAPIEndPoint}/${this.state.createdInvoiceData.id}?lang=${this.state.selectLan}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),invoicePaymentBody
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.paymentApiMethodtype
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteInvoice = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    let deleteInvoiceBody = new FormData();
      deleteInvoiceBody.append("data[status]",'archived'); 
    const deleteInvoiceApiCall = apiCall({
      header: header,
      httpBody: deleteInvoiceBody,
      url: `${configJSON.createInvoiceAPIEndPoint}/${this.state?.createdInvoiceData?.id}?lang=${this.state.selectLan}`,
      httpMethod: "PATCH",
    });
    this.deleteInvoiceApiCallId = deleteInvoiceApiCall.messageId;
    runEngine.sendMessage(deleteInvoiceApiCall.id, deleteInvoiceApiCall);
  }
  getJobReference = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const languageSelected = localStorage.getItem("lang");
    const { startDate, endDate } = this.state.selectedDate;
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.jobEndPoint
    let getJobUrl
    if (this.state.searchJobs && this.state.sortColumn) {
      getJobUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchJobs}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${languageSelected}&start_date=${moment(startDate).format('dd-mm-yyyy')}&end_date=${endDate}&date_filter=${this.state.selectedStatusForAPI}`
    } else if (this.state.searchJobs) {
      getJobUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchJobs}&lang=${languageSelected}&start_date=${moment(startDate).format('dd-mm-yyyy')}&end_date=${endDate}&date_filter=${this.state.selectedStatusForAPI}`
    } else if (this.state.sortColumn) {
      getJobUrl = `${apiEndPoint}?status=${this.state.status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${languageSelected}&start_date=${moment(startDate).format('dd-mm-yyyy')}&end_date=${endDate}&date_filter=${this.state.selectedStatusForAPI}`
    } else {
      getJobUrl = `${apiEndPoint}?status=${this.state.status}&lang=${languageSelected}&start_date=${moment(startDate).format('dd-mm-yyyy')}&end_date=${endDate}&date_filter=${this.state.selectedStatusForAPI}`
    }
    this.setState({ invoicePdfLoading: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getJobUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSelectJobData = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const selectLanguage = localStorage.getItem("lang");
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.jobEndPoint
    this.setState({ invoicePdfLoading: true })
    const getSelectJobApi = apiCall({
      header: header,
      httpBody: null,
      url: `${apiEndPoint}/${this.state.JobId}?lang=${selectLanguage}`,
      httpMethod: "GET",
    });
    this.getSelectJobApiCallId = getSelectJobApi.messageId;
    runEngine.sendMessage(getSelectJobApi.id, getSelectJobApi);
  }
  handlePaymentDueChange = (date: any) => {
    this.setState({ invoiceForm: { ...this.state.invoiceForm, payment_due: date } })


  }
  handleBadDept = () => {
    this.setState({ badDebtStatus: true }, () => {
      this.updateInvoice()
    })
  }
  handleIssueDate = (date: any) => {
    const dateValidations = this.state.invoiceForm.issue_date === this.state.invoiceForm.payment_due || this.state.invoiceForm.issue_date <= this.state.invoiceForm.payment_due
    if (dateValidations) {
      this.setState({ invoiceForm: { ...this.state.invoiceForm, payment_due: date, issue_date: date } })

    } else {
      this.setState({ invoiceForm: { ...this.state.invoiceForm, issue_date: date } })

    }
  }
  handleBackIconV1 = () => {
    this.props.navigation.history.goBack();
  }
  handleMarkAsCollectionPaymentSave = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token   
    }; 
 
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsCompletedApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.markasCompletedApiEndPoint}${this.props.location?.state?.invoiceId}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.markAsCompletedAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}




    // Customizable Area End
}
