import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { jpgExtensionIcon, pngExtensionIcon } from "../../RequestManagement/src/assets";
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    location: any;
    openToastHandler: any;
    t:(key?: string, options?: object) => string|unknown;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: {
        first_name: any;
        last_name: any
        phone_number: any
    },
    bussiness: {
        company_name: any;
        compamy_size: any;
        industy_type: any;
    },
    referbefore: any;
    getSteps: any[];
    activeStep: number;
    completed: boolean;
    activeLabel: string;
    isFinishStep: boolean;
    isProfileError: any;
    isBussinessError: any;
    bussinessLogo: any;
    dragStatus: boolean;
    companyLogo: any;
    progress: any;
    fileUploaded: boolean
    converted_base_url: string
    extensionIcons: any;
    extensionAllowed: any;
    countryCode: any;
    countryCodeError:string;
    countryCodeInputValue: string
    countryInputValue: string
    countryName: any;
    signUpLoader:boolean
    showOtherField :boolean
    othertext:string
    otherField:boolean
    otherFielsText:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UserprofileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdSignup: string = "";
    countrylistApiCallId: string = "";
    industriesListApiCallId: string = "";
    profileAnswersListApiCallId: string = "";
    countryCodelistApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            profile: {
                first_name: "",
                last_name: "",
                phone_number: "",
            },
            bussiness: {
                company_name: "",
                compamy_size: "",
                industy_type: ""
            },
            referbefore: "",
            getSteps: [
                {
                    stepLabel: 'Profile',

                },
                {
                    stepLabel: 'Bussiness',

                },
                {
                    stepLabel: "refer",

                }],
            activeStep: 0,
            completed: false,
            activeLabel: "Profile",
            isFinishStep: false,
            isProfileError: {},
            isBussinessError: {},
            bussinessLogo: null,
            dragStatus: false,
            companyLogo: null,
            progress: 0,
            fileUploaded: false,
            converted_base_url: "",
            extensionIcons: {
                jpg: jpgExtensionIcon,
                png: pngExtensionIcon,
                jpeg: jpgExtensionIcon,
            },
            extensionAllowed: ["jpg", "png"],
            countryCode: "",
            countryCodeError:"",
            countryCodeInputValue: "",
            countryInputValue: "",
            countryName: "",
            signUpLoader:false,
            showOtherField:false,
            othertext:"",
            otherField: false,
            otherFielsText: ""

        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    // Customizable Area Start

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.apiCallIdSignup) {
                this.handleSignUpApiResponce(responseJson)
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleSignUpApiResponce = (responseJson: any) => {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
            this.setState({signUpLoader:true})
            this.props.openToastHandler("Sign up successful", "success",)
            localStorage.setItem("otpCount",JSON.stringify(0))
            localStorage.setItem("comingFrom","Signup")
            localStorage.setItem("OtpToken", responseJson.meta.token)
            localStorage.setItem("email",responseJson.data?.attributes?.email)
            localStorage.removeItem("terms")
            localStorage.removeItem("cameFrom")
            setTimeout(this.handleNavigationSignup, 500)
        }
    }

    handleNavigationSignup = ()=>{
        this.props.navigation.navigate("OtpPassword")
      }

    handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        
        // Update the profile state with the new value
        this.setState((prevState) => ({
            profile: {
                ...prevState.profile,
                [name]: value,
            },

            isProfileError: {
                ...prevState.isProfileError,
                [name]: '',
            }
        }));
    };
    
    countryInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        this.setState({ countryInputValue: value })

    }
    countryCodeInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        this.setState({ countryCodeInputValue: value })

    }

    handleCountryChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
        this.setState({ countryName: newValue });
        
        // Clear the country name error
        this.setState((prevState) => ({
            isProfileError: {
                ...prevState.isProfileError,
                countryName: '',
            }
        }));
    };
  
    handleCountryCodeChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
        this.setState({ countryCode: newValue });
    
        // Clear the country code error
        this.setState((prevState) => ({
            isProfileError: {
                ...prevState.isProfileError,
                countryCode: '',
            }
        }));
    };    
    
    validateProfileForm = (values: any, countryName: string, countryCode: string) => {
        let error: any = {};
        this.validateFirstName(values.first_name, error);
        this.validateLastName(values.last_name, error);
        this.validatePhoneNumber(values.phone_number, error)
const {t}=this.props
        if (!countryCode) {
            error.countryCode = t('profileSetup.validations.phoneNumber.code')
        }
        if (!countryName) {
            error.countryName = t('profileSetup.validations.country')
        }
        return error;
    }
    validateFirstName = (values: any, error: any) => {
        const {t}:any=this.props
        if (!values) {
            error.first_name = t('profileSetup.validations.firstName.required');

        } else if (!configJSON.charactersregex.test(values)) {
            error.first_name = t('profileSetup.validations.firstName.character');

        } else if ((values).length > 24) {
            error.first_name =t('profileSetup.validations.firstName.length');
        }
    }
    validateLastName = (values: any, error: any) => {
        const {t}=this.props
        if (!values) {
            error.last_name = t('profileSetup.validations.lastName.required');
        } else if (!configJSON.charactersregex.test(values)) {
            error.last_name = t('profileSetup.validations.lastName.character');

        } else if ((values).length > 24) {
            error.last_name = t('profileSetup.validations.lastName.length');
        }
    }
    validatePhoneNumber = (phoneNumber: string, error?: any) => {
        const { t } = this.props;
        let errors: any = {};
        const numRegex = /^[6-9][0-9]{6,14}$/;
        if (!phoneNumber) {
            errors.phone_number = t('profileSetup.validations.phoneNumber.required'); 
        } else if (!numRegex.test(phoneNumber)) {
            errors.phone_number = t('profileSetup.validations.phoneNumber.numbers');
        }
        return errors;
    };

    handleLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errors = this.validateProfileForm(this.state.profile, this.state.countryName, this.state.countryCode);
        const phoneErrors = this.validatePhoneNumber(this.state.profile.phone_number);
        const finalErrors = { ...errors, ...phoneErrors };
        if (Object.keys(finalErrors).length > 0) {
            this.setState({ isProfileError: finalErrors });
            return;
        }
        this.setState({ isProfileError: {} }, () => {
            this.handleNext();
        });
    };    

    handleLogo = (event: any) => {
        this.setState({
            bussinessLogo: event.target.files
        });
    }
    handleBussinessChange = (event: any) => {
        this.setState({
            bussiness: { ...this.state.bussiness, [event.target.name]: event.target.value },
            otherField:event.target.value === 'Others'
        });
    }

    otherOnChange = (event:ChangeEvent<HTMLInputElement>) => { 
        this.setState({
            otherFielsText: event.target.value
        })
                                                                                                               
    }
    validatebussinessForm = (values: any) => {
        let error: any = {};
        const {t}:any=this.props
        this.validateCompanyName(values.company_name, error)
        if (!values.compamy_size) {

            error.compamy_size = t('Bussiness.Validations.size');
        }
        if (!values.industy_type) {
            error.industy_type = t('Bussiness.Validations.industry');
        }
        return error;
    }
    validateCompanyName = (company_name: string, error: any) => {
        const {t}=this.props
        if (!company_name) {
            error.company_name =t('Bussiness.Validations.company_name.required');
            
        } else if ((company_name).length > 40) {
            error.company_name = t('Bussiness.Validations.company_name.length');
        }
    }
    handlebussinessForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState({ isBussinessError: this.validatebussinessForm(this.state.bussiness) },

            () => {
                if (Object.keys(this.state.isBussinessError).length === 0) {
                    this.handleNext();
                }
            }
        );
    }
    handleChangeReferBefore = (event: any) => {
        const value = event.target.value as string;
        this.setState({
          referbefore: value,
          showOtherField: value === 'Other (please specify)',
        });
    }

    handleOther = (event: ChangeEvent<HTMLInputElement>) => {
        
        this.setState({
            othertext: event.target.value
        })

    }
    handleNext = () => {
        if (this.state.getSteps.length - 1 === this.state.activeStep) {
            this.setState({
                isFinishStep: true,
                activeStep: this.state.activeStep + 1
            })
        } else {
            this.setState({ activeStep: this.state.activeStep + 1, activeLabel: this.state.getSteps[this.state.activeStep + 1].stepLabel.toLowerCase() });
        }
    };
    handleBackIcon = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    };

    handleBack = (e: any) => {
        const disabled = e?.target?.className?.includes("inActive")

        if (!disabled) {
            this.setState({ activeStep: this.state.activeStep - 1 })
        }
    }
    progressbarclass = (index: any, activeStep: any, classes: any) => {
        if (index < activeStep) {
            return classes.finish
        }
        else if (index == activeStep) {
            return classes.active
        } else {
            return classes.inActive
        }
    }

    handleSelectFiles = (event: any) => {
        const file = event.target.files && event.target.files[0];
        this.setState({
            companyLogo: file,
        });
        if (file) {
            const reader = new FileReader();
            reader.onloadstart = this.readOnLoadStart;
            reader.onprogress = this.readOnProgress;
            reader.onload = this.readOnLoad;
            reader.readAsDataURL(file);
        }
    }

    handleOnDragOver = (event: any) => {
        event.preventDefault();
    }

    handleOnDrop = (event: any) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        this.setState({ companyLogo: file });
        if (file) {
            const reader = new FileReader();
            reader.onload = this.readOnLoad;
            reader.onloadstart = this.readOnLoadStart;
            reader.onprogress = this.readOnProgress;
            reader.readAsDataURL(file);
        }
    }
    handleDeleteUploadedLogo = () => {
        this.setState({
            companyLogo: null,
            completed: false,
            progress: 0
        });
    }
    handleProgressEvent = (files: any) => {
        if (files) {
            const reader = new FileReader();
            reader.onloadstart = this.readOnLoadStart;
            reader.onprogress = this.readOnProgress;
            reader.onload = this.readOnLoad;
            reader.readAsDataURL(files);
        }
    }
    readOnLoadStart = () => {
        this.setState({ progress: 0 });
    };

    readOnProgress = (event: any) => {
        if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;

            this.setState({ progress: percentComplete });
        }
    };
    readOnLoadCallbackTimeout = () => {
        this.setState({ completed: true });
    };

    readOnLoadCallback = () => {
        if (this.state.companyLogo) {
            setTimeout(this.readOnLoadCallbackTimeout, 500);
        }
    };
    readOnLoad = (event: any) => {
        if (event.target.result === "image/jpeg") {
            this.setState({
                converted_base_url: event.target.result.replace("data:image/jpeg;base64,", ""),
                progress: 100
            }, () => {
                this.readOnLoadCallback()
            })
        } else if (event.target.result === "image/jpg") {
            this.setState({
                converted_base_url: event.target.result.replace("data:image/jpg;base64,"),
                progress: 100
            }, () => {
                this.readOnLoadCallback()
            })
        } else {
            this.setState({
                converted_base_url: 
                 String(event.target.result).substring('data:image/png;base64,'.length) ,
                progress: 100
            }, () => {
                this.readOnLoadCallback()
            });
        }
    };
    //istanbul ignore next
    SignUp = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };
        this.setState({ signUpLoader: true })
        const bussinessPart = this.state.bussiness
        const profilepart = this.state.profile
        const user = this.props.location.state.userDetails
        const data = {

            "data": {
                "type": "email_account",
                "attributes": {
                    "first_name": profilepart.first_name.trim(),
                    "last_name": profilepart.last_name.trim(),
                    "email": user.signUpEmail.trim(),
                    "password": user.signUpPassword,
                    "country_code": this.state.countryCode,
                    "phone_number": profilepart.phone_number,
                    "full_phone_number": `${this.state.countryCode} ${profilepart.phone_number}`,
                    "country_name": this.state.countryName,
                    "industrie_id": bussinessPart.industy_type && this.state.otherFielsText?this.state.otherFielsText:bussinessPart.industy_type,
                    "company_size": bussinessPart.compamy_size,
                    "company_name": bussinessPart.company_name.trim(),
                    "profile_question_id": (this.state.referbefore && this.state.othertext)?this.state.othertext:this.state.referbefore,
                    "terms":localStorage.getItem("terms")==="true"
                },
                "company_logo": this.state.converted_base_url
            }

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCallIdSignup = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.signup
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

}
// Customizable Area End