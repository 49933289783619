import { runEngine } from "../../framework/src/RunEngine";
import { checkLoggedInUserDashboard } from "../../blocks/dashboard/src/utility.web";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { getStorageData } from "../../framework/src/Utilities";
import i18n from 'i18next';
import { JobsData } from "../../blocks/TimesheetManagement/src/TimeSheetWeeklyController";
interface CustomI18nType {
  changeLanguage(language: string): void;
}
interface ValueObject{
  title: string;
  start: Date;
  end: Date;
  attributes: {
    start_date: Date;
    start_time: Date;
    end_date: string;
    end_time: string;
    "admin_address": [],
    'company_logo': null,
    'created_at': Date,
    'currency': string,
    'customer_id': number,
    'customer_name': string,
    'discount': string,
    'files': null,
    'invoice_deposits': [],
    'invoice_id': string,
    'issue_date': string,
    'job_id': string,
    'job_title': string,
    'newEngStatus': string,
    'notes': string,
    'payment_due': string,
    'pdf_url': {},
    'products': [],
    'quote_id': string,
    'site_id': string,
    'status': string
    'sub_total': number,
    'tax': number,
    'total': number
    'tax_name': string,
    'title': string,
    'updated_at': string,
    'id': string,
    'type': string
  },
  id: string,
  type: string
}
export interface INavigateTo {
  id?: string;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
interface AttributesArray {
  data: ValueObject[]
}
declare const window: { document: { querySelector: (arg0: string) => { (): any; new(): any; setAttribute: { (arg0: string, arg1: string): void; new(): any; }; }; }; };

interface IApiCall {
  header: any;
  httpBody: any;
  url: string;
  httpMethod: string;
}
export function getHeaders(obj: Record<string, string>): Headers {
  const headers: any = {};
  headers["Content-Type"] = "application/json";
  for (let key in obj) {
    headers[key] = obj[key];
  }
  return headers;
}
export function apiCall({ header, httpBody, url, httpMethod }: IApiCall) {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    httpMethod
  );
  return requestMessage;
}
export function checkTokenExpired(responseJson:any,openToastHandler:any,history:any,props?:any){
   
   if (
    responseJson?.errors[0].token == "Token has Expired" ||
    responseJson?.errors[0].token == "Invalid token"
  ) { 
    openToastHandler(responseJson?.errors[0].token, "error");
    localStorage.clear()
    setTimeout(async() => {
      const isEmployee = await checkLoggedInUserDashboard()
      if (history) {
        history.push(isEmployee ? "/Employee/Login" :"/Login");
      }
      else {
        props.navigation.navigate(isEmployee ? "EmployeeLogin": 'Login')
      }
   }, 1000);
  }  
}

export const filterListData=(data:Record<string,AttributesArray> | JobsData)=> {
  Object.entries(data)?.map(([key, value]) => {
    value.data.map((it:ValueObject)=>{
      it.attributes.newEngStatus = key;

      (it.attributes?.products as any)?.data?.length > 0 
      && (it.attributes?.products as any)?.data?.forEach((item1:any) => {
        if (item1.attributes?.service_id) {
          item1.attributes.service_name = item1.attributes.product_name
        }
      })
    })
  });

  return data
}
export function customerFilterListData(data:any) {
  let array:any = [];
  data.data.map((item: any) => {
    item?.attributes.products.data?.map((item1: any) => {
      return array.push({ ...item, product: item1 });
    });
  });
  return {'data':array}
}
export const debounce = (fn: any, ms = 700) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
export const dateMinusOne=(date:any)=>{
  let endDate = new Date(date);
  endDate.setDate(endDate.getDate() - 1);
  return endDate
}
export const checkEventOrNot=({eventDate,eventsArray}:any)=>{
  const newArray= eventsArray.map((element:any) => {
     return {...element, startNew:dateMinusOne(element.start)}
   });
   if (newArray.find((item: any) => new Date(eventDate).getTime() <= new Date(item.end).getTime() && new Date(eventDate).getTime() >= new Date(item.startNew).getTime())) {
     return true
   }
 }
 export const findEventObject=({eventDate,eventsArray}:any)=>{
  const newArray= eventsArray.map((element:any) => {
     return {...element, startNew:dateMinusOne(element.start)}
   });
   if (newArray.find((item: any) => new Date(eventDate).getTime() <= new Date(item.end).getTime() && new Date(eventDate).getTime() >= new Date(item.startNew).getTime())) {
     return newArray.find((item: any) => new Date(eventDate).getTime() <= new Date(item.end).getTime() && new Date(eventDate).getTime() >= new Date(item.startNew).getTime())
   }
 }
export const checkBoxShadowEventOrNot=({eventDate,eventsArray}:any)=>{
  if (eventsArray.find((item: any) => moment(new Date(eventDate)).format("YYYY-MM-DD").toString() === moment(new Date(item.start)).format("YYYY-MM-DD").toString() )){
    return true
  }
}
export const goBack=(navigation:any)=>{
  navigation.goBack()

}
export function navigateTo({
  id,
  props,
  screenName,
  raiseMessage,
}: INavigateTo) {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
  id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
  raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  runEngine.sendMessage(message.id, message);
}

export const testingMockApi = (instance: any, apiCallId: string, apiData: object) => {
  const msgValidationAPI = new Message(getName(MessageEnum.RestAPIResponceMessage));
  msgValidationAPI.addData(getName(MessageEnum.RestAPIResponceDataMessage), msgValidationAPI.messageId);
  msgValidationAPI.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), apiData);
  instance[apiCallId] = msgValidationAPI.messageId
  runEngine.sendMessage("Unit Test", msgValidationAPI);
  }

  export const languageSelect=async(value:string)=>{
    const languageSelected = await getStorageData("lang")
      if (languageSelected === 'ar') {
        window.document.querySelector("html").setAttribute("dir", "rtl");
      } else if (languageSelected === "en") {
        window.document.querySelector("html").setAttribute("dir", "ltr");
      }
      (i18n as CustomI18nType).changeLanguage(value);
      // i18n.changeLanguage(value);
  }
