import React, { useState } from "react";
import {
  Box,
  Typography,
  Menu,
  InputBase
} from '@material-ui/core';
import {
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { clockIcon } from "../../blocks/Settings/src/assets";
import { CONSTANTS } from "./theme";
const selectedLanguage = localStorage.getItem("lang");
function generateMockTimeArray(startingHour: number, languageSelected: string | boolean) {
  const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
  return Array.from({ length: 48 }, (_, index) => {
    const hour = (Math.floor(index / 4) + startingHour) % 12 + 1;
    const minute = (index % 4) * 15 || "00";
    const formattedHour = languageSelected === "ar"
      ? hour.toString().padStart(2, "0").split("").map(digit => arabicNumerals[parseInt(digit)]).join("")
      : hour.toString().padStart(2, "0");
    const formattedMinute = languageSelected === "ar"
      ? minute.toString().padStart(2, "0").split("").map(digit => arabicNumerals[parseInt(digit)]).join("")
      : minute.toString().padStart(2, "0");

    return {
      id: index + 1,
      time: `${formattedHour}:${formattedMinute}`,
    };
  });
}

function generateMock24HourTimeArray(languageSelected: string | boolean) {
  const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
  return Array.from({ length: 96 }, (_, index) => {
    const hour = (Math.floor(index / 4) + 7) % 24;
    const minute = (index % 4) * 15 || "00";
    const formattedHour = languageSelected === "ar"
      ? hour.toString().padStart(2, "0").split("").map(digit => arabicNumerals[parseInt(digit)]).join("")
      : hour.toString().padStart(2, "0");
    const formattedMinute = languageSelected === "ar"
      ? minute.toString().padStart(2, "0").split("").map(digit => arabicNumerals[parseInt(digit)]).join("")
      : minute.toString().padStart(2, "0");

    return {
      id: index + 1,
      time: `${formattedHour}:${formattedMinute}`,
    };
  });
}

const mock12HourTimeFormat = generateMockTimeArray(6, selectedLanguage === "ar" && "ar");
const mock1212PmHourTimeFormat = generateMockTimeArray(11, selectedLanguage === "ar" && "ar");
const mock24HourTimeFormat = generateMock24HourTimeArray(selectedLanguage === "ar" && "ar");

const TimePicker = (props: any) => {
  const [amPm, setAmPm] = useState(props.languageSelected === "ar" ? props.t("settings.am") : "AM");
  const [timeValue, setTimeValue] = useState(props?.timeFormat !== "24-Hour Clock" ? props?.time : props?.time.split(" ")[0]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    if(props?.time) {
      setTimeValue(props?.time);
      const initialAmPm = props.languageSelected === "ar" ? props.t("settings.am") : "AM";
      setAmPm(initialAmPm);
      setAnchorEl(null);
    }

    if(props?.timeFormat === "24-Hour Clock") {
      setTimeValue(props?.time.split(" ")[0]);
      setAmPm("");
    }
  }, [props?.time, props.languageSelected, props.t]);

  const handleChangeAmPm = () => {
    setAmPm(amPm === props.t("settings.am") ? props.t("settings.pm") : props.t("settings.am"));
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if(!props.isEmployee) {
      setAnchorEl(event.currentTarget);
    }

    if (languageSelected === "ar") {
      if (timeValue.includes("PM") || timeValue.includes("مساء")) {
        setAmPm("مساء");
      } else if (timeValue.includes("AM") || timeValue.includes("صباخا")) {
        setAmPm("صباخا");
      }
    } else {
      if (timeValue.includes("PM")) {
        setAmPm("PM");
      } else {
        setAmPm("AM");
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectTimeValue = (item: { id: number, time: string }) => {
    const selectedTimeWithAmPm = props.timeFormat === "24-Hour Clock" ? item.time : `${item.time} ${amPm}`;
    setTimeValue(selectedTimeWithAmPm);

    if (props.onChange) {
      props.onChange(selectedTimeWithAmPm);
    }
  }

  const { t, languageSelected } = props;
  const mockAmPm = [
    { id: 1, name: t("settings.am") },
    { id: 2, name: t("settings.pm") }
  ]

  const translateToArabicTime = (englishTime: string, languageSelected: string) => {
    if (languageSelected !== "ar") {
      return englishTime;
    }

    const [hour, minute, period] = englishTime.split(/[ :]/);
    let translatedHour = String(hour);
    let translatedMinute = String(minute);
    
    const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
    const englishNumerals = "0123456789";
    
    for (let i = 0; i < englishNumerals.length; i++) {
      translatedHour = translatedHour.replace(new RegExp(englishNumerals[i], 'g'), arabicNumerals[i]);
      translatedMinute = translatedMinute.replace(new RegExp(englishNumerals[i], 'g'), arabicNumerals[i]);
    }
    
    let periodIndicator = period;
    if (period === "PM") {
      periodIndicator = "مساء";
    } else if (period === "AM") {
      periodIndicator = "صباخا";
    }

    if (period === "PM" && parseInt(translatedHour, 10) < 12) {
      translatedHour = String(parseInt(translatedHour, 10) + 12);
    }

    translatedHour = translatedHour.padStart(2, '٠');
    translatedMinute = translatedMinute.padStart(2, '٠');

    return `${translatedHour}:${translatedMinute} ${props.timeFormat !== "24-Hour Clock" ? periodIndicator : ""}`;
  };

  const { classes } = props;
  return (
    <Box style={webStyle.positionStyle}>
      <Box style={languageSelected === "ar" ? {...webStyle.container, border: "none"} : webStyle.container}  onClick={handleClick}>
        <Box style={webStyle.clockIconContainer} className={selectedLanguage === 'ar' ? `${classes.transform} ${classes.clockIconContainer}` : classes.clockIconContainer}>
          <img style={webStyle.clockIcon} className={selectedLanguage === 'ar' ? classes.transform : ''} src={clockIcon} alt="" />
        </Box>
        <StyledInputBase
          style={webStyle.inputBaseStyle}
          className={selectedLanguage === 'ar' ? `${classes.transform} ${classes.inputBaseStyle}` : classes.inputBaseStyle}
          defaultValue={translateToArabicTime(props.timeFormat !== "24-Hour Clock" ? timeValue : timeValue.split(" ")[0], languageSelected)}
          value={translateToArabicTime(props.timeFormat !== "24-Hour Clock" ? timeValue : timeValue.split(" ")[0], languageSelected)}
          inputProps={{
            readOnly: true,
            onClick: (event) => event.preventDefault(),
          }}
          languageSelected={props.languageSelected}
        />
      </Box>
      
      <StyleMenu
        id="customized-menu"
        keepMounted
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}>
        <Box style={webStyle.timeValuesContainer}>
          {props.timeFormat !== "24-Hour Clock" &&
            <Box style={webStyle.arrowsContainer}>
              {mockAmPm.map((item) => (
                <Box
                  style={
                    amPm === item.name ?
                    { ...webStyle.amPmContainer, border: `1px solid ${CONSTANTS.color.darkBlue}` } :
                    webStyle.amPmContainer
                  }
                  onClick={handleChangeAmPm}
                  key={item.id}
                >
                  <Typography
                    variant="caption"
                    style={
                      amPm === item.name ?
                      { ...webStyle.amPmStyle, color: CONSTANTS.color.white, backgroundColor: CONSTANTS.color.darkBlue } :
                      webStyle.amPmStyle
                    }
                  >
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          }
          <Box
            style={
              props.timeFormat === "24-Hour Clock" ?
              { ...webStyle.timeValues, height: "100%" } :
              webStyle.timeValues
            }
          >
            {(props.timeFormat === "24-Hour Clock" ?
              mock24HourTimeFormat :
              (amPm === props.t("settings.am") ? mock12HourTimeFormat : mock1212PmHourTimeFormat)).map((item) => {
              return (
                <Box
                 className="time-picker-box"
                  key={item.id}
                  style={{
                    ...webStyle.timeValueItemContainer,
                    ...(translateToArabicTime(timeValue, languageSelected).split(" ")[0] === item.time && { backgroundColor: CONSTANTS.color.darkBlue }),
                  }}
                  onClick={() => handleSelectTimeValue(item)}
                >
                  <Typography
                    style={
                      translateToArabicTime(timeValue, languageSelected).split(" ")[0] === item.time ?
                      { ...webStyle.timeValueItem, color: CONSTANTS.color.white } :
                      webStyle.timeValueItem
                    }
                    variant="body2"
                  >
                    {item.time}
                  </Typography>
                  {props.timeFormat !== "24-Hour Clock" &&
                    <Typography
                      style={
                        translateToArabicTime(timeValue, languageSelected).split(" ")[0] === item.time ?
                        { ...webStyle.timeValueItem, color: CONSTANTS.color.white } :
                        webStyle.timeValueItem
                      }
                      variant="body2"
                    >
                      {amPm}
                    </Typography>
                  }
                </Box>
              );
            })}
          </Box>
        </Box>
      </StyleMenu>
    </Box>
  );
}

export const styles = (theme: Theme) => ({
  inputBaseStyle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important",
      paddingLeft: "0.5rem !important",
    },
    "& .MuiInputBase-input": {
      transform: selectedLanguage==='ar'? 'rotate(180deg)':"",
      textAlignLast:"auto"
  }
  },
  transform:{
    transform: 'rotate(180deg)'
  },
  clockIconContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "58px !important"
    },
    [theme.breakpoints.down(370)]: {
      maxWidth: "52px !important"
    },
  }
});

const webStyle = {
  positionStyle: {
    position:'relative' as const
  },
  container: {
    display: "flex",
    borderRadius: "10px",
    border: "1px solid rgba(0,0,0,0.1)",
    cursor: "pointer",
  },
  clockIconContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "30px",
    height: "56px",
    borderRadius: "10px 0 0 10px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    backgroundColor: "rgba(0, 0, 0, 0.02)",
  },
  inputBaseStyle: {
    width: "100%",
    fontSize: "18px",
    color: CONSTANTS.color.midGrey,
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "0 10px 10px 0",
    paddingLeft: "1rem",
    cursor: "pointer",
  },
  clockIcon: {
    width: "24px",
    height: "23px"
  },
  arrows: {
    width: "7px",
    height: "11px",
    cursor: "pointer"
  },
  timeValuesContainer: {
    marginTop: "1rem",
    width: "100%",
    maxWidth: "367px",
    borderRadius: "12px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 3px 14px rgba(0,0,0,0.05)",
    zIndex: 10
  },
  arrowsContainer: {
    padding: "1rem 2rem 1rem 2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    position: "sticky" as const,
    top: 0,
    zIndex: 100,
    backgroundColor: CONSTANTS.color.white,
  },
  amPmContainer: {
    width: "93px",
    height: "40px",
    borderRadius: "8px",
    border: `1px solid ${CONSTANTS.color.ashGrey}`,
    cursor: "pointer"
  },
  amPmStyle: {
    color: CONSTANTS.color.darkBlue,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderRadius:"6px"
  },
  timeValues: {
    overflow: "scroll" as const,
    height: "85%",
  },
  timeValueItemContainer: {
    display: "flex",
    gap: "1.5rem",
    padding: "1rem 2rem",
    cursor: "pointer",
    justifyContent: "center"
  },
  timeValueItem: {
    color: CONSTANTS.color.jetBlack,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
}

interface StyledProps {
  languageSelected: string;
}

const StyledInputBase = withStyles({
  root: {
    "& .MuiInputBase-input": {
      transform: (props: StyledProps) => props.languageSelected === "ar" && "rotate(180deg)"
    }
  }
})(InputBase);

const StyleMenu = withStyles({
  paper: {
    borderRadius: "5px",
    background: CONSTANTS.color.white,
    width:'200px',
    height:'300px',
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
})((props:any) => (
  <Menu
    elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default withStyles(styles)(TimePicker);
