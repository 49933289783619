//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Container,
  Typography,
  InputBase,
  TextField,
  MenuItem,
  InputAdornment,
  TableContainer,
  ClickAwayListener,
  Divider,
  Hidden,
  Checkbox
} from "@material-ui/core";
import {
  withStyles,
  Theme
} from "@material-ui/core/styles";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import {
  dropDownSelectIcon,
  threeDotsIcon,
} from "../../RequestManagement/src/assets";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { StyledGrid } from "../../RequestManagement/src/Requests.web"
import {backIconMobile} from "../../CustomisableUserProfiles/src/assets"
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme"
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import { withRouter } from "react-router-dom";
import moment from "moment";
const selectedLanguage =  localStorage.getItem("lang");
// Customizable Area End

import InvoicesController, { Props, configJSON } from "./InvoicesController.web";
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
import { deleteIcon, searchIcon } from "../../CustomisableUserProfiles/src/assets";
import DeleteModel from "./DeleteModal.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { withTranslation } from "react-i18next";
// Customizable Area Start
export const StyledTextFieldRequest = withStyles((theme:Theme)=>({
  root: {
    "& .MuiInputBase-root": {
      height: "56px",
      borderRadius: "10px",
      color: CONSTANTS.color.grey,
      fontSize: "1.1667rem",
      fontWeight: 400,
      lineHeight: "16px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 14px",
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-adornedEnd":{
      paddingRight: (props) => props.languageSelected === 'ar' && "0px",
    },
    "& .MuiInputAdornment-positionEnd":{
      marginLeft: (props) => props.languageSelected === 'ar' && "13px"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1c18af",
      borderWidth: "2px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1c18af",
      borderWidth: "2px"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.1rem",
      marginTop: "-5px",
    }
  }
}))(TextField);

export const StyledTableContainerRequest = withStyles({
  root: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  }
})(TableContainer);
// Customizable Area End

export class Invoices extends InvoicesController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  hardDeleteStatus() {
    const { t } = this.props;
    return <>
      {this.state.hardDeleteStatus &&
        <DeleteModel
          deleteType={`${t('invoice.permanentlyDeleteInvoice')}`}
          subType={`${t('invoice.permanentlyDeleteInvoiceDes')}`}
          handleArchivedDialogue={this.handleDeleteCloseDailog}
          handleCloseDeleteDialog={this.handleDeleteCloseDailog}
          deleteAPI={this.deleteRequest}
        />}
    </>
  }
  statusModalRequests = ({classes,InvoiceStatusData}) => {
    return (
      <ClickAwayListener onClickAway={this.handleStatusInvoiceModalClose}>
        <Typography>
          <Box style={webStyle.statusModalContainerInvoice}>
            <Box style={webStyle.statusModalRequest}>
              {InvoiceStatusData.map((item) => (
                <Box key={item.id} display={"flex"} mb={1} gridGap={"0.5rem"}>
                  <Checkbox
                    data-test-id="handleSelectStatus"
                    checked={this.state.checkedItems.some((subItem) => item.statusName === subItem)}
                    onChange={this.handleSelectStatus.bind(this, item, InvoiceStatusData)}
                    style={{ padding: 0, color: "#1c18af" }} />
                  <Typography className={classes.menuItemStyle} variant="body1">
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }

  toggleModalRequests = (classes) => {
    return (
      <ClickAwayListener onClickAway={this.handleToggleRequestModalClose}>
        <Typography>
          <Box style={webStyle.toggleInvoiceModalContainer}>
            <Box style={webStyle.toggleModalInvoiceStyle}>
              {this.state.requestToggleData && this.state.requestToggleData.map((item: any) => (
                <Box style={webStyle.requestToggleIconsContainer} key={item.id}>
                  <StyledCheckbox
                    data-test-id="handleToggleColumnRequest"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={item.hidden}
                    onChange={() => this.handleToggleColumnRequest(item.id)}
                  />
                  <MenuItem
                    value={item.title}
                    disableRipple
                  >
                    <Typography variant="body1" className={classes.font_grey}>
                    {item.title}
                    </Typography>
                  </MenuItem>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  };
  invoiceHeadings = (key: string, classes: any,InvoiceStatusData) => {
    return (
      <>
        <Grid container>
          <Typography variant="body2"
            className={key === "awaiting_payment" ?
              classes.statusKeysTextPending :
              this.findClassesInvoice(key, classes)} >
            {InvoiceStatusData.find((item: any) => item.statusName === key)?.title}</Typography>
        </Grid>
        <Divider
          style={key === "awaiting_payment" ? webStyle.dividerLineColorInvoicePending :
            this.findDividerColorInvoice(key)} />
      </>
    )
  }
  findClassesInvoice = (key: string, classes: any) => {
    return key === "bad_debt" ?
      classes.statusKeysTextQuoted : this.findInvoiceClasses(key, classes)
  }

  findInvoiceClasses = (key: string, classes: any) => {
    return key === "payment_overdue"
      ? classes.statusKeysTextOverdue : this.findInvoiceClassesv1(key, classes)

  }
  findInvoiceClassesv1 = (key: string, classes: any) => {
    return key === "paid" ? classes.statusKeysTextConverted : classes.statusKeysTextCancelled
  }

  findDividerColorInvoice = (key: string) => {
    return key === "payment_overdue" ?
      webStyle.dividerLineColorInvoiceOverdue : this.findInvoiceColor(key)
  }
  findInvoiceColor = (key: string) => {
    return key === "bad_debt"
      ? webStyle.dividerLineColorInvoiceQuoted : this.findInvoiceColorv1(key)
  }
  findInvoiceColorv1 = (key: string) => {
    return key === "paid"
      ? webStyle.dividerLineColorInvoiceConverted : webStyle.dividerLineColorInvoiceCancelled
  }
  
  invoiceTableRow = ({ classes,invoiceTableRow }) => {
    return <>
      {invoiceTableRow.map((item: any, index: any) => (
        <>
          {
            this.state.requestToggleData[index]?.hidden && (
              <Grid item
                align="right"
                className={classes.padding_16}
                key={item.id}
                onClick={() => this.handleSortRequest(item.key)}
                data-test-id="handleSort"
                style={this.state.sortColumn === item.key
                  ? { backgroundColor: "rgba(232,232,232,0.4)", width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px" }
                  : { width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px" }}
              >
                <span
                  style={webStyle.tableHeadSpan}
                >
                  <Typography variant="body1"
                    className={classes.font_grey}
                    style={webStyle.tableHeadTextRequest}>
                    {item.title}
                  </Typography>
                  <img
                    style={(this.state.sortColumn === item.key) && this.state.sortDirection === 'asc' ? { ...webStyle.dropDownSelectIconTable, transform: 'rotate(180deg)' } : webStyle.dropDownSelectIconTable}
                    src={dropDownSelectIcon}
                    className={classes.dropDownSelectIcon}
                    alt=""
                  />
                </span>
              </Grid>
            )
          }
        </>
      ))}
    </>
  }
  tableCell = ({ key, classes, item }) => {
    return <>
      {this.state.requestToggleData[0]?.hidden &&
        (<Grid item
          className={`${classes.padding16}`}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >
          <Typography variant="body1" className={classes.font_grey}>
            {item.attributes?.title}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[1]?.hidden &&
        (<Grid item
          className={`${classes.padding16}`}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >

          <Typography variant="body1" className={classes.font_grey}>
            {item.attributes?.customer_name}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[2]?.hidden &&
        (<Grid item
          className={`${classes.padding16}`}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >

          <Typography variant="body1" className={classes.font_grey}>
            {moment(item.attributes?.issue_date).format("DD-MM-YYYY").toString()}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[3]?.hidden &&
        (<Grid item
          className={`${classes.padding16}`}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >

          <Typography variant="body1" className={classes.font_grey}>
            {moment(item.attributes?.payment_due).format("DD-MM-YYYY").toString()}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[4]?.hidden &&
        (<Grid item
          className={`${classes.padding16}`}
          style={{
            width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px"
          }}
          onClick={() => this.handleViewRequestData(item)}
        >

          <Typography variant="body1" className={classes.font_grey}>
            {(item.product?.attributes?.product_id && item.product?.attributes?.product_name) ?? '-'}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[5]?.hidden &&
        (<Grid item
          className={`${classes.padding16}`}
          style={{
            width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px"
          }}
          onClick={() => this.handleViewRequestData(item)}
        >
          <Typography variant="body1" className={classes.font_grey}>
            {(item.product?.attributes?.service_id && item.product?.attributes?.product_name) ?? '-'}
          </Typography>
        </Grid>
        )}
      {this.state.requestToggleData[6]?.hidden &&
        (<Grid item
          className={`${classes.padding16}`}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >

          <Typography variant="body1" className={classes.font_grey}>
            {item.attributes?.job_title}
          </Typography>
        </Grid>
        )}

      {this.state.requestToggleData[7]?.hidden &&
        (<Grid item
          className={`${classes.padding16}`}
          style={{ width: `${(100 / this.state.requestToggleData.filter((item) => item.hidden).length)}%`, minWidth: "185px" }}
          onClick={() => this.handleViewRequestData(item)}
        >

          <Typography variant="body1" className={classes.font_grey}>
            {item.attributes.total}
          </Typography>
        </Grid>)}
      {(key === "archived" && item.id === this.state.hoverRow) &&
        <Grid item
          align="center"
          style={{ zIndex: '1', position: 'absolute', width: '80px', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}
          onClick={() => this.handleDeleteDailog(item)}
          className={classes.tableCellBodyDeleteIcon}
        >
          <img src={deleteIcon} style={webStyle.deleteIcon} />
        </Grid>
      }

    </>
  }
  mobileBackIcon = () => {
    return selectedLanguage === "ar" ? {...webStyle.backIconMobile, ...webStyle.transformStyle} : webStyle.backIconMobile
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,t } = this.props;
    const InvoiceStatusData = [
      { id: 1, title: `${t('invoice.menuLabel.all')}`, statusName: "all"},
      { id: 2, title: `${t('invoice.menuLabel.awaitingPayment')}`, statusName: "awaiting_payment" },
      { id: 3, title: `${t('invoice.menuLabel.paymentOverdue')}`, statusName: "payment_overdue" },
      { id: 4, title: `${t('invoice.menuLabel.paid')}`, statusName: "paid" },
      { id: 5, title: `${t('invoice.menuLabel.badDebt')}`, statusName: "bad_debt" },
      { id: 5, title: `${t('invoice.menuLabel.archived')}`, statusName: "archived" },
    ]
    const invoiceTableRow = [
      { id: 1, key: "invoice_title", title: `${t('invoice.menuLabel.invoiceTitle')}` },
      { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
      { id: 3, key: "issue_date", title: `${t('invoice.menuLabel.issueDate')}`},
      { id: 4, key: "payment_due", title: `${t('invoice.menuLabel.paymentDue')}`},
      { id: 5, key: "product_name", title: `${t('invoice.menuLabel.productName')}`},
      { id: 6, key: "service_name", title: `${t('invoice.menuLabel.serviceName')}`},
      { id: 7, key: "job_reference", title: `${t('invoice.menuLabel.jobReference')}`},
      { id: 8, key: "product_quantity", title: `${t('invoice.menuLabel.totalPrice')}`},
    ]
    const lang = localStorage.getItem('lang')
    return (
      <>
        <DashboardSideBar oldProps={undefined} {...this.props} navMenuItem={`${this.props.t('invoice.header')}`} active={6} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.selectedLanguage}>
          <Box
            sx={{
              marginTop: "9rem",
              paddingBottom: "5rem"
            }}
            className={classes.boxRequestContainer}
          >
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                <Grid item md={5} xs={12}>
                  <Box style={webStyle.backIconContainer}>
                    <Hidden smUp>
                      <img style={this.mobileBackIcon()} src={backIconMobile} onClick={this.handleBackIcon} alt="" />
                    </Hidden>
                    <Box style={webStyle.searchContainer}>
                      <Box>
                        <img 
                          className={lang === 'ar' ? classes.searchIconAr : classes.searchIcon} 
                          src={searchIcon} alt="" 
                        />
                      </Box>
                      <InputBase
                        data-test-id="searchRequest"
                        style={webStyle.inputBase}
                        className={classes.inputBase}
                        placeholder={`${t('invoice.placeholder.searchInvoice')}`}
                        inputProps={{ 'aria-label': 'search' }}
                        value={this.state.searchRequest}
                        onChange={this.handleChangeSearchRequest}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={2}  xs={3}>
                  <CustomDatePicker
                    type='invoice'
                    data-testid="customDatePicker"
                    selectedDate={this.state.selectedDate}
                    status={this.state.dateStatus}
                    dateModal={this.state.dateModal}
                    datePickerRef={this.datePickerRef}
                    languageSelected={this.state.selectedLanguage}
                    handleDateChange={this.handleDateChange}
                    handleSelectStatus={this.handleSelectDateStatus}
                    handleDateModalOpen={this.handleDateModalOpen}
                    handleStatusModalClose={this.handleStatusModalClose} 
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                  <StyledTextFieldRequest
                    fullWidth
                    variant="outlined"
                    languageSelected={this.state.selectedLanguage}
                    defaultValue={`${t('invoice.placeholder.status')}`}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={!this.state.statusModal ? webStyle.dropDownSelectIcon : { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }} src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleStatusRequestModalOpen}
                  >
                  </StyledTextFieldRequest>
                  {this.state.statusModal ? (
                    this.statusModalRequests({ classes,InvoiceStatusData })
                  ) : null}
                </Grid>
                <Grid item md={3} xs={5}>
                  <StyledTextFieldRequest
                    fullWidth
                    data-testid='your-element'
                    languageSelected={this.state.selectedLanguage}
                    defaultValue={`${t('invoice.placeholder.toggleColumns')}`}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img style={!this.state.toggleModal ? webStyle.dropDownSelectIcon :
                            { ...webStyle.dropDownSelectIcon, transform: "rotate(180deg)" }}
                               src={dropDownSelectIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    onClick={this.handleToggleRequestModalOpen}
                  >
                  </StyledTextFieldRequest>
                  {this.state.toggleModal ? (
                    this.toggleModalRequests({ classes })
                  ) : null}
                </Grid>
              </Grid>
              <Grid container className={classes.invoiceContainer}>
                <Grid item xs={12} style={{ overflow: 'scroll', minWidth: "fit-content" }}>
                  <Grid container
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexWrap: 'nowrap',
                      gridAutoFlow: 'column',
                    }}
                  >
                    {this.invoiceTableRow({ classes,invoiceTableRow })}
                  </Grid>
                  <Divider className={classes.marginTop_1} />
                  {Object.keys(this.state.allRequestsList)?.map((key: string) => {
                    return (
                      <>
                       {this.invoiceHeadings(key,classes,InvoiceStatusData)}

                      
                        {this.state.allRequestsList[key]?.data?.length === 0 &&
                          <Grid container justifyContent="center" alignItems="center"
                            style={{ height: '150px' }}
                          >
                            <Grid item xs={12}>
                              <Typography variant="subtitle1" className={classes.fontW_500} style={webStyle.noDataText}>
                               {t('ProductService.noDataFound')}.
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                        {this.state.allRequestsList[key]?.data?.length !== 0 &&
                          <Grid container style={{
                            maxHeight: '300px', overflow: 'scroll', minWidth: "fit-content"
                          }}>
                            <Grid item xs={12}>
                              {this.state.allRequestsList[key]?.data?.map((item: any) => (
                                <>
                                  <StyledGrid container
                                    style={{
                                      display: 'flex',
                                      width: '100%',
                                      flexWrap: 'nowrap',
                                      gridAutoFlow: 'column',
                                      zIndex: '0',
                                      position: 'relative',
                                      justifyContent: 'end'
                                    }}
                                    data-test-id="handleViewRequest"
                                    key={item.id}
                                    onMouseEnter={() => {
                                      this.setState({ hoverRow: item.id });
                                    }}
                                    onMouseLeave={() => this.setState({ hoverRow: -1 })}
                                  >
                                    {this.tableCell({ key, classes, item })}
                                  </StyledGrid>
                                  <Divider />
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        }
                      </>
                    )
                  })}
                </Grid>
              </Grid>
              <Box style={webStyle.buttonsContainerInvoice}>
                <CreateQuote
                  onClick={this.handleCreateInvoice}
                  data-test-id="createinvoice"
                >
                  <Typography variant="subtitle2"
                    style={webStyle.buttonTextCreateRequest}
                    className={`${classes.fontW_400} ${classes.buttonTextRequest}`}
                  >
                   {t('invoice.button.createInvoice')}
                  </Typography>

                </CreateQuote>
              </Box>
              <LoadingBackdrop
                loading={this.state.tableLoading}
              />
            </Container>
          </Box>
        </StyledRoot>
        {this.hardDeleteStatus()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  boxRequestContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem 2rem !important",
    }
  },
  width20: {
    width: '20% !important'
  },
  buttonContainerRequest: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100% !important",
      minHeight: "48px !important"
    }
  },
  padding16: {
    padding: '28px 16px 28px 16px !important'
  },
 
  dropDownSelectIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem !important",
      width: "11px !important",
      height: "7px !important",
    }
  },
  padding_16: {
    padding: '16px'
  },
  searchIcon: {
      height: "20px",
      width: "20px",
      margin: "0 0.6rem 0 1.3rem",
    [theme.breakpoints.down("xs")]: {
      margin: "4px 0.6rem 0px 1.3rem !important",
    }
  },
  searchIconAr: {
    height: "20px",
    width: "20px",
    margin: "0px 1rem 0px 0.9rem",
    marginTop:"4px"
  },
  marginTop_1: {
    marginTop: '0.5rem'
  },
  tableCellBodyDeleteIcon: {
    background: "#B2B2B2"
  },
  font_grey:{
    color:CONSTANTS.color.grey
  },
  fontW_400:{
    fontWeight:CONSTANTS.fontWeight.fontWeight400
  },
  fontW_500:{
    fontWeight:CONSTANTS.fontWeight.fontWeight500
  },
  statusKeysTextPending: {
    color: "#6764D6",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginRight: 16,
    marginLeft: 16,
    textTransform: "capitalize"
  },
  statusKeysTextConverted: {
    color: "#008000",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginLeft: 16,
    marginRight: 16,
    textTransform: "capitalize"
  },
  statusKeysTextQuoted: {
    color: "#E02020",
    marginRight: 16,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginLeft: 16,
    textTransform: "capitalize"
  },
  statusKeysTextOverdue: {
    marginRight: 16,
    color:"#FA6400",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginLeft: 16,
    textTransform: "capitalize"
  },
  inputBase: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  statusKeysTextCancelled: {
    color: "#8C8C8C",
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 16,
    marginTop: 10,
    marginLeft: 16,
    textTransform: "capitalize"
  },
  statusText: {
    marginRight: 16,
    color: CONSTANTS.color.darkBlue,
    marginTop: 10,
    marginLeft: 16,
    textTransform: "capitalize"
  },
  invoiceContainer: {
    marginTop: "2rem",
    maxWidth: "100%",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "300px",
    overflow: 'scroll',
    height: "1043px",
    '&::-webkit-scrollbar-thumb': {
      backgroundColor:"#1c18af"
    },
    "&::-webkit-scrollbar" : {
      maxHeight: "5px"
  }
  },
});

const webStyle = {
  dividerLineColorInvoicePending: {
    marginTop: "8px",
     width: '100% !important',
    backgroundColor: "#6764D6"
  },
  dividerLineColorInvoiceConverted: {
    marginTop: "8px",
     width: '100% !important',
    backgroundColor: "#008000"
  },
  dividerLineColorInvoiceQuoted: {
    marginTop: "8px",
     width: '100% !important',
    backgroundColor: "#E02020"
  },
  dividerLineColorInvoiceOverdue: {
    marginTop: "8px",
     width: '100% !important',
    backgroundColor: "#FA6400"
  },
  dividerLineColorInvoiceCancelled: {
    marginTop: "8px",
     width: '100% !important',
    backgroundColor: "#8C8C8C"
  },
  dividerColor: {
    marginTop: "8px",
    backgroundColor: CONSTANTS.color.darkBlue,
    width: '100% !important'
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    width: "100%",
    borderRadius: "8px",
    border: "1.5px solid rgba(0,0,0,0.1)",
  },
  dropDownSelectIcon: {
    width: "13px",
    height: "9px",
    cursor: "pointer"
  },
  inputBase: {
    width: "100%",
    lineHeight: "16px",
    fontSize: "1.1667rem",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    color: CONSTANTS.color.grey
  },
  dropDownSelectIconTable: {
    width: "13px",
    height: "9px",
    cursor: "pointer",
    transform: "rotate(0deg)"
  },
  statusModalContainerInvoice: {
    position: "absolute" as const,
    zIndex: 9,
    width: "240px",
    minHeight: "182px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  statusModalRequest: {
    padding: "1rem"
  },
  menuItemStyleRequest: {
    color: CONSTANTS.color.grey,
  },
  toggleInvoiceModalContainer: {
    position: "absolute" as const,
    padding: "1rem",
    zIndex: 9,
    width: "254px",
    minHeight: "194px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  },
  toggleModalInvoiceStyle: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
  },
  requestToggleIconsContainer: {
    display: "flex",
    alignItems: "center"
  },
  toggleIconsRequest: {
    width: "24px",
    height: "24px"
  },
  tableHeadSpan: {
    cursor: "pointer",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableHeadTextRequest: {
    textAlign: 'start'
  },
  buttonsContainerInvoice: {
    marginTop: "3rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonTextCreateRequest: {
    color: CONSTANTS.color.white,
    textAlign: "center" as const,
  },
  noDataText: {
    color: CONSTANTS.color.darkGrey,
    textAlign: 'center',
  },
  backIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  backIconMobile: {
    width: "12px",
    height: "24px",
  },
  transformStyle: {
    transform: "rotate(180deg)"
  },
  deleteIcon: {
    width: "30px",
    height: "30px"
  },
}
const RequestRouter = withStyles(styles)(Invoices);
// @ts-ignore
const RequestsComponent = withRouter(RequestRouter);
const LanguageComponent= withSnackbar(RequestsComponent);
export default withTranslation()(LanguageComponent);
// Customizable Area End