import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import React from "react";

// Customizable Area Start
import {
  radioButton,
  radioButtonOff,
} from "./assets";

export const createCustData = [
  {
    id: 1,
    title: "Request follow-up",
    desc: "Follow up when a request is closed.",
    button: "Settings",
    radioEnable: radioButton,
    radioDisable: radioButtonOff,
    radioEnableLabel: "On",
    radioDisableLabel: "Off",
    enable: false,
  },
  {
    id: 2,
    title: "Quote follow-up",
    desc: "Follow up on outstanding quote.",
    button: "Settings",
    radioEnable: radioButton,
    radioDisable: radioButtonOff,
    radioEnableLabel: "On",
    radioDisableLabel: "Off",
    enable: false,
  },
  {
    id: 3,
    title: "Job follow-up",
    desc: "Follow up when a job is closed.",
    button: "Settings",
    radioEnable: radioButton,
    radioDisable: radioButtonOff,
    radioEnableLabel: "On",
    radioDisableLabel: "Off",
    enable: false,
  },
  {
    id: 4,
    title: "Invoice follow-up",
    desc: "Follow up on overdue invoice.",
    button: "Settings",
    radioEnable: radioButton,
    radioDisable: radioButtonOff,
    radioEnableLabel: "On",
    radioDisableLabel: "Off",
    enable: false,
  },
];

import { checkLoggedInUser,getLang, performAuthorizationCheck , sitesvalidateFormData, sitesvalidateFormDataHandle} from "./utility.web";
import {WithStyles} from '@material-ui/core' 
interface Position { 
  lng: number;
  lat: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles  {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  oldProps: any;
  text: any;
  toaster: any;
  handleCloseToaster: any;
  openToastHandler: any;
  t: (key: string) => string;
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  active: any;
  toaster: boolean;
  fileToaster: boolean;
  toasterText: string;
  files: any;
  editedFiles: any;
  convertedFiles: any;
  customerInformationFormData: {
    name: string;
    phone: string;
    email: string;
    notes: string;
   
  };
  editCustomerInformationFormData: {
    name: string;
    phone: string;
    email: string;
    notes: string;
  
  };
  textAddress: string;
  isErrorFormData: any;
  isFormSubmitted: boolean;
  deleteDialogOpen: boolean;
  customersData: any;
  createCustomersLoading: boolean;
  getCustomersLoading: boolean;
  getCustomerByIdLoading: boolean;
  editCustomersLoading: boolean;
  deleteCustomersLoading: boolean;
  mockAutomaticRemindersData: any;
  countryCode: any;
  countryCodeEdit: any;
  remindersDialogOpen: boolean;
  showUpdateButton: boolean;
  showFields: boolean;
  createdCustomerId: string;
  showActionBtn: boolean;
  mapsLibraries: any;
  center: {
    lat: number;
    lng: number;
  };
  selectedPlace: any;
  mapLoading: boolean;
  googleMapsApiKey: string;
  siteDialogOpen:boolean;
  siteInformationFormData: {
    site_name: string;
    site_Email: string;
    site_Phone: string;
    site_address: any;
    state: string;
    city: string;
    zip: string;
    latitude: any;
    longitude: any;
    country:string
  };
  autoCompleteInput:string;
  confirmationModal:boolean;
  lang:string,
  customerStatus:string,
  currentPosition: Position;
  latitude: number,
  longitude: number,
  openUnarchiveDialog : boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CreateCustomerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCustomersApiCallId: string = "";
  editCustomersApiCallId: string = "";
  getCustomersApiCallId: string = "";
  deleteCustomersApiCallId: string = "";
  unArchiveCustomersApiCallId : string = "";
  getCustomerByIdApiCallId: string = "";
  getCountryLocationApiCallId: string = "";
  mapRef: any;
  autocomplete: any;
  inputRef: any;
  timeoutId: null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.mapRef = React.createRef();
    this.inputRef = React.createRef();
    this.autocomplete = null;
    this.timeoutId = null;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      customerStatus:"active",
      active: 0,
      toaster: false,
      toasterText: "",
      fileToaster: false,
      remindersDialogOpen: false,
      files: [],
      editedFiles: [],
      convertedFiles: [],
      mapsLibraries: ["places"],
      center: { lat: 45.5344, lng: 23.555 },
      selectedPlace: null,
      mapLoading: false,
      customerInformationFormData: {
        name: "",
        phone: "",
        email: "",
        notes: "",
      
      },
      editCustomerInformationFormData: {
        name: "",
        phone: "",
        email: "",
        notes: "",
      },
      textAddress: "",
      isErrorFormData: {},
      mockAutomaticRemindersData: createCustData,
      isFormSubmitted: false,
      deleteDialogOpen: false,
      customersData: [],
      createCustomersLoading: false,
      getCustomersLoading: false,
      getCustomerByIdLoading: false,
      editCustomersLoading: false,
      deleteCustomersLoading: false,
      countryCode: "",
      countryCodeEdit: "",
      showUpdateButton: false,
      showFields: true,
      createdCustomerId: "",
      showActionBtn: false,
      googleMapsApiKey: "",
      siteDialogOpen:false,
      siteInformationFormData: {
        site_name: "",
        site_Email: "",
        site_Phone: "",
        site_address: "",
        state: "",
        city: "",
        zip: "",
        latitude: "",
        longitude: "",
        country:""
      },
      confirmationModal:false,
      autoCompleteInput:"",
      lang:"en",
      currentPosition:{
        lng: 0,
        lat: 0
      },
      latitude:0,
      longitude:0,
      openUnarchiveDialog : false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getCountryLocation()
    const editCusId = localStorage.getItem("editedCustomerId")
     if (editCusId &&this.props.location.pathname === "/EditCustomer"){
      this.handleProps();
      localStorage.removeItem("createCustomerDetails")
      localStorage.removeItem("createCustomerCountryCode")
    }
    window.scrollTo(0, 0);
    navigator.geolocation.getCurrentPosition(position => {
      this.setState({ currentPosition: { lat: position.coords.latitude, lng: position.coords.longitude } });
    }, error => {
      if (error.code === error.PERMISSION_DENIED) {
        const defaultLocation = { lat: this.state.latitude, lng: this.state.longitude };
        this.setState({ currentPosition: defaultLocation });
      }});
    const lang = await getLang();
    this.setState({ lang });
    const localVal = localStorage.getItem("editedCustomerId");
    const localValue = localStorage.getItem("customerPage");
    const path = this.props.location.pathname;
    this.setPathname(path, localVal)
    this.setPath(path, localValue)
    if (
      this.props.location.pathname === "/CreateCustomer" &&
      localStorage.getItem("customerPage") === "CREATE"
    ) {
      localStorage.removeItem("customerName");
      localStorage.removeItem("customer_id");
      localStorage.removeItem("editedCustomerId");
      localStorage.removeItem("customerPage");
    }
    if(this.props.location.pathname === "/CreateCustomer"){
      const text:string|null = localStorage.getItem("createCustomerDetails");
      const code:string|null = localStorage.getItem("createCustomerCountryCode");
      const codeObj= typeof code==='string'&& JSON.parse(code);
      this.setState({countryCode:codeObj},()=>{
        localStorage.removeItem("createCustomerCountryCode")
      })
      const obj= typeof text==='string'&& JSON.parse(text);
      this.setState({customerInformationFormData:obj},()=>{
        localStorage.removeItem("createCustomerDetails")
      })
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createCustomersApiCallId) {
        if (!responseJson.errors) {
          this.setState({ createCustomersLoading: false});
          this.setState({ createdCustomerId: responseJson.data.id }, () => { });
          localStorage.setItem("customer_id", responseJson.data.id);
          localStorage.setItem("editedCustomerId", responseJson.data.id);
          localStorage.setItem(
            "customerName",
            responseJson.data.attributes?.name
          );
          localStorage.setItem("customerPage", "CREATE");
          this.setState({ toasterText: "Customer created" });
          this.setState({ convertedFiles: [] });
          this.props.openToastHandler(this.props.t("customers.customerCreated"),"success")
         
          setTimeout(this.closeToasterTimeout, 1000);
        } else {
          //Check Error Response
          this.setState({ createCustomersLoading: false });
          this.handleTokenExpireError(responseJson);
        }
        this.setState({ createCustomersLoading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      } else if(apiRequestCallId === this.getCountryLocationApiCallId){
        this.getCountryLocationResponse(responseJson)
      } else if(apiRequestCallId === this.unArchiveCustomersApiCallId){
        this.unArchiveCustomersResponse(responseJson)
      }
    }

    this.handleEditCustomersData(message);
    this.handleDeleteCustomersData(message);
    await this.handleGetCustomerByIdData(message);

    // Customizable Area End
  }

  // Customizable Area Start
  //  componentWillUnmount() {
  //     // Stop listening for route changes when the component unmounts
  //     if (this.unlisten) {
  //       this.unlisten();
  //     }
  //   }
  setPath = (path:string,localVal:string|null)=>{
    if (
      path === "/CreateCustomer" &&
      localVal === "CREATE"
    ) {
      localStorage.removeItem("customerName");
      localStorage.removeItem("customer_id");
      localStorage.removeItem("editedCustomerId");
      localStorage.removeItem("customerPage");
    }
  }
  setPathname = (path:string,localVal:string | null)=>{
    if (
      localVal &&
      path === "/EditCustomer"
    ) {
      this.handleProps();
    }
  }
  closeToasterTimeout = () => {
    this.setState({ toaster: false, toasterText: "", fileToaster: false });
    this.props.history.push("/CustomerSites");
  }
  handleGetResponse = (responseJson: any) => {
    this.setState({ getCustomerByIdLoading: false });
    const {
      name,
      phone_number,
      email,
      notes,
      request_follow_up,
      quote_follow_up,
      job_follow_up,
      invoice_follow_up,
      files,
      status
    } = responseJson.data.attributes;
    this.setState({ createdCustomerId: responseJson.data.id, deleteDialogOpen: false, });
    localStorage.setItem("customer_id", responseJson.data.id);

    this.setState({
      editCustomerInformationFormData: {
        name,
        phone: phone_number?.split(" ")[1],
        email,
        notes,
      },
      files: [],
      editedFiles: files?.length > 0 ? [...files] : [],
      customerStatus:status
    });
    this.setState({
      countryCode: phone_number?.split(" ")[0],

    });
    const mockArr = [request_follow_up, quote_follow_up, job_follow_up, invoice_follow_up];
    const updatedItems = this.state.mockAutomaticRemindersData.map(
      (itm: any, index: any) => {
        return { ...itm, enable: mockArr[index] ? true : false };
      }
    );
    this.setState({ mockAutomaticRemindersData: updatedItems });
  };

  handleGetCustomerByIdData = async (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    await this.handleGetCustomerByIdDataCallback(
      apiRequestCallId,
      responseJson,
      errorResponse,
      this.getCustomerByIdApiCallId
    );
  };

  handleGetCustomerByIdDataCallback = async (
    apiRequestCallId: any,
    responseJson: any,
    errorResponse: any,
    getCustomerByIdApiCallId: any
  ) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === getCustomerByIdApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            return this.props.openToastHandler(
              this.props.t("customers.unAuth"),
              "error"
            );
          }
          this.handleGetResponse(responseJson);
        } else {
          //Check Error Response
          this.setState({ getCustomerByIdLoading: false, deleteDialogOpen: false, });
          await this.handleTokenExpireError(responseJson);
        }
        this.setState({ getCustomerByIdLoading: false, deleteDialogOpen: false,});
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };

  handleTokenExpireError = async (responseJson: any) => {
    if (
      responseJson.errors[0]?.token == "Token has Expired" ||
      responseJson.errors[0]?.token == "Invalid token"
    ) {
      this.props?.openToastHandler(responseJson?.errors[0].token, "error");
      await this.createCustomertokenExpired();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  createCustomertokenExpired = async () => {
    const isEmployee = await checkLoggedInUser();
    this.tokenExpiredCallback(isEmployee);
  };

  tokenExpiredCallback = (isEmployee: any) => {
    const redirectUrl = isEmployee ? "/Employee/Login" : "/Login";
    setTimeout(this.tokenExpiredTimeCallback.bind(this, redirectUrl), 1000);
  };

  tokenExpiredTimeCallback = (url: any) => {
    localStorage.clear();
    this.props.history.push(url);
  };


  getCountryLocation = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryLocationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryLocation}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCountryLocationResponse = (responseJson: { errors: string,longitude:number,latitude:number }) => {
    if (responseJson && !responseJson.errors) {
      this.setState({latitude:responseJson.latitude,longitude:responseJson.longitude});
    }
  }
  unArchiveCustomersResponse = (responseJson: { errors: string; }) =>{
    if (!responseJson.errors) {
      this.setState({ toasterText: this.props.t("customers.formData.cusUnArchive")});
      this.props.openToastHandler(this.props.t("customers.formData.cusUnArchive"),"success")
      this.setState({
        deleteCustomersLoading: false,
        openUnarchiveDialog: false,
      });
      localStorage.removeItem("editedCustomerId");

      setTimeout(this.editApiCloseToastertimeout, 1000);
    } else {
      this.setState({
        deleteCustomersLoading: false,
        openUnarchiveDialog: false,
      });
      this.handleTokenExpireError(responseJson);
    }
    this.setState({
      deleteDialogOpen: false,
      deleteCustomersLoading: false,
    });
  }
  handleEditCustomersData = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.editCustomersApiCallId) {
        if (!responseJson.errors) {
          this.editApiStateCallback();
          this.handleGetResponse({
            data: responseJson.customer.data,
          });
        } else {
          //Check Error Response
          this.setState({ editCustomersLoading: false });
          this.handleTokenExpireError(responseJson);
        }
        this.setState({ editCustomersLoading: false });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };
  editApiStateCallback = () => {
    this.setState({
      editCustomersLoading: false,
      showUpdateButton: false,
    });
    this.setState({ showFields: true });
  
    this.props.openToastHandler(this.props.t("customers.customerUpdated"),"success")
    this.setState({  convertedFiles: [] });
  
  };
  handleDeleteCustomersData = (message: any) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deleteCustomersApiCallId) {
        if (!responseJson.errors) {
          this.setState({ toasterText: "Customer deleted" });
         
          this.props.openToastHandler(this.props.t("customers.customerDeleted"),"success")
          this.setState({
            deleteCustomersLoading: false,
            deleteDialogOpen: false,
          });
          localStorage.removeItem("editedCustomerId");

          setTimeout(this.editApiCloseToastertimeout, 1000);
        } else {
          //Check Error Response
          this.setState({
            deleteCustomersLoading: false,
            deleteDialogOpen: false,
          });
          this.handleTokenExpireError(responseJson);
        }
        this.setState({
          deleteDialogOpen: false,
          deleteCustomersLoading: false,
        });
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };
  editApiCloseToastertimeout = () => {
    this.setState({ toaster: false, toasterText: "" });
    this.props.history.push("/Customers");
  };
  createCustomers = () => {
    const {
      name,
      phone,
      email,
      notes,
    } = this.state.customerInformationFormData;
     const {site_name,site_Email, site_Phone,site_address,latitude,longitude,state,zip,city,country} =  this.state.siteInformationFormData
    const { countryCode } = this.state;

    const header = {
      token: localStorage.getItem("authToken"),
    };
    let formDataV1 = new FormData();

    formDataV1.append("data[name]", String(name).trim());
    formDataV1.append("data[email]", String(email).trim());
    formDataV1.append("data[phone_number]",`${countryCode} ${String(phone).trim()}`);
    formDataV1.append("data[notes]", String(notes).trim());

    formDataV1.append("data[site_informations_attributes][0][site_name]",String(site_name).trim())

    formDataV1.append("data[site_informations_attributes][0][site_address]",String(site_address).trim())

    formDataV1.append("data[site_informations_attributes][0][city]",String(city).trim())

    formDataV1.append("data[site_informations_attributes][0][zip_code]",String(zip).trim())

    formDataV1.append("data[site_informations_attributes][0][country]",String(country).trim())

    formDataV1.append("data[site_informations_attributes][0][state]",String(state).trim())
    formDataV1.append("data[site_informations_attributes][0][latitude]",String(latitude).trim())
    formDataV1.append("data[site_informations_attributes][0][longitude]",String(longitude).trim())
    formDataV1.append("data[site_informations_attributes][0][email]",String(site_Email).trim())
    formDataV1.append("data[site_informations_attributes][0][phone_number]",String(site_Phone).trim())
    
    formDataV1.append(
      "data[request_follow_up]",
      this.state.mockAutomaticRemindersData[0].enable
    );
    formDataV1.append(
      "data[quote_follow_up]",
      this.state.mockAutomaticRemindersData[1].enable
    );
    formDataV1.append(
      "data[job_follow_up]",
      this.state.mockAutomaticRemindersData[2].enable
    );
    formDataV1.append(
      "data[invoice_follow_up]",
      this.state.mockAutomaticRemindersData[3].enable
    );
    for (const element of this.state.files) {
      formDataV1.append("data[files][]", element);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCustomersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCustomersAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataV1
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createCustomersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  editCustomers = (status:string) => {
    const {
      name,
      phone,
      email,
      notes,
    } = this.state.editCustomerInformationFormData;
    const { countryCode } = this.state;

    const header = {
      token: localStorage.getItem("authToken"),
    };
   
    let formDataV2 = new FormData();

    formDataV2.append("data[name]", String(name).trim());
    formDataV2.append("data[email]", String(email).trim());
    formDataV2.append("data[phone_number]", `${countryCode} ${String(phone).trim()}`);
    formDataV2.append("data[notes]", String(notes).trim());
    formDataV2.append("data[status]", status);
    formDataV2.append(
      "data[request_follow_up]",
      this.state.mockAutomaticRemindersData[0].enable
    );
    formDataV2.append(
      "data[quote_follow_up]",
      this.state.mockAutomaticRemindersData[1].enable
    );
    formDataV2.append(
      "data[job_follow_up]",
      this.state.mockAutomaticRemindersData[2].enable
    );
    formDataV2.append(
      "data[invoice_follow_up]",
      this.state.mockAutomaticRemindersData[3].enable
    );

    for (const element of this.state.files) {
      formDataV2.append("data[files][]", element);
    }
    for (const element of this.state.convertedFiles) {
      formDataV2.append("data[file_id][]", element.id);
    }

    const editedCustomerId = localStorage.getItem("editedCustomerId");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.editCustomersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/customers/${editedCustomerId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataV2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editCustomersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  unArchiveCustomer = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const editedCustomerId = localStorage.getItem("editedCustomerId");
    let formDataV2 = new FormData();

    const floatId = editedCustomerId ? parseFloat(editedCustomerId) : null;

    formDataV2.append("id", floatId !== null ? String(floatId) : "");
    this.setState({ deleteCustomersLoading: true }) 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unArchiveCustomersApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unArchiveCustomerAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataV2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editCustomersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  deleteCustomer = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };
    const editedCustomerId = localStorage.getItem("editedCustomerId");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCustomersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/customers/${editedCustomerId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteCustomersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getCustomerById = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: localStorage.getItem("authToken"),
    };

    const editedCustomerId = localStorage.getItem("editedCustomerId");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerByIdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/customers/${editedCustomerId}?lang=${this.state.lang}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCustomersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleProps = () => {
    this.setState({ getCustomerByIdLoading: true });
    this.getCustomerById();
  };

  //autocomplete address fuction start
  onLoad = (autocomplete: any) => {
    this.autocomplete = autocomplete;
    this.setState({ mapLoading: true });
  };

  handleGoogleFormData = (
    state: string,
    city: string,
    zipcode: any,
    formattedAddress: string,
    location: any,
    country:string
  ) => {
    this.setState({
      siteInformationFormData: {
        ...this.state.siteInformationFormData,
       
        latitude: location.lat(),
        longitude: location.lng(),
       
      },
      autoCompleteInput:formattedAddress
    });
  };

  handleGeocodeCallback = (
    addressComponents: any,
    formattedAddress: string,
 
    location: any
  ) => {
    let stateV1 = "";
    let zipcodeV1 = "";
    let cityV1 = "";
    let countryV1 = "";

    addressComponents.forEach((component: any) => {
      if (component.types.includes("administrative_area_level_1")) {
        stateV1 = component.long_name;
      } else if (component.types.includes("postal_code")) {
        zipcodeV1 = component.long_name;
      } else if (component.types.includes("locality")) {
        cityV1 = component.long_name;
      } else if (component.types.includes("country")) {
        countryV1 = component.long_name;
      }
    });
    this.handleGoogleFormData(
      stateV1,
      cityV1,
      zipcodeV1,
      formattedAddress,
      location,
      countryV1
    );
  };
  handleGeocodeCall = async ( place: any) => {
    const addressComponentsV1 = place.address_components;
    const formattedAddressV1 = place.formatted_address;
    if (addressComponentsV1 && formattedAddressV1) {
      this.handleGeocodeCallback(
        addressComponentsV1,
        formattedAddressV1,
      
        place.geometry.location
      );
    } else {
      const placeV1 = this.autocomplete?.getPlace();
      const geocoder = new window.google.maps.Geocoder();
      try {
        await geocoder.geocode(
          { placeId: placeV1 ? placeV1.place_id : place.place_id },
          (results: any, status: any) => {
            this.handleGeocodeResponse(status, results);
          }
        );
      } catch (error) { }
    }
  };

  handleGeocodeResponse = (
    status: string,
    results: any,
  ) => {
    if (status === "OK") {
      const addressComponentsV2 = results[0].address_components;
      const formattedAddressV2 = results[0].formatted_address;
      let stateV2 = "";
      let zipcodeV2 = "";
      let cityV2 = "";
       let countryV2 = "";

      addressComponentsV2.forEach((component: any) => {
        if (component.types.includes("administrative_area_level_1")) {
          stateV2 = component.long_name;
        } else if (component.types.includes("postal_code")) {
          zipcodeV2 = component.long_name;
        } else if (component.types.includes("locality")) {
          cityV2 = component.long_name;
        }else if (component.types.includes("country")) {
          countryV2 = component.long_name;
        }
      });
      this.handleGoogleFormData(
        stateV2,
        cityV2,
        zipcodeV2,
        formattedAddressV2,
        results[0].geometry.location,
        countryV2
      );
    }
  };
  fetchAddress = async (place: any) => {
    try {
      await this.handleGeocodeCall(place);
      this.setState({ selectedPlace: place.geometry.location }, () => {
        const map = this.mapRef.current;
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(place.geometry.location);
        map.fitBounds(bounds, 100);
        map.setZoom(15);
      });
    } catch (error) { }
  };
  handleKeyPress = async ( event: any) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value;

      const geocoder = new window.google.maps.Geocoder();
      try {
        await geocoder.geocode({ address: inputValue }, (results, status) => {
          if (status === "OK" && results && results.length > 0) {
            const place = results[0];
            this.fetchAddress(place);
          }
        });
      } catch (error) { }
    }
  };

  onPlaceChanged = async () => {
    if (this.autocomplete != null) {
      const place = this.autocomplete?.getPlace();
      this.fetchAddress(place);
    }
  };
  //autocomplete address fuction end

  handleChangeCountryCode = (event: any, value: any) => {
    this.setState({ countryCode: value });
  };
  handleChangeFormDatacallback = (local:string | null,e:any)=>{
    if (local) {
      this.setState({
        editCustomerInformationFormData: {
          ...this.state.editCustomerInformationFormData,
          [e.target.name]: e.target.value,
        },
      },()=>{
        this.setState(
          {
            isErrorFormData: this.validateFormData(
              this.state.editCustomerInformationFormData
            ),
          },
         
        )
      });
    } else {
      this.setState({
        customerInformationFormData: {
          ...this.state.customerInformationFormData,
          [e.target.name]: e.target.value,
        },
      },()=>{
        this.setState(
          {
            isErrorFormData: this.validateFormData(
              this.state.customerInformationFormData
            ),
          },
         
        )
      });
    }
  }
  handleChangeFormDataSites = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      siteInformationFormData: {
        ...this.state.siteInformationFormData,
        [e.target.name]: e.target.value,
      },
      
    },()=>{
      this.setState({
        isErrorFormData: sitesvalidateFormDataHandle(
          this.state.siteInformationFormData,this.props.t,e.target.name
        ),
      })
    });
  };

  handleChangeFormDataInputSites = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      autoCompleteInput: e.target.value,
    },()=>{
      this.setState({
        isErrorFormData: sitesvalidateFormData(
          this.state.siteInformationFormData,this.props.t
        ),
      })
    });
  };
  handleChangeFormData = (e: any) => {
   this.handleChangeFormDatacallback(localStorage.getItem("editedCustomerId"),e)
  };

  handleRadioCallback = (item: any) => {
    if (item.id) {
      const itemToUpdate = this.state.mockAutomaticRemindersData.find(
        (i: any) => i.id == item.id
      );
      const updatedItem = { ...itemToUpdate, enable: !item.enable };
      const updatedItems = this.state.mockAutomaticRemindersData.map(
        (itm: any) => {
          if (itm.id == itemToUpdate.id) {
            return updatedItem;
          } else {
            return itm;
          }
        }
      );
      this.setState({ mockAutomaticRemindersData: updatedItems });
    }
  };

  handleRadioChange = (item: any, page: any) => {
    if (page == "CREATE") {
      this.handleRadioCallback(item);
    } else {
      if (item.id && !this.state.showFields) {
        const itemToUpdate = this.state.mockAutomaticRemindersData.find(
          (i: any) => i.id == item.id
        );
        const updatedItem = { ...itemToUpdate, enable: !item.enable };
        const updatedItems = this.state.mockAutomaticRemindersData.map(
          (itm: any) => {
            if (itm.id == itemToUpdate.id) {
              return updatedItem;
            } else {
              return itm;
            }
          }
        );
        this.setState({ mockAutomaticRemindersData: updatedItems });
      }
    }
  };

  handleCloseRemindersDialog = () => {
    this.setState({ remindersDialogOpen: false });
  };

  handleCancelButton = () => {
    localStorage.removeItem("editedCustomerId");
    localStorage.removeItem("customerName");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("customerPage");
    const redirectUrl = localStorage.getItem("cancelRedirection");
    const crudType =this.props.location?.state?.crudType
    this.redirect(redirectUrl,crudType);
  };
  editUrl = (url: string,crudType:string) => {
    
    if (crudType ==="editRequest") {
      this.props.navigation.history.push("/Request/Edit", { requestId: this.props.location.state.requestId });
    }
    else if(crudType ==="editJob"){
      this.props.navigation.history.push("/Job/Edit", { jobId: this.props.location.state.jobId,type:"edit" });
    }
    else {
      this.props.navigation.history.push(url);
    }
  }
  redirect = (url: any,crudType:string) => {
    if (url) {
      this.editUrl(url,crudType)
    } else {
      setTimeout(this.clearRedirectUrl, 2000);
      this.props.history.goBack();
    }
  };

  clearRedirectUrl = () => {
    localStorage.removeItem("cancelRedirection");
  };

  handleEditButton = async () => {
    const isAuthorized = await performAuthorizationCheck("customers_data"); //true = not authorized , false = authorized
    this.handleEditButtonCallback(isAuthorized);
  };

  handleEditButtonCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ showUpdateButton: true, showFields: false });
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };

  handleClickProceedDelete = () => {
    if (this.state.customerStatus === "archived") {
      this.deleteCustomer();
      this.setState({ deleteCustomersLoading: true });
    } else {
      this.editCustomers("archived")
    }
    
  };

  handleCloseToaster = () => {
    this.setState({ toaster: false });
  };



  validateFormData = (values: any) => {
    const errors: any = {};
    const name = values.name;

    if (!name) {
      errors.name = this.props.t("customers.customersErrorText.requiredTypo");
    } else if (name.length > 23) {
      errors.name = this.props.t("customers.customersErrorText.lengthTypo");
    } else if (!configJSON.regexAr.spaces.test(name)) {
      errors.name = this.props.t("customers.customersErrorText.alphabeticTypo");
    }
    if (values.phone && !configJSON.regex.phone.test(values.phone)) {
      errors.phone = this.props.t("customers.customersErrorText.phoneTypo");
    }

    return errors;
  };

  handleClickOpenDialog = async () => {
    const isAuthorized = await checkLoggedInUser();
    this.handleClickOpenDialogCallback(isAuthorized);
  };

  handleClickOpenDialogCallback = async (isAuthorized: any) => {
    if (!isAuthorized) {
      //false = either BO or no permissions
      this.setState({ deleteDialogOpen: true });
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };

  handleCloseDialog = async () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleSaveCall = () => {
    if (Object.keys(this.state.isErrorFormData).length === 0) {
      this.setState({  siteDialogOpen:true ,countryCode:this.state.countryCode || "" ,customerInformationFormData:{
        name:this.state.customerInformationFormData.name || "",
        phone:this.state.customerInformationFormData.phone || "",
        email:this.state.customerInformationFormData.email || "",
        notes:this.state.customerInformationFormData.notes || ""
      }});
    }
  };

  handleSaveCustomerInformation = async () => {
    const isAuthorized = await performAuthorizationCheck("customers_data");
    this.handleSaveCustomerInformationCallback(isAuthorized);
  };
  handleSaveCustomerInformationCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
     
      const validationErrors = this.validateFormData(this.state.customerInformationFormData);
      
      this.setState({ isErrorFormData: validationErrors }, () => {
        if (Object.keys(validationErrors).length > 0) 
        {         
          const firstErrorField = Object.keys(validationErrors)[0];
          const element = document.querySelector(`[name="${firstErrorField}"]`);
  
          if (element) 
          {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else
        {
          this.handleSaveCall();
        }
      });
    } else {
      this.props.openToastHandler(this.props.t("customers.unAuth"), "error");
    }
  };
  
  
  handleDownloadClick = async (file: any) => {
    try {
      const url = file.url ? file.url : URL.createObjectURL(file);

      const response = await fetch(url);
      const blob = await response.blob();

      // Create an anchor element
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = file.name;

      // Trigger a click on the anchor link
      downloadLink.click();

      // Clean up the object URL
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      this.props.openToastHandler(this.props.t("customers.fileErr"), "error");
    }
  };

  handleGoToSettings = () => {
    this.setState({confirmationModal:!this.state.confirmationModal})
  
  };
  handleOpenUnarchiveDialog = () => {
    this.setState({ openUnarchiveDialog: true })
  }
  handleCloseUnarchiveDialog = () => {
    this.setState({ openUnarchiveDialog: false })
  }
  handleGoToSettingsCallback = ()=>{
    localStorage.setItem('createCustomerDetails', JSON.stringify(this.state.customerInformationFormData))
    localStorage.setItem('createCustomerCountryCode', JSON.stringify(this.state.countryCode))
    this.props.history.push("/Settings/Notification");
  }

  handleMarkerDrag = async (event: any) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    try {
      let { address }:any= await this.getAddressFromCoordinates(newLat, newLng);
      this.setState({
        siteInformationFormData: {
          ...this.state.siteInformationFormData,
        
          latitude:newLat,
          longitude:newLng
        }, autoCompleteInput:address
      }, );
    } catch (error) {
      this.props.openToastHandler(this.props.t("customers.addressErr"), "error");
    }
  };

  getAddressFromCoordinates = async (lat: any, lng: any) => {
    const apiKey = "AIzaSyD--KtNzcav1Kh1toELW4RHw-NqiFVpeH8";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      return this.getAddressFromCoordinatesCallback(data);
    } catch (error) {
      this.props.openToastHandler(this.props.t("customers.addressErr"), "error");
      return { address:"", country:""};
    }
  };
  getAddressFromCoordinatesCallback = (data: any) => {
    if (data.results.length > 0) {
      const result = data.results[0];
      const formattedAddress = result.formatted_address;

      // Filtering out the country name from address components
      const countryComponent = result.address_components.find(
        (component: any) => component.types.includes("country")
      );
      const country = countryComponent ? countryComponent.long_name : "";

      return { address:formattedAddress, country };

    } else {
      this.props.openToastHandler(this.props.t("customers.noDataFound"), "error");
      return { address: "", country: "" };
    }
  };
  handleCloseSiteDialog = () => {
    this.setState({ siteDialogOpen: false });
  };



  handleSaveCallSite = () => {
    if (Object.keys(this.state.isErrorFormData).length === 0) {
      this.setState({createCustomersLoading:true, siteDialogOpen:false}, this.createCustomers);
     
    }
  };

  handleSaveSiteInformation = () => {
    this.setState({ showActionBtn: false });
    this.setState(
      {
        isErrorFormData: sitesvalidateFormData(
          this.state.siteInformationFormData, this.props.t
        ),
      },
      this.handleSaveCallSite
    );
  };

  getJobEditedFiles = (files: any) => {
    this.setState({
      editedFiles: files
    })
  }
  getJobConvertedFiles = (files: any) => {
    this.setState({
      convertedFiles: files
    })
  }
  updateInternalNotes = (internalNotes: string) => {
    this.setState({ customerInformationFormData: {
      ...this.state.customerInformationFormData,
      notes:internalNotes
    } })
  }
  updateInternalAttachments = (attachments: any) => {
    this.setState({ files: attachments })
  }
  handleUpdateCallback = () => {
    if (Object.keys(this.state.isErrorFormData).length === 0) {
      this.setState({ editCustomersLoading: true });
      this.editCustomers("active");
    } else {     
      const firstErrorField = Object.keys(this.state.isErrorFormData)[0];
      const element = document.querySelector(`[name="${firstErrorField}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  };

  handleUpdateButton = () => {
    this.setState(
      {
        isErrorFormData: this.validateFormData(
          this.state.editCustomerInformationFormData
        ),
      },
      this.handleUpdateCallback
    );
  };
}
