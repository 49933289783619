import React from "react";

// Customizable Area Start
import {
  Box, Dialog, Grid, Divider, Avatar, ListItemText, DialogContent, DialogActions, Tooltip, ClickAwayListener, Button, Menu, MenuItem, ListItemIcon, Typography, Checkbox, Hidden
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { Calendar as CustomCalender, momentLocalizer } from "react-big-calendar";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { editIcon, viewIcon, deleteIcon, taskIcon, jobIcon, appointmentIcon } from "./assets"
import { backIconMobile } from '../../CustomisableUserProfiles/src/assets'
import { withSnackbar } from "../../../components/src/toastweb";
import DeleteModal from './DeleteModal.web'
import NewAppointment from './NewAppointment.web'
import Task from './Task.web'
import Job from './Job.web'
import calendarStyles from './Calendar.style'
import moment from "moment";
const localizer = momentLocalizer(moment)
import 'moment/locale/ar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { checkEventOrNot, checkBoxShadowEventOrNot, findEventObject } from "../../../components/src/CommonFunction";
import { CONSTANTS } from '../../../components/src/theme'
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import { ar } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
const selectedLanguage = localStorage.getItem("lang");
if (selectedLanguage === 'ar') {
  registerLocale("ar", ar);
}
// Customizable Area End

import CalendarController, {
  Props,
} from "./CalendarController.web";

// Customizable Area Start
interface Event {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "task_name": string,
    "description": string,
    "start_date": string,
    "end_date": string,
    "start_time": string,
    "end_time": string,
    "schedule_later": boolean,
    "all_day": boolean,
    "email_team": boolean,
    "mark_as_complete": boolean,
    "employees": [
      {
        "id": number,
        "name": string,
        "email": string
      },
    ],
    "employee_image": [
      {
        "id": number,
        "url": string,
        "name": string,
        "content_type": string,
        "size": number
      }
    ]
  },
  "title": string,
  "start": string,
  "end": string,
  "des": string
}
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export function BgColor(events: any, event: any) {
  if (checkEventOrNot({ eventDate: event, eventsArray: events })) {
      return '#E9EAFF'
  }
  else {
    return ''
  }
}
export function eventHorizontalLine(events: any, event: any) {
  if (checkBoxShadowEventOrNot({ eventDate: event, eventsArray: events })) {
      return 'inset 4px 0em #1c18af'
  } else {
    return ''
  }
}
export function customDayPropGetter(event: any, events: any, view: any) {
  return {
    style: {
      background: BgColor(events, event),
      boxShadow: view === 'month' ? eventHorizontalLine(events, event) : '',
    },
  }
}
export function dateHeaderStyle(date: any, events: any) {
  if (date.isOffRange === true) {
    return 'white'
  } else {
    if (checkEventOrNot({ eventDate: date.date, eventsArray: events })) {
      return '#1c18af'
    } else {
      return '#626262'
    }
  }
}
export function viewDay(selectedDate: string, view: string, startDate: Date) {
  if (view === 'day') {
    if (selectedDate) {
      return moment(new Date(selectedDate)).format("dddd MMM DD").toString()
    } else {
      return moment(new Date(startDate)).format("dddd MMM DD").toString()
    }
  }
}
export function dateFormate(datePickerIsOpen:boolean,classes: ClassNameMap<string>, view: string, closeDatePicker: React.MouseEventHandler<HTMLButtonElement> | undefined, openDatePicker: React.MouseEventHandler<HTMLButtonElement> | undefined, startDate: Date, selectedDate: string) {
  const curr = new Date(startDate);
  const first = curr.getDate() - curr.getDay();
  const last = first + 6;
  const firstDay = moment(new Date(curr.setDate(first))).format("MMM DD").toString();
  const lastDay = moment(new Date(curr.setDate(last))).format("MMM DD").toString();
  return <>
    <Typography className={classes.fontWeight_600} variant="subtitle1">
      {view === 'month' && moment(new Date(startDate)).format("MMMM YYYY").toString()}
      {view === 'week' && `${firstDay} - ${lastDay}`}
      {viewDay(selectedDate, view, startDate)}
    </Typography>
    {datePickerIsOpen === true ?
      <Button onClick={closeDatePicker} className={classes.dropdownIconBtn}>
        <ExpandLessIcon style={{ color: 'darkGrey' }}  />
      </Button> :
      <Button onClick={openDatePicker} className={classes.dropdownIconBtn}>
        <ExpandMoreIcon style={{ color: 'darkGrey' }} />
      </Button>
    }
  </>
}
export function dateLabel(t: (key: string) => string,classes: ClassNameMap<string>, handleDayChange: (plus: string) => void, view: string, datePickerIsOpen: boolean, startDate: Date, handleMonthChange: (date: Date) => void) {
  return <>{datePickerIsOpen === true && view === "week" ?

    <DatePicker
      locale="ar"
      selected={startDate}
      onChange={handleMonthChange}
      open={datePickerIsOpen}
      showWeekPicker
      showPopperArrow={false}
      className={classes.customDatePicker}
      calendarClassName={classes.customWeekPicker}
      renderCustomHeader={({
        date,
        decreaseYear,
        increaseYear,
      }) => (
        <div
          style={{
            margin: "0px 18px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className={`${classes.cursor} ${classes.arrowIcon}`} onClick={decreaseYear}>{"<"}</span>
          <span className={`${classes.font_16} ${classes.fontWeight_500}`}>{moment(date).format("MMMM YYYY")}</span>
          <span className={`${classes.cursor} ${classes.arrowIcon}`} onClick={increaseYear}>{">"}</span>
        </div>
      )}
      renderDayContents={(_, date) => {
        return <span>{moment(date).format("DD")}</span>;
      }}
    />
    : null}
    {datePickerIsOpen === true && view === "month" ?
      <DatePicker
        locale="ar"
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
        }) => (
          <div
            style={{
              margin: 18,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span className={classes.cursor} onClick={decreaseMonth}>{"<"}</span>
            <span className={classes.font_22}>{moment(date).format("YYYY")}</span>
            <span className={classes.cursor} onClick={increaseMonth}>{">"}</span>
          </div>
        )}
        selected={startDate}
        onChange={handleMonthChange}
        open={datePickerIsOpen}
        showMonthYearPicker
        showFourColumnMonthYearPicker
        showPopperArrow={false}
        className={classes.customDatePicker}
        calendarClassName={classes.customMonthPicker}
      />
      : null}
      {dayPicker(t,classes,handleDayChange,view,datePickerIsOpen,startDate,handleMonthChange)}
      </>
}
export function dayPicker(t: (key: string) => string,
classes: ClassNameMap<string>, handleDayChange: (plus: string) => void,
 view: string, datePickerIsOpen: boolean, startDate: Date, 
 handleMonthChange: (date: Date) => void) {
  const getDayName = (date: Date) => {
    const daysOfWeek = [
      `${t('settings.sunday')}`,
      `${t('settings.monday')}`,
      `${t('settings.tuesday')}`,
      `${t('settings.wednesday')}`,
      `${t('settings.thursday')}`,
      `${t('settings.friday')}`,
      `${t('settings.saturday')}`,
      `${t('settings.sunday')}`,
    ];
    return daysOfWeek[date.getDay()];
  };
  const dayName = getDayName(startDate);
  return  datePickerIsOpen === true && view === 'day' ?
  <DatePicker
    locale="ar"
    renderCustomHeader={() => (
      <div style={{
        margin: 18,
        display: "flex",
        justifyContent: "space-between",
      }}
      >
        <span className={classes.cursor} onClick={() => handleDayChange('minus')}>{"<"}</span>
        <span className={`${classes.font_16} ${classes.fontWeight_500}`}>{dayName}</span>
        <span className={classes.cursor} onClick={() => handleDayChange('plus')}>{">"}</span>
      </div>
    )}
    selected={startDate}
    onChange={handleMonthChange}
    open={datePickerIsOpen}
    showPopperArrow={false}
    className={classes.customDatePicker}
    calendarClassName={classes.customWeekPicker}
  />
  : null
}
export function menuLabel(type: string, t: (key: string) => string) {
  return <>
    {type === "appointment" && `${t('Calendar.editAppointment')}`}
    {type === "task" && `${t('Calendar.editTask')}`}
    {type === "job" && `${t('Calendar.editJob')}`}
  </>
}
export function statusLabel(type: string, t: (key: string) => string) {
  return <>
    {type === "appointment" && `${t('request.appointmentText')}`}
    {type === "task" && `${t('Calendar.taskStatus')}`}
    {type === "job" && `${t('Calendar.jobStatus')}`}
  </>
}
export function appointmentDescription(description: string, classes: ClassNameMap<string>) {
  return <>
    <Typography variant="body2" className={classes.paddingLeft_20}>
      {description}
    </Typography>
  </>
}
export function deleteLabel(t: (key: string) => string, isEmployee: boolean, classes: ClassNameMap<string>, handleOpenDeleteAppointMentModal: React.MouseEventHandler<HTMLLIElement> | undefined) {
  return <>
    {isEmployee &&
      <MenuItem className={`${classes.padding_10} ${classes.marginLeft_15}`} data-test-id="open-deleteModal" onClick={handleOpenDeleteAppointMentModal}>
        <ListItemIcon style={{ minWidth: '35px' }}>
          <img src={deleteIcon} width="20px" height="20px" />
        </ListItemIcon>
        <Typography
          variant="body2"
          className={classes.text_black}
          data-test-id='deleteModal-open'
        >
          {t('Calendar.button.delete')}
        </Typography>
      </MenuItem>
    }
  </>
}
export function selectEmpImg(selectedEventData: { employee_img: [] }) {
  return <Grid item>
    {selectedEventData.employee_img?.map((item: { employee_image: string }) => {
      return <Avatar style={{ marginBottom: '12px', marginTop: '15px' }} src={item.employee_image || ''} />
    })}
  </Grid>
}
export function selectEmpName(t: (key: string) => string, classes: ClassNameMap<string>, selectLanguage: string, selectedEventData: { type: string; employee_img: []; employee_image: string; employee: []; employee_name: string; employee_email: string }) {
  return <Grid item className={classes.paddingLeft_9}>
    {selectedEventData.employee?.map((item: { employee_name: string, employee_email: string }) => {
      return <>
        <Typography variant="body1" className={selectLanguage === 'ar' ? `${classes.text_grey} ${classes.fontWeight_500} ${classes.paddingRight_9}` : `${classes.text_grey} ${classes.fontWeight_500}`}>
          {t(`${item.employee_name}`)}
        </Typography>
        <Typography className={selectLanguage === 'ar' ? `${classes.text_grey} ${classes.font_14} ${classes.paddingRight_9}` : `${classes.text_grey} ${classes.font_14}`}>
          {item.employee_email}
        </Typography>
      </>
    })}
  </Grid>
}
export function selectEmpDetails(t: (key: string) => string, classes: ClassNameMap<string>, selectedEventData: { type: string; employee_img: []; employee_image: string; employee: []; employee_name: string; employee_email: string }, selectLanguage: string) {
  return <Grid container alignItems="center"
    className={`${classes.paddingLeft_18} ${classes.paddingTop_10}`}>
    {selectedEventData.type === 'appointment' && selectEmpImg(selectedEventData)}
    {selectedEventData.type === 'job' && selectEmpImg(selectedEventData)}
    {selectedEventData.type === 'task' &&
      <Grid item>
        <Avatar style={{ marginBottom: '12px', marginTop: '15px' }} src={selectedEventData.employee_image || ''} />
      </Grid>
    }
    {selectedEventData.type === 'appointment' ?
      selectEmpName(t, classes, selectLanguage, selectedEventData) :
      <Grid item className={classes.paddingLeft_9}>
        <Typography variant="body1" className={selectLanguage === 'ar' ? `${classes.text_grey} ${classes.fontWeight_500} ${classes.paddingRight_9}` : `${classes.text_grey} ${classes.fontWeight_500}`}>
          {selectedEventData.employee_name}
        </Typography>
        <Typography className={selectLanguage === 'ar' ? `${classes.text_grey} ${classes.font_14} ${classes.paddingRight_9}` : `${classes.text_grey} ${classes.font_14}`}>
          {selectedEventData.employee_email}
        </Typography>
      </Grid>
    }
    {selectedEventData.type === 'job' && selectEmpName(t, classes, selectLanguage, selectedEventData)}
  </Grid>
}
export function empTooltipDetails(
  classes: ClassNameMap<string>,selectedKeys:any[],
  selectLanguage: string,
  getEmployeeData: any[],
  handleChange: (employeeData: any,e:any) => void
) {
  return (
    <>
      {getEmployeeData?.map((item: any) => (
        <MenuItem key={item.id} style={{ whiteSpace: 'initial' }} className={classes.paddingLeft_22}>
          <ListItemIcon style={{ alignItems: 'center', minWidth: '0px' }}>
          <input
          type="checkbox"
          checked={selectedKeys.includes(item.id)}
          onChange={(e:any) => {
            handleChange(item,e)}} 
        />
          </ListItemIcon>
          <ListItemIcon className={selectLanguage === 'ar' ? classes.mr_9 : classes.marginLeft_9}>
            <Avatar src={item.attributes.employee_image?.url || ''} />
          </ListItemIcon>
          <Typography variant="body2" style={{ overflowWrap: 'anywhere' }} className={classes.text_black}>
            {item.attributes.full_name}
          </Typography>
        </MenuItem>
      ))}
    </>
  );
}
export function coloumGrid(isEmployee: any) {
  return <Grid item xs={isEmployee ? 6 : 1} />
}
export function dayBtn(t: (key: string) => string, view: string, classes: ClassNameMap<string>, handleDayViewChange: (day: string) => void) {
  return <Button data-test-id='dayView' data-testid='dayView' className={view === 'day' ? classes.activeBtn : classes.toolBarBtn} onClick={() => handleDayViewChange('day')}>
    <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>{t('Calendar.button.day')}</Typography>
  </Button>
}
export function weekBtn(t: (key: string) => string, view: string, classes: ClassNameMap<string>, handleViewChange: (day: string) => void) {
  return <Button data-test-id='weekView' className={view === 'week' ? classes.activeBtn : classes.toolBarBtn} onClick={() => handleViewChange('week')}>
    <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>{t('Calendar.button.week')}</Typography>
  </Button>
}
export function monthBtn(t: (key: string) => string, view: string, classes: ClassNameMap<string>, handleMonthViewChange: (day: string) => void) {
  return <Button className={view === 'month' ? classes.activeBtn : classes.toolBarBtn} onClick={() => handleMonthViewChange('month')}>
    <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>{t('Calendar.button.month')}</Typography>
  </Button>
}
export function eventBox(event: any) {
  let titleColor: any = event.event.type === 'appointment' ? '#E25085' : '#1c18af'
  return <Box component="span"
    style={{ color: event.event.type === 'job' ? '#FC8337' : titleColor, textDecoration: event.event?.attributes?.status === 'completed' ? 'line-through' : 'none' }}>
    {event.event?.attributes?.task_name || event.title}
  </Box>
}
export function eventStyleGetter(event: Event) {
  let labelColor: any = event.type === 'appointment' ? '#E25085' : '#1c18af'
  let style = {
    color: event.type === 'job' ? '#FC8337' : labelColor,
  };
  return {
    style: style
  };
}
export function titleBg(type: string, classes: ClassNameMap<string>) {
  if (type === "appointment") {
    return `${classes.padding_20} ${classes.backGround_pink} ${classes.fontWeight_400} ${classes.color_lightBrown}`
  } else if (type === "task") {
    return `${classes.padding_20} ${classes.bg_blue} ${classes.fontWeight_400} ${classes.color_lightBrown}`
  } else {
    return `${classes.padding_20} ${classes.bg_orange} ${classes.fontWeight_400} ${classes.color_lightBrown}`
  }
}
export function padding(selectLanguage: string, classes: ClassNameMap<string>) {
  if (selectLanguage === 'en') {
    return classes.pd_0
  } else {
    return classes.pr_20
  }
}
export function selectLanguage(selectLanguage: string) {
  if (selectLanguage === 'ar') {
    return moment.locale('ar')
  } else {
    return moment.locale('en')
  }
}
export function mobileBackIcon (classes: ClassNameMap<string>) {
    return selectedLanguage === "ar" ? 
    `${classes.backIconMobile} ${classes.transformStyle} ${classes.backIcon}` : 
      `${classes.backIconMobile} ${classes.backIcon}`
}
// Customizable Area End

export class Calendar extends CalendarController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes, t } = this.props;
    selectLanguage(this.state.selectLanguage)

    return (
      <>
        <DashboardSideBar permissions={undefined} oldProps={undefined} {...this.props} navMenuItem={`${t('Calendar.heading')}`} active={8} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.selectLanguage}>
          <Box
            sx={{ marginTop: "8rem" }}
            className={classes.boxContainer}
          >
            <Grid container className={classes.containerPadding}>
              <Grid item xs={12} style={{ alignItems: 'center' }} className={`${classes.grid_show} ${classes.marginBottom_25}`}>
                <Hidden smUp>
                  <img className={mobileBackIcon(classes)} src={backIconMobile} alt="" onClick={this.handleBack} />
                </Hidden>
                <>
                  <Grid container alignItems="center">
                    {dateFormate(this.state.datePickerIsOpen,classes, this.state.view,this.closeDatePicker, this.openDatePicker, this.state.startDate, this.state.selectedDate)}
                    {dateLabel(t,classes,this.handleDayChange, this.state.view, this.state.datePickerIsOpen, this.state.startDate, this.handleMonthChange)}
                  </Grid>
                </>
              </Grid>
              <Grid item xs={3} sm={2} md={4}>
                <Button className={`${classes.todayBtn} ${classes.darkBlue_color} ${classes.text_border_gray}`}>
                  <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>{t('Calendar.button.today')}</Typography>
                </Button>
              </Grid>
              <Grid item sm={4} md={4} className={classes.grid_hide}>
                <Grid container alignItems="center">
                  {dateFormate(this.state.datePickerIsOpen,classes, this.state.view,this.closeDatePicker, this.openDatePicker, this.state.startDate, this.state.selectedDate)}
                  {dateLabel(t,classes,this.handleDayChange, this.state.view, this.state.datePickerIsOpen, this.state.startDate, this.handleMonthChange)}
                </Grid>
              </Grid>
              <Grid item xs={9} sm={6} md={4}>
                <Grid container style={{ alignItems: 'center', display: 'flex' }}>
                  {!this.state.isEmployee &&
                    <Grid item xs={5} md={5} lg={5} style={{ alignItems: 'center' }}>
                      <ClickAwayListener onClickAway={this.handleCloseAssignMenu}>
                        <div>
                          <WhiteTooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            arrow
                            placement="bottom-end"
                            interactive
                            onClose={this.handleCloseAssignMenu}
                            open={this.state.openAssignToMenu}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={<>
                              <MenuItem className={`${classes.paddingLeft_22}`}>
                                <Typography variant="subtitle1" className={classes.text_black}>{t('Calendar.assignTo')}</Typography>
                              </MenuItem>
                              {empTooltipDetails(classes,this.state.selectedemployeekeys, this.state.selectLanguage, this.state.getEmployeeData, this.handleChange)}
                            </>
                            }
                          >
                            <Button
                              aria-controls="customized-menu"
                              aria-haspopup="true" style={{ justifyContent: 'space-evenly' }} className={classes.assignedToBtn} onClick={this.handleOpenAssignMenu}>
                              <Typography className={`${classes.fontWeight_500} ${classes.font_14}`}>{t('Calendar.assignTo')}</Typography>
                              <ExpandMoreIcon />
                            </Button>
                          </WhiteTooltip>
                        </div>
                      </ClickAwayListener>
                    </Grid>
                  }
                  {coloumGrid(this.state.isEmployee)}
                  <Grid item xs={6} className={classes.assignedToBtn} style={{ alignItems: 'center', display: 'flex' }}>
                    {dayBtn(t, this.state.view, classes, this.handleDayViewChange)}
                    {weekBtn(t, this.state.view, classes, this.handleViewChange)}
                    {monthBtn(t, this.state.view, classes, this.handleMonthViewChange)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CustomCalender
              data-test-id='calender-id'
              culture={this.state.selectLanguage === 'ar' ? 'ar' : "en"}
              className={this.state.view === 'day' ? `${classes.customBigCalender} ${classes.calendarHeight}` : `${classes.customBigCalender} ${classes.weekCalendar} ${classes.calendarHeight}`}
              localizer={localizer}
              events={this.convertToCalendarEvents(Array.isArray(this.state.events) ? this.state.events : [])}
              timeslots={6}
              showMultiDayTimes={true}
              defaultView="month"
              view={this.state.view}
              defaultDate={new Date()}
              onNavigate={(event) => this.handleProjectDetailModalOpen(event)}
              date={new Date(this.state.startDate)}
              components={{
                toolbar: () =>
                  <div></div>,
                month: {
                  dateHeader: (date: any) =>
                    <Box component="span"
                      style={{ color: dateHeaderStyle(date, this.state.events) }}>
                      {date.label}
                    </Box>,
                  event: (event: any) =>
                    <div>{eventBox(event)}</div>
                },
                week: {
                  event: (event: any) =>
                    <div>{eventBox(event)}</div>
                },
                day: {
                  event: (event) =>
                    <div>{eventBox(event)}</div>
                }
              }}
              dayPropGetter={(event: any) => customDayPropGetter(event, this.state.events, this.state.view)}
              selectable={true}
              onSelectEvent={(event: any) => this.handleEventSelected(event)}
              onSelectSlot={(slotInfo: any) => this.handleSlotSelected(slotInfo)}
              eventPropGetter={(event: Event) => eventStyleGetter(event)}
            />
            <Dialog
              open={this.state.projectDetailModal}
              onClose={this.handleProjectDetailModalClose}
              maxWidth="lg"
              PaperProps={{
                style: {
                  width: "500px",
                  backgroundColor: CONSTANTS.color.white,
                  boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
                  borderRadius: 20,
                },
              }}
            >
              <Box style={{ background: "#E9E9FF", height: '75px', display: 'flex', color: '#34313A', alignItems: 'center', fontSize: '22px', padding: '0px 30px' }}>
                {t('languageSupport.projectDetails')}
              </Box>
              <Box style={{ height: '600px' }}>
                <Box style={{ padding: '20px 30px' }}>
                  <Typography style={{ fontSize: '14px', color: '#1C18AF', fontWeight: 500 }}>{t('dashboardSidebar.tasks')}</Typography>
                  {this.filterDataByDateAndType(this.state.events, this.state.selectedProjectDate, "task").map((task: any) => (
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box style={{ width: '300px'}}>
                        <Typography style={{ fontFamily: 'Rubik', fontSize: '18px', color: '#666666', textDecorationLine: this.markAsCompleteLine(task.attributes.status)}}>{task.attributes.task_name}</Typography>
                        <Typography style={{ fontFamily: 'Rubik', fontSize: '18px', color: '#999999'}}>{task.attributes.due_date}</Typography>
                      </Box>
                      <Box style={{ background: this.markAsCompleteColor(task.attributes.status), borderRadius: '4px', padding: '4px 8px', color: '#ffffff', fontSize: '13px'}}>{task.attributes.status}</Box>
                      <ArrowForwardIosIcon style={{color: '#1C18AF', cursor: 'pointer'}} onClick={() => this.handleEventSelected(task)} />
                    </Box>
                  ))}
                </Box>
                <Divider />
                <Box style={{ padding: '20px 30px' }}>
                  <Typography style={{ fontSize: '14px', color: '#1C18AF', fontWeight: 500 }}>{t('Calendar.appointments')}</Typography>
                  {this.filterDataByDateAndType(this.state.events, this.state.selectedProjectDate, "appointment").map((appointment: any) => (
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box style={{ width: '300px'}}>
                        <Typography style={{ fontFamily: 'Rubik', fontSize: '18px', color: '#666666', textDecorationLine: this.markAsCompleteLine(appointment.attributes.status)}}>{appointment.attributes.title}</Typography>
                        <Typography style={{ fontFamily: 'Rubik', fontSize: '18px', color: '#999999'}}>{appointment.attributes.start_time}-{appointment.attributes.end_time}</Typography>
                      </Box>
                      <Box style={{ background: this.markAsCompleteColor(appointment.attributes.status), borderRadius: '4px', padding: '4px 8px', color: '#ffffff', fontSize: '13px'}}>{appointment.attributes.status}</Box>
                      <ArrowForwardIosIcon style={{color: '#1C18AF', cursor: 'pointer'}} onClick={() => this.handleEventSelected(appointment)} />
                    </Box>
                  ))}
                </Box>
                <Divider />
                <Box style={{ padding: '20px 30px' }}>
                  <Typography style={{ fontSize: '14px', color: '#1C18AF', fontWeight: 500 }}>{t('dashboardNavbar.jobs')}</Typography>
                  {this.filterDataByDateAndType(this.state.events, this.state.selectedProjectDate, "job").map((job: any) => (
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box style={{ width: '300px'}}>
                        <Typography style={{ fontFamily: 'Rubik', fontSize: '18px', color: '#666666', textDecorationLine: this.markAsCompleteLine(job.attributes.status)}}>{job.attributes.job_title}</Typography>
                        <Typography style={{ fontFamily: 'Rubik', fontSize: '18px', color: '#999999'}}>{job.attributes.start_time}-{job.attributes.end_time}</Typography>
                      </Box>
                      <Box style={{ background: this.markAsCompleteColor(job.attributes.status), borderRadius: '4px', padding: '4px 8px', color: '#ffffff', fontSize: '13px'}}>{job.attributes.status}</Box>
                      <ArrowForwardIosIcon style={{color: '#1C18AF', cursor: 'pointer'}} onClick={() => this.handleEventSelected(job)} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Dialog>
            <Grid>
              <LoadingBackdrop
                loading={this.state.getAppointmentLoading}
              />
            </Grid>
            <Dialog
              open={this.state.openEvent}
              onClose={this.handleClose}
              maxWidth="lg"
              PaperProps={{
                style: {
                  width: "500px",
                  backgroundColor: CONSTANTS.color.white,
                  boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
                  borderRadius: 20,
                },
              }}
            >
              <Typography
                variant="h6"
                className={titleBg(this.state.selectedEventData.type, classes)}
              >
                <div style={{
                  width: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                  {this.state.selectedEventData.title}
                </div>
              </Typography>
              <DialogContent className={padding(this.state.selectLanguage, classes)}>
                <Typography
                  variant="body1"
                  className={`${classes.paddingLeft_20} ${classes.text_lightGrey} ${classes.paddingTop_15}`}
                >
                  {statusLabel(this.state.selectedEventData.type, t)}
                </Typography>
                {this.state.selectedEventData.type !== 'appointment' &&
                  <Grid container alignItems="center"
                    className={`${classes.paddingLeft_22} ${classes.marginTop_5} ${classes.text_grey}`}
                  >
                    <StyledCheckbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                      onChange={this.handleStatusChange}
                      data-test-id='status-change'
                      name='mark_as_complete'
                      checked={this.state.selectedEventData.mark_as_complete}
                    />
                    <Typography variant="body1" className={this.state.selectLanguage === 'ar' ? classes.paddingRight_9 : ""}>{t('Calendar.markAsComplete')}</Typography>
                  </Grid>
                }
                {appointmentDescription(this.state.selectedEventData.description, classes)}
                <Divider style={{ marginTop: '10px' }} />
                <Typography
                  variant="body1"
                  className={`${classes.paddingLeft_20} ${classes.text_lightGrey} ${classes.paddingTop_15}`}
                >
                  {t('Calendar.assignTo')}
                </Typography>
                {selectEmpDetails(t, classes, this.state.selectedEventData, this.state.selectLanguage)}
                <Divider style={{ marginTop: '10px' }} />
              </DialogContent>
              <DialogActions className={classes.pd_0}>
                <Grid container className={classes.padding_20}>
                  <Grid item sm={6} className={classes.btn_hide_smallScreen}>
                    <Button
                      data-test-id='handel-close'
                      onClick={this.handleClose}
                      className={`${classes.cancelBtn} ${classes.outlinedButton} ${classes.text_border_red}`}
                    >
                      <Typography variant="body1">{t('Calendar.button.cancel')}</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ textAlign: 'end' }}>
                    <Button variant="outlined"
                      data-test-id='moreAction-menu-open'
                      onClick={this.handleOpenMoreActionMenu}
                      className={`${classes.moreActionBtn} ${classes.outlinedButton}`}
                    >
                      <Typography variant="body1">{t('Calendar.button.more')}</Typography>
                      <ArrowDropDownIcon style={{ fontSize: '35px' }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} className={classes.btn_hide_largeScreen}>
                    <Button
                      data-test-id='handel-close1'
                      onClick={this.handleClose}
                      className={`${classes.cancelBtn} ${classes.outlinedButton} ${classes.text_border_red}`}
                    >
                      <Typography variant="body1">{t('Calendar.button.cancel')}</Typography>
                    </Button>
                  </Grid>
                </Grid>

              </DialogActions>
              <StyleMenu
                id="customized-menu"
                keepMounted
                anchorEl={this.state.openMoreActionMenu}
                open={Boolean(this.state.openMoreActionMenu)}
                data-test-id='moreAction-menu-close'
                onClose={this.handleCloseMoreActionMenu}
              >
                <MenuItem>
                  <Typography className={`${classes.text_black} ${classes.marginLeft_9}`} variant="subtitle1">{t('Calendar.button.action')}</Typography>
                </MenuItem>
                <MenuItem className={`${classes.padding_10} ${classes.marginLeft_15}`} data-test-id='editModal' onClick={this.handleOpenEditAppointMentModal}>
                  <ListItemIcon style={{ minWidth: '35px' }}>
                    <img src={editIcon} width="20px" height="20px" />
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    className={classes.text_black}
                    data-test-id='editAppointmentModal-open'
                  >
                    {menuLabel(this.state.selectedEventData.type, t)}
                  </Typography>
                </MenuItem>
                {deleteLabel(t, !this.state.isEmployee, classes, this.handleOpenDeleteAppointMentModal)}
              </StyleMenu>
            </Dialog>
          </Box>
        </StyledRoot>

        {
          this.state.openDeleteAppointmentModal &&
          <DeleteModal
            handleOpenDeleteAppointMentModal={this.handleOpenDeleteAppointMentModal}
            handleCloseDeleteAppointMentModal={this.handleCloseDeleteAppointMentModal}
            handleDelete={this.handleDelete}
            t={this.props.t}
            selectedEventData={this.state.selectedEventData}
            openDeleteTaskModal={this.state.openDeleteTaskModal}
            isDeleteAppointment={this.state.isDeleteAppointment}
            isDeleteJob={this.state.isDeleteJob}
          />
        }
        {
          moment(new Date()).format("MMMM YYYY").toString() === moment(this.state.onOffRange.start).format("MMMM YYYY").toString()
          &&
          <NewAppointment
            data-test-id='newAppointMentC'
            handleOpenNewAppointMentModal={this.state.openNewAppointmentModal}
            handleCloseNewAppointMentModal={this.handleCloseNewAppointMentModal}
            isOpenNewAppointment={this.state.isOpenNewAppointment}
            viewAppointmentDetails={this.state.viewAppointmentDetails}
            openEditAppointmentModal={this.state.openEditAppointmentModal}
            getAppointment={this.getAppointment}
            appointmentForm={this.state.appointmentForm}
            openToastHandler={this.props.openToastHandler}
            selectedEventData={this.state.selectedEventData}
            deleteAppointment={this.deleteAppointment}
            getEmployee={this.getEmployee}
            getEmployeeData={this.state.getEmployeeData}
            getCustomerData={this.state.getCustomerData}
            getSiteData={this.state.getSiteData}
            isEmployee={this.state.isEmployee}
            employeeId={this.state.employee_id}
            view={this.state.view}
            isActive={this.state.isActive}
            selectLanguage={this.state.selectLanguage}
            getSite={this.getSite}
          />
        }
        {this.state.openTaskModal &&
          <Task
            isOpen={this.handleOpenTask}
            onClose={this.handleCloseTask}
            getEmployeeData={this.state.getEmployeeData}
            isEmployee={this.state.isEmployee}
            appointmentForm={this.state.appointmentForm}
            employeeId={this.state.employee_id}
            isOpenNewTask={this.state.isOpenNewTask}
            openEditTaskModal={this.state.openEditTaskModal}
            selectedEventData={this.state.selectedEventData}
            events={this.state.events}
            t={this.props.t}
            viewTaskDetails={this.state.viewTaskDetails}
            isActive={this.state.isActive}
            createTask={this.createTask}
            updateTask={this.updateTask}
            deleteTask={this.deleteAppointment}
            selectLanguage={this.state.selectLanguage}
          />
        }
        {this.state.openJobModal &&
          <Job
            isOpen={this.state.openJobModal}
            onClose={this.handleCloseJob}
            isOpenNewJob={this.state.isOpenNewJob}
            selectedEventData={this.state.selectedEventData}
            getEmployeeData={this.state.getEmployeeData}
            isEmployee={this.state.isEmployee}
            view={this.state.view}
            t={this.props.t}
            appointmentForm={this.state.appointmentForm}
            openEditJobModal={this.state.openEditJobModal}
            viewJobDetails={this.state.viewJobDetails}
            selectLanguage={this.state.selectLanguage}
            getCustomerData={this.state.getCustomerData}
            getSiteData={this.state.getSiteData}
            getSite={this.getSite}
            createJob={this.createJob}
            updateJob={this.updateJob}
            deleteJob={this.deleteAppointment}
          />
        }
        {this.state.openSlot &&
          <Dialog
            open={this.state.openSlot}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                width: "300px",
                height: '200px',
                backgroundColor: CONSTANTS.color.white,
                boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            <Typography className={`${classes.text_black} ${classes.padding_10} ${classes.marginLeft_5}`} variant="subtitle1">
              {t('Calendar.addTo')} {moment(new Date(this.state.appointmentForm.start)).format("MMM DD").toString()}
            </Typography>
            <StyledMenuItem1 onClick={this.handleOpenJob}>
              <ListItemIcon>
                <img src={jobIcon} width="20px" height="20px" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">{t('Calendar.newJob')}</Typography>
              </ListItemText>
            </StyledMenuItem1>
            <StyledMenuItem1 onClick={this.handleOpenTask}>
              <ListItemIcon>
                <img src={taskIcon} width="20px" height="20px" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">{t('Calendar.newTask')}</Typography>
              </ListItemText>
            </StyledMenuItem1>
            <StyledMenuItem1 onClick={this.handleOpenAppointment}>
              <ListItemIcon>
                <img src={appointmentIcon} width="23px" height="20px" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">{t('Calendar.newAppointment')}</Typography>
              </ListItemText>
            </StyledMenuItem1>
          </Dialog>
        }

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const WhiteTooltip = withStyles({
  arrow: {
    '&.MuiTooltip-arrow': {
      color: CONSTANTS.color.white,
    },
  },
  tooltip: {
    borderRadius: "5px",
    background: CONSTANTS.color.white,
    minWidth: '250px',
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  }
})(Tooltip)
const StyledMenuItem1 = withStyles((theme) => ({
  root: {
    '& .MuiListItemIcon-root': {
      minWidth: '45px',
    },
    '& .MuiListItemText-root': {
      color: CONSTANTS.color.black
    },
    '&:focus': {
      backgroundColor: CONSTANTS.color.darkBlue,
      '& .MuiListItemIcon-root, & .MuiListItemText-root': {
        color: theme.palette.common.white,
        filter: 'brightness(0) invert(1)'
      },
    },
  },
}))(MenuItem);

const StyleMenu = withStyles((theme: any) => ({
  paper: {
    borderRadius: "5px",
    background: CONSTANTS.color.white,
    width: '290px',
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    [theme.breakpoints.down("xs")]: {
      width: "230px",
    }
  },
}))((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
));
export const StyledCheckbox = withStyles({
  root: {
    height: "20px",
    width: "20px",
    color: "rgba(0,0,0,0.1)",
    "&.Mui-checked": {
      color: CONSTANTS.color.darkBlue
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
      paddingRight: '8px'
    }
  }
})(Checkbox);
export const styles: any = (theme: any) => ({
  customBigCalender: {
    '& .rbc-month-view': {
      backgroundColor: "#ffffff",
      boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
      marginTop: '20px',
      border: 'none',
      zIndex: 0,
      '& .rbc-show-more': {
        color: '#E25085',
        marginLeft: '6px',
        whiteSpace: 'nowrap',        
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: "100%",
        maxWidth:"150px",           
        display: 'inline-block' 
      },
      '& .rbc-month-header': {
        '& .rbc-header': {
          [theme.breakpoints.down("xs")]: {
            padding: "10px !important"
          },
          padding: '25px',
          fontWeight: 400,
          color: 'rgb(153, 153, 153)',
          
        },
      },
      '& .rbc-month-row': {
        '& .rbc-row': {
          '& .rbc-date-cell': {
            padding: '10px 10px 0px 0px',
            fontWeight: 600,
          },
          '& .rbc-off-range': {
            color: 'white !important'
          },
          '& .rbc-row-segment': {
            '& .rbc-event': {
              background: 'none',
              left: '0% !important',
            },
            '& .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus': {
              outline: 'none !important',
              left: '0% !important',
            },
            '& .rbc-event-content': {
              [theme.breakpoints.down("xs")]: {
                maxWidth: '10%',
              },
              color: '#E25085',
              fontWeight: 600,
              whiteSpace: 'nowrap',      
              overflow: 'hidden',         
              textOverflow: 'ellipsis',
              display: 'inline-block',
              width: "100%",
              maxWidth:"150px"
            }
          }
        },
      },
    },
    '& .rbc-time-content': {
      overflowY: "clip"
    }
  },
  jobCalendar:{
    '& .rbc-day-bg': {
    borderLeft: selectedLanguage === 'ar' ? '1px solid #ddd':""
    },
    '& .rbc-month-view .rbc-month-header .rbc-header': {
    borderLeft: selectedLanguage === 'ar' ? '1px solid #ddd':""
    },
'& .rbc-month-view':{
    borderLeft: selectedLanguage === 'ar' ? 'none' :"" 
    },
'& .rbc-button-link':{
    whiteSpace:"normal",
    wordWrap:"brake-word",
    textAlign:"center",
    // paddingBottom:"10px"
    },
},
  weekCalendar: {
    '& .rbc-time-view .rbc-time-gutter': {
      display: 'flex',
      [theme.breakpoints.down("xs")]: {
        display: 'none',
      }
    },
    '& .rbc-time-view': {
      '& .rbc-time-header': {
        '& .rbc-time-header-content': {
          '& .rbc-time-header-cell': {
            [theme.breakpoints.down("xs")]: {
              minHeight: '40px',
            },
            '& .rbc-header': {
              paddingTop: '10px',
              '& .rbc-button-link': {
                whiteSpace: 'wrap'
              }
            }
          }
        }
      }
    },
    '& .rbc-label': {
      padding: '0px 5px',
      [theme.breakpoints.down("xs")]: {
        padding: '0px'
      }
    },
    // Add this block for event styling
    '& .rbc-day-slot': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column', // Stack events vertically
      width: '100%', // Ensure slot uses full width
      overflow: 'hidden', // Prevent overflow
      '& .rbc-event': {
        position: 'relative',
        left: '0% !important', // Ensure events align to the start of the slot
        width: '100%', // Take up full slot width
        padding: '5px', // Padding for content
        marginTop: '5px', // Spacing between events
        borderRadius: '4px', // Round corners
        whiteSpace: 'normal', // Allow text to wrap
        wordWrap: 'break-word', // Handle long words
        overflow: 'visible', // Ensure full text is visible
        textOverflow: 'ellipsis', // Truncate text with ellipsis if needed
        fontSize: '0.9rem', // Adjust font size
        lineHeight: '1.2', // Improve spacing for multi-line text
      }
    }
  },
   
  boxContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0 0.8rem !important"
    }
  },
  containerPadding: {
    padding: "30px 30px 0px 30px",
    [theme.breakpoints.down("md")]: {
      padding: '0px 13px 0px 13px'
    }
  },
  calendarHeight: {
    height: '725px',
    padding: '15px 30px',
    [theme.breakpoints.down("sm")]: {
      height: '625px'
    },
    [theme.breakpoints.down("md")]: {
      padding: '13px'
    }
  },
  pd_0: {
    padding: "0px"
  },
  pr_20: {
    paddingRight: "20px"
  },
  backIconMobile: {
    marginRight: selectedLanguage === "en" ? "10px" : "",
    marginLeft: selectedLanguage === "ar" ? "10px" : ""
  },
  backIcon:{
    width: "12px",
    height: "24px",
  },
  transformStyle: {
    transform: "rotate(180deg)"
  },
  todayBtn: {
    borderRadius: "8px",
    width: '85px',
    height: '40px',
    textTransform: "capitalize",
    '&:hover': {
      cursor: "pointer",
      border:"none",
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white
    },
    transition: 'background-color 0.3s, color 0.3s',
    [theme.breakpoints.down("xs")]: {
      width: '25%',
      height: '35px',
    }
  },
  dropdownIconBtn: {
    minWidth: "0px",
    padding: "0px"
  },
  grid_hide: {
    display: 'flex',
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  },
  grid_show: {
    display: 'flex',
    [theme.breakpoints.between("sm", 'lg')]: {
      display: 'none'
    },
  },
  customDatePicker: {
    display: "none",
  },
  customWeekPicker: {
    marginLeft: "-18em !important",
    [theme.breakpoints.down('xs')]: {
      marginLeft: "-16em !important",
    },
  },
  font_22: {
    fontSize: "22px"
  },
  font_16: {
    fontSize: "16px"
  },
  arrowIcon: {
    fontSize: '20px',
    marginTop: "-3px",
    color: "#ccc",
    fontWeight: 600
  },
  cursor: {
    cursor: "pointer"
  },
  fontWeight_400: {
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  fontWeight_500: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  fontWeight_600: {
    fontWeight: CONSTANTS.fontWeight.fontWeight600,
  },
  darkBlue_color: {
    color: CONSTANTS.color.darkBlue,
  },
  text_black: {
    color: CONSTANTS.color.black
  },
  color_lightBrown: {
    color: CONSTANTS.color.lightBrown,
  },
  text_lightGrey: {
    color: CONSTANTS.color.lightGrey
  },
  text_grey: {
    color: CONSTANTS.color.grey
  },
  cancelBtn: {
    width: "135px",
    height: "48px",
    [theme.breakpoints.down("xs")]: {
      width: '100%',
      marginTop: '20px'
    }

  },
  activeBtn: {
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    background: CONSTANTS.color.darkBlue,
    color: CONSTANTS.color.white,
    textTransform: "capitalize",
    borderRadius: '6px',
    width: '100%',
    height: '40px',
    [theme.breakpoints.down("xs")]: {
      height: '35px',
    },
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
      border:"none"
    },
    '&.MuiButton-root': {
      minWidth: '0px !important',
      width: '100% !important',
    }
  },
  toolBarBtn: {
    color: 'darkGrey',
    textTransform: "capitalize",
    transition: 'background-color 0.3s, color 0.3s',
    width: '100%',
    height: '40px',
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
      border:"none"
    },
    [theme.breakpoints.down("xs")]: {
      height: '35px',
    },
    '&.MuiButton-root': {
      minWidth: '0px !important',
      width: '100% !important',
    }
  },
  assignedToBtn: {
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    textTransform: "capitalize",
    background: CONSTANTS.color.white,
    color: 'darkGrey',
    border: 'none',
    height: '40px',
    [theme.breakpoints.down("xs")]: {
      height: '35px',
    },
    '&.MuiButton-root': {
      minWidth: '0px !important',
      width: '112% !important',
      padding: "6px 37px",
      [theme.breakpoints.down("md")]: {
        padding: "6px 6px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "6px 5px",
      },
    }
  },
  btnBackground: {
    boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
    background: CONSTANTS.color.white,
    border: 'none',
    height: '46px',
  },
  moreActionBtn: {
    width: "180px",
    height: "50px",
    color: CONSTANTS.color.white,
    transition: 'background-color 0.3s, color 0.3s',
    background: CONSTANTS.color.darkBlue,
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: theme.palette.common.white,
      cursor: "pointer",
      border:"none"
    },
    [theme.breakpoints.down("xs")]: {
      width: '100%',
    },
  },
  outlinedButton: {
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  btn_hide_smallScreen: {
    display: 'flex',
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  },
  btn_hide_largeScreen: {
    display: 'flex',
    [theme.breakpoints.between("sm", "xl")]: {
      display: 'none'
    },
  },
  employeeText: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down("xs")]: {
      width: 'calc(100% - 10px)'
    },
  },
  text_border_red: {
    border: `1px solid ${CONSTANTS.color.crimsonRed}`,
    color: CONSTANTS.color.crimsonRed,
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    '&:hover': {
      backgroundColor: '#ff5454',
      color: theme.palette.common.white,
      cursor: "pointer"
    },
  },
  backGround_pink: {
    background: CONSTANTS.color.pink
  },
  bg_blue: {
    background: '#E9EAFF'
  },
  bg_orange: {
    background: "#FEF5EE"
  },
  font_14: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: '12px !important'
    },
  },
  mr_9: {
    marginRight: "19px"
  },
  marginLeft_5: {
    marginLeft: '5px'
  },
  marginLeft_9: {
    marginLeft: '9px'
  },
  marginLeft_15: {
    marginLeft: '15px'
  },
  marginBottom_25: {
    marginBottom: "25px"
  },
  paddingLeft_22: {
    paddingLeft: '22px'
  },
  padding_10: {
    padding: '10px'
  },
  padding_20: {
    padding: '20px'
  },
  paddingTop_10: {
    paddingTop: '10px'
  },
  paddingTop_15: {
    paddingTop: '15px'
  },
  paddingLeft_9: {
    paddingLeft: '9px'
  },
  paddingLeft_18: {
    paddingLeft: '18px'
  },
  paddingLeft_20: {
    paddingLeft: '20px'
  },
  paddingRight_9: {
    paddingRight: '9px'
  },
  text_border_gray: {
    border: "1px solid darkGrey",
  },
});

const CalendarSnackbar = withSnackbar(Calendar)
const calendarStyle = calendarStyles(CalendarSnackbar)
const LanguageComponent = withStyles(styles)(calendarStyle)
export default withTranslation()(LanguageComponent);
// Customizable Area End